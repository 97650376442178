<template>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <el-descriptions class="margin-top col-md-6" title="FEDERAL TAXES" :column="1" size="small" border>
        <el-descriptions-item v-for="(tax, i) in federal_taxes" :key="i">
          <template slot="label">
            <i class="el-icon-user"></i>
            {{ tax.name }}
          </template>
          {{ money(tax.amount, 2) }}
        </el-descriptions-item>
      </el-descriptions>
      <hr>
    </div>
    <div class="col-md-12">

      <div class="row">
        <div class="col-md-4" v-for="(state,i) in state_taxes" :key="i" style="margin-bottom: 10px;">
          <el-descriptions class="margin-top col-md-12" :title="state.name+' TAXES'" :column="1" size="small" border>
            <el-descriptions-item v-for="(tax, j) in state.taxes" :key="j">
              <template slot="label">
                <i class="el-icon-user"></i>
                {{ tax.name }}
              </template>
              {{ money(tax.amount, 2) }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import {
  mapState
} from 'vuex';

export default {
  props: ['summary'],
  data() {
    return {
      federal_taxes: [],
      state_taxes: []
    };
  },
  watch: {

  },
  created: function () {
    this.groupTaxes()
  },
  methods: {
    groupTaxes() {
      this.federal_taxes = []
      this.state_taxes = []
      let self = this
      this.summary.employee_taxes.forEach(tax => {
        if (tax.type == 1) self.federal_taxes.push(tax)
        else self.configureStates(tax)
      });
    },
    configureStates(tax) {
      const usStateAbbreviations = [
        "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA",
        "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
        "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT",
        "VA", "WA", "WV", "WI", "WY"
      ];

      let words = tax.name.split(" ");
      let state = words.find(word => usStateAbbreviations.includes(word.toUpperCase()));
      if (state) {
        let self = this
        let found = false
        this.state_taxes.forEach(function (s) {
          if (s.name == state) {
            if (!s.taxes) s.taxes = []
            s.taxes.push(tax)
            found = true
          }
        })
        if (!found) this.state_taxes.push({
          name: state,
          taxes: [tax]
        })
      }
    }
  },
  computed: {
    ...mapState(['user'])
  },
}
</script>

<style></style>
