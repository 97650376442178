<template>
  <div v-loading="loading">
    <el-collapse v-model="active" @change="handleChange">
      <el-collapse-item title="New Module" name="new_permission">
       
        <div style="margin-top: 15px;width: 300px;" >
          <el-input placeholder="Type the new permission" v-model="new_module" class="input-with-select">
            <el-button slot="append" icon="el-icon-plus" @click="addModule()"></el-button>
          </el-input>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="name" :name="name" v-for="name in modules" :key="name">
       <el-row>
          <el-button size="mini" :type="actionLabel(name, action)" v-for="action in actions" :key="action" style="margin-bottom: 10px" @click="togglePermission(name, action)">{{action}}</el-button>
        </el-row>
        <el-row>
          <el-button size="mini" type="primary" style="margin-bottom: 10px" @click="togglePermission(name, 'select')">SELECT ALL</el-button>
          <el-button size="mini" type="danger" style="margin-bottom: 10px" @click="togglePermission(name, 'unselect')">UNSELECT ALL</el-button>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>


import { Role } from '@/resources/role'

export default {
  props: ['role'],
  data() {
    return {
      loading: false,
      modules: [],
      actions: [],
      role_modules: [],
      active: ['new_permission'],
      new_module: null
    };
  },
  watch: {
    role: function () {
      this.getRolePermissions()
    }
  },
  created: function () {
    this.getRolePermissions()
  },
  methods: {
    addModule() {
      Role.addModule({ module: this.new_module })
        .then(result => {
          this.getRolePermissions()
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to save permissions',
          });

        }).finally(() => {
          this.loading = false
        })
    },
    modifyRoleModules(name, action) {
      let hasAction = this.hasAction(name, action)
      let self = this
      let md = null
      this.role_modules.forEach(function (module, i) {
        if (module.name == name) {
          md = module
        }
      })

      if (!md) {
        md = { name: name, permissions: [] }
        this.role_modules.push(md)
      }

      if (md) {
        if (hasAction) {
          md.permissions = md.permissions.filter(function (e) { return e !== action })
        } else {
          if (action == 'unselect') {
            md.permissions = []
          } else if (action == 'select') {
            md.permissions = this.actions
          } else {
            md.permissions.push(action)
          }
        }

        console.log(md, action)
      }
    },
    togglePermission(name, action) {
      this.loading = true
      Role.setPermission(this.role.id, {module: name ,action:action, name: name })
        .then(result => {
          this.modifyRoleModules(name, action)
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to save permissions',
          });

        }).finally(() => {
          this.loading = false
        })
    },
    actionLabel(name, action) {
      return this.hasAction(name, action) ? 'success' : 'info'
    },
    hasAction(name, action) {
      let found = false

      this.role_modules.forEach(function (module) {
        if (module.name == name) {
          module.permissions.forEach(function (permission) {
            if (permission == action) found = true
          })
        }
      })

      return found
    },
    handleChange() {

    },
    getRolePermissions() {
      this.loading = true
      Role.getRolePermissions(this.role.id)
        .then(result => {
          this.actions = result.data.actions
          this.modules = result.data.modules
          this.active = result.data.modules
          this.groupPermissions(result.data.permissions)
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch permissions',
          });

        }).finally(() => {
          this.loading = false
        })
    },
    groupPermissions(permissions) {
      let modules = []
      permissions.forEach(p => {
          let permission = p.permission
          let found = false
          modules.forEach(function (module,i) {
            if (permission.module == module.name) {
              modules[i].permissions.push(permission.action)
              modules[i].permissions = [...new Set(modules[i].permissions)];
              found = true
            }
          })

          if (!found) {
            modules.push({
              name: permission.module,
              permissions: [permission.action]
            })
          }
      });

      this.role_modules = modules
    }
  }
}
</script>
<style></style>
