<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
              <label>Email</label>
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The email field format is invalid' : null" :hasSuccess="passed"
                  name="email" v-model="form.email">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="form-group">
                <label>Password</label>
                <ValidationProvider name="password" rules="required|password_regex" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'The password should have a minimum length of 8 characters and must include both a letter and a number.' : null" :hasSuccess="passed"
                    name="password" v-model="form.password">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()" class="ml-2">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { Employee } from '@/resources/employee'

extend("email", email);
extend("required", required);
extend('password_regex', {
  validate: (value) => {
    // Define your regex pattern for password validation
    const regex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    return regex.test(value);
  },
  message: 'The password should have a minimum length of 8 characters and must include both a letter and a number.',
});


export default {
  props: ['employee'],
  data() {
    return {
      form: {
        email: null,
        password: null
      }
    };
  },
  watch: {
    employee: function () {
      if (this.employee.user) {
        this.form = Object.assign({}, this.employee.user)
        this.form.password = null
      }
    }
  },
  created: function () {
    if (this.employee) {
      if (this.employee.user) {
        this.form = Object.assign({}, this.employee.user)
        this.form.password = null
      }
    }
  },
  beforeDestroy() {
    this.form = {
      email: null,
      password: null
    }
  },
  methods: {
    close() {
      this.$emit('save', false)
    },
    submit() {
      this.$loading = true

      Employee.saveUser(this.employee.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'The credentials have been stored, and a copy has been dispatched to the employee',
          });
          this.$emit('save', result.data.user)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>