<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
    :append-to-body="true"
        :destroy-on-close="true">
        <div class="">
          <payroll-generate-invoice :payroll="selected" v-if="selected" @close="getPayrolls()"></payroll-generate-invoice>
        </div>
      </el-drawer>

    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-3">
          <div class="">
            <el-input prefix-icon="nc-icon nc-zoom-split" size="small" class="input-sm" placeholder="Search"
              v-model="term">
            </el-input>
          </div>
        </div>
        <div class="col-sm-9">
          <div class="pull-right">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

        </div>

        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="payrolls" style="width: 100%">

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Check Date">
              <template slot-scope="props">
                {{ props.row.check_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Period">
              <template slot-scope="props">
                {{ props.row.pay_start_date }} to {{ props.row.pay_end_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <el-alert :title="$store.state.payroll_statuses[props.row.status]"
                  :type="$store.state.payroll_status_color[props.row.status]" :closable="false">
                </el-alert>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Schedule">
              <template slot-scope="props">
                {{ $store.state.schedule_types[props.row.schedule_type] }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ $store.state.payroll_types[props.row.payroll_type] }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Paychecks">
              <template slot-scope="props">
                <el-tag type="success">{{ props.row.paychecks_count }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Amount">
                <template slot-scope="props">
                  {{ money(props.row.total_check_amount) }}
                </template>
              </el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="primary" link @click="handleCreateInvoice(props.row)" size="mini">
                  <i class="fa fa-file"></i> Create Invoice
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Payroll } from '@/resources/payroll'
import PayrollGenerateInvoice from './PayrollGenerateInvoice.vue'

export default {
  components: { PayrollGenerateInvoice },
  data() {
    return {
      showDelete: false,
      showForm: false,
      payrolls: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      payroll_group: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPayrolls()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPayrolls()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      let page = parseInt(this.$route.query.page)
      if (page != this.pagination.currentPage) this.$router.push({ path: this.$route.fullPath, query: { page: this.pagination.currentPage } });
      if (!this.$loading) this.getPayrolls()
    }
  },
  created: function () {
    this.getPayrolls()
  },
  methods: {
    handleCreateInvoice(payroll) {
      this.showForm = true
      this.selected = Object.assign({}, payroll)
    },
    handleClose() { 
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
      this.selected = { payroll_group_id: this.payroll_group.id }
    },
    getPayrolls() {
      this.$loading = true
      this.handleClose()

      let payrollGroupId = this.$route.params.id
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage, payroll_group_id: payrollGroupId }

      Payroll.getPayrollsReadyToInvoice({ params: params })
        .then(result => {
          if (result.data.payrolls)
            this.payrolls = result.data.payrolls
          this.pagination = result.data.meta
          this.payroll_group = result.data.payroll_group
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
