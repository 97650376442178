<template>
<div class="" v-if="form" style="padding: 20px">
  <h4>Invoice Preview</h4>
  <invoice-form :invoice="form" @close="close()"></invoice-form>
</div>
</template>

<script>
import {
  Payroll
} from '@/resources/payroll'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import InvoiceForm from '../../Invoices/InvoiceForm.vue';

extend("required", required);

export default {
  components: { InvoiceForm },
  props: ['payroll'],
  data() {
    return {
      form: null
    }
  },
  created: function () {
    this.generate()
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
    generate() {
      this.$loading = true
      this.$loadingText = 'Generating Invoice. Please wait...'

      Payroll.generateInvoice(this.payroll.id)
        .then(result => {
          this.form = result.data.invoice
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
