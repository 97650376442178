<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this employee? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <employee-form @save="handleClose()" v-if="showForm" :employee="selected"></employee-form>
        </div>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showEmployee" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <employee-view :employee="selected"></employee-view>
    </el-drawer>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-3">
          <stats-card :type="'primary'" icon="fa fa-users" :small-title="'Active Employees'"
            :title="active_employees_count">

          </stats-card>
        </div>
      </div>
    </div>
    <div class="col-md-12 ">
      <div class="card">
        <div class="card-header">
          <div class="category">{{ title }}</div>
        </div>
        <div class="card-body row">
          <div class="col-sm-3">
            <div>
              <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
          <div class="col-sm-3">
            <search-department @update="checkDepartments($event)" :multiple="true"></search-department>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
                <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <el-button type="primary" size="small" class="ml-2" @click="handleForm" v-if="add">New +</el-button>
            </div>
          </div>

          <div class="col-sm-12 mt-2">

            <div class="row">
              <div class="col-md-3" v-for="employee in employees" :key="employee.id">
                <employee-card :employee="employee" @handleShow="handleShow($event)" :select="select"
                  @handleSelect="handleSelect($event)"></employee-card>
              </div>
            </div>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Employee } from '@/resources/employee'
import EmployeeForm from './EmployeeForm.vue'
import EmployeeView from './EmployeeView.vue'
import EmployeeCard from './EmployeeCard.vue'
import SearchDepartment from '../../../Common/SearchDepartment.vue'
import { CompanyAnalytic } from '@/resources/company_analytic'
import StatsCard from '../../../UIComponents/Cards/StatsCard.vue'

export default {
  components: { EmployeeForm, EmployeeView, EmployeeCard, SearchDepartment, StatsCard },
  props: {
    title: {
      type: String,
      description: 'Heading Title',
      default: 'Manage Employees'
    },
    add: {
      type: Boolean,
      description: 'show add button',
      default: true
    },
    select: {
      type: Boolean,
      description: 'select employee',
      default: false
    },
    view: {
      type: Boolean,
      description: 'view employee',
      default: true
    }
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      showEmployee: false,
      employees: [],
      selected: null,
      perPageOptions: [4, 8, 12],
      pagination: {
        perPage: 4,
        currentPage: 1,
        total: 0
      },
      term: null,
      department_ids: [],
      active_employees_count: 0
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getEmployees()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getEmployees()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getEmployees()
    }
  },
  created: function () {
    this.getEmployees()
    this.getAnalytics()
  },
  methods: {
    getAnalytics() {
      this.$loading = true
      CompanyAnalytic.all()
        .then(result => {
          this.active_employees_count = result.data.active_employees_count
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    checkDepartments(ids) {
      this.department_ids = ids
      this.getEmployees()
    },
    handleSelect(employee) {
      this.$emit('onSelect', employee)
    },
    handleShow(employee) {
      this.showEmployee = true
      this.selected = Object.assign({}, employee)
    },
    handleEdit(employee) {
      this.showForm = true
      this.selected = Object.assign({}, employee)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showEmployee = false
      this.getEmployees()
    },
    handleDelete(data) {
      this.showDelete = true
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    deleteItem() {

    },
    getEmployees() {
      let params = {
        term: this.term, status: this.status, page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        department_ids: this.department_ids
      }
      this.$loading = true
      Employee.get({ params: params })
        .then(result => {
          if (result.data.employees)
            this.employees = result.data.employees
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch employees',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
