var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Job' : 'Create Job')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Title is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Description")]),_c('ValidationProvider',{attrs:{"name":"description"}},[_c('el-input',{attrs:{"type":"textarea","rows":2},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Workers Comp Code")]),_c('ValidationProvider',{attrs:{"name":"wc_code"}},[_c('el-input',{attrs:{"type":"text","rows":2},model:{value:(_vm.form.wc_code),callback:function ($$v) {_vm.$set(_vm.form, "wc_code", $$v)},expression:"form.wc_code"}})],1)],1),_c('div',{staticClass:"text-right"},[_c('el-button',{attrs:{"type":"info","native-type":"submit","size":"small"}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"button","size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])],1)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }