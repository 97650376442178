<template>
  <div v-if="user">
    <h5>Dashboard</h5>
    <hr>
    <employee-overview v-if="user.is_employee"></employee-overview>
    <div v-else>
      <employer-overview></employer-overview>
    </div>
  </div>
</template>
<script>
import EmployeeOverview from './EmployeeOverview.vue'
import EmployerOverview from './EmployerOverview.vue'

export default {
  components: { EmployeeOverview, EmployerOverview },
  data () {
    return {
      user: null
    }
  },
  created: function () {
    this.user = this.$store.state.user
    
    if (this.$route.query.refresh) {
      this.$router.push({ name: 'Home' })
      window.location.reload()
    }
  }
}

</script>
<style>

</style>
