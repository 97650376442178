<template>
  <div v-if="report">
    <div>STATE {{ report.name }}</div>
    <hr>
    <el-button plain type="primary" v-for="(r,i) in report.list" :key="i" @click="handleSelect(r)">{{ r }}</el-button>
  </div> 
</template>
<script>

import {
  mapState
} from 'vuex';

export default {
  props:['state'],
  data() {
    return {
      states: [
        {
          name: 'CA',
          list: [
            'CA DE9'
          ]
        }
      ],
      report:null
    };
  },
  watch: {
    state: function () {
      this.filterReports()
    },
  },
  created: function () {
    this.filterReports()
  },
  methods: {
    handleSelect(name) {
      this.$emit('click', name)
    },
    filterReports() {
      let self = this
      this.report = null
      this.states.forEach(function (state) {
        if (state.name == self.state) self.report = state
      })
    }
  },
}
</script>
<style></style>
