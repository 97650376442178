import api from './api'

const API = '/api/company/work-schedules'

export const WorkSchedule = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  download: function(id, params) {
    return api.create( `${API}/${id}/download`, params)
  }, 
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  addEmployee: function(id, form) {
    return api.create( `${API}/${id}/employees`, form)
  }, 
  removeEmployee: function(id, form) {
    return api.delete( `${API}/${id}/employees`, form)
  }, 
  getEmployees: function(id, form) {
    return api.get( `${API}/${id}/employees`, form)
  }, 
  saveStates: function(id, form) {
    return api.create( `${API}/${id}/states`, form)
  }, 
  getStates: function(id, form) {
    return api.get( `${API}/${id}/states`, form)
  }, 
}
