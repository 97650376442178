<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4">
            <stats-card :type="'primary'" icon="fa fa-users" :small-title="'Active Employees'"
              :title="active_employees_count">
              <div class="stats pointer" slot="footer" @click="goTo('company/employees')">
                <i :class="'fa fa-external-link'"></i>
                View more
              </div>
            </stats-card>
          </div>
          <div class="col-md-4">
            <stats-card :type="'warning'" icon="fa fa-clock-o" :small-title="'Clocked-in Employees'"
              :title="0">
              <div class="stats" slot="footer">
                <i :class="'fa fa-external-link'"></i>
                View more
              </div>
            </stats-card>
          </div>
          <div class="col-md-4">
            <stats-card :type="'danger'" icon="fa fa-exclamation-triangle" :small-title="'Unpaid Invoices'" :title="unpaid_invoices_count">
              <div class="stats pointer" slot="footer" @click="goTo('company/invoices')">
                <i :class="'fa fa-external-link'"></i>
                View more
              </div>
            </stats-card>
          </div>
        </div>
        <el-card class="mt-3">
          <h5><i class="fa fa-check-square-o mr-2"></i>TODO LIST</h5>
          <p>Nothing to do yet</p>
        </el-card>
      </div>
      <div class="col-md-4">
        <!-- <el-card>
          <h5 ><i class="fa fa-bookmark mr-2"></i>SHORTCUTS</h5>

        </el-card> -->
        <el-card class="">
          <h5><i class="fa fa-calendar-o mr-2"></i>Upcoming Events</h5>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import StatsCard from "../../../UIComponents/Cards/StatsCard.vue"
import { CompanyAnalytic } from '@/resources/company_analytic'


export default {
  components: { StatsCard },
  data() {
    return {
      user: null,
      active_employees_count: 0,
      unpaid_invoices_count: 0
    }
  },
  created: function () {
    this.user = this.$store.state.user
    this.getAnalytics()
  },
  methods: {
    getAnalytics() {
      this.$loading = true
      CompanyAnalytic.all()
        .then(result => {
          this.active_employees_count = result.data.active_employees_count
          this.unpaid_invoices_count = result.data.unpaid_invoices_count
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    goTo(path) {
      this.$router.push({ path: path })
    }
  }
}

</script>
<style></style>
