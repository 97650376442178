<template>
<div class="" v-if="work_schedule">
  <h5>{{ work_schedule.name }}</h5>
  <div class="row">
    <div class="col-md-5">
      <div>Period: {{ work_schedule.start_date }} to {{ work_schedule.end_date }}</div>
      <div>Job: {{ work_schedule.company_job.title }}</div>
      <div>Workers Comp Code: {{ work_schedule.company_job.wc_code }}</div>
      <div>Assigned Employees: {{ total }}</div>
    </div>
    <div class="col-md-7 text-right">
      <el-button type="info" size="mini" @click="download()">DOWNLOAD PDF COPY <i class="fa fa-download"></i></el-button>
    </div>
  </div>
  <el-menu @select="setMenu" :default-active="tab" class="el-menu-demo mt-4" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
    <el-menu-item index="employees">Employees</el-menu-item>
    <el-menu-item index="states">States</el-menu-item>
  </el-menu>

  <work-employees-list v-if="tab == 'employees'" :work_schedule="work_schedule" @update="setEmployees($event)"></work-employees-list>
  <work-schedule-states-list v-if="tab == 'states'" :work_schedule="work_schedule" @update="setEmployees($event)"></work-schedule-states-list>
</div>
</template>

<script>
import {
  WorkSchedule
} from '@/resources/work_schedule'
import WorkEmployeesList from './WorkEmployeesList.vue'
import WorkScheduleStatesList from './WorkScheduleStatesList.vue'

export default {
  props: ['work_schedule'],
  components: {
    WorkEmployeesList,
    WorkScheduleStatesList

  },
  data() {
    return {
      tab: "employees",
      total: 0
    }
  },
  created: function () {
    this.total = this.work_schedule.total_employees
  },
  methods: {
    setMenu(event) {
      this.tab = event
    },
    setEmployees(total) {
      this.total = total
    },
    download() {
      this.$loading = true
      WorkSchedule.download(this.work_schedule.id)
        .then(result => {
         const link = document.createElement('a');
          link.setAttribute('href', result.data.file.link); // Replace 'path_to_your_file' with the file URL or data
          link.setAttribute('download', result.data.file.filename); // Replace 'filename.ext' with the desired file name and extension

          // Simulate click event
          link.click();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
