<template>
  <div>
    <el-select v-model="select_payroll_ids" :multiple="multiple" clearable placeholder="Select"
      popper-class="custom-header" @change="handleChange">
      <el-option v-for="payroll in payrolls" :key="payroll.id" :value="payroll.id" :label="getName(payroll)"></el-option>
    </el-select>
  </div>
</template>
<script>


import { Payroll } from '@/resources/payroll';

export default {
  props: {
    end_date: {
      description: 'Error message',
      default: null
    },
    start_date: {
      description: 'Department',
      default: null
    },
    multiple: {
      type: Boolean,
      description: 'Whether multiple',
      default: false
    }
  },
  data() {
    return {
      payrolls: [],
      select_payroll_ids: [],
      loading :false
    };
  },
  watch: {
    'start_date': function () {
      if (this.start_date && this.end_date) this.getPayrolls()
    },
    'end_date': function () {
      if (this.start_date && this.end_date) this.getPayrolls()
    },
  },
  created: function () {
    if (this.start_date && this.end_date) this.getPayrolls()
  },
  methods: {
    getName(payroll) {
      return payroll.payroll_group.name + " Check Date:"+ payroll.check_date + ' | ' + payroll.paychecks_count+ " Paychecks"
    },
    getPayrolls() {
      this.loading = true
      let start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      let end_date = this.$moment(this.end_date).format('YYYY-MM-DD')
      console.log(start_date, end_date)

      Payroll.getAll({ params: { per_page: 999, start_date: start_date, end_date: end_date } })
        .then(result => {
          this.payrolls = result.data.payrolls
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to fetch payrolls at the moment'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    },
    handleChange(item) {
      this.$emit('update', item)
    }
  },
};
</script>

<style scoped lang="scss">
.error-text {
  display: block;
}

.custom-header {
  .el-checkbox {
    display: flex;
    height: unset;
  }
}
</style>