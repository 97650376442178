var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Default Earning' : 'Create New Default Earning')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"company_earning","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                    { 'has-danger': failed },
                    { 'has-success': passed }],attrs:{"filterable":"","placeholder":"Name","remote-method":_vm.getCompanyEarnings,"loading":_vm.loading,"remote":""},model:{value:(_vm.form.company_earning),callback:function ($$v) {_vm.$set(_vm.form, "company_earning", $$v)},expression:"form.company_earning"}},_vm._l((_vm.company_earnings),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item}})}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Name is required")]):_vm._e()]}}],null,true)})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Hours")]),_c('ValidationProvider',{attrs:{"name":"hours","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
                    var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'Start date is required' : null,"hasSuccess":passed,"name":"hours"},model:{value:(_vm.form.hours),callback:function ($$v) {_vm.$set(_vm.form, "hours", $$v)},expression:"form.hours"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Rate")]),_c('ValidationProvider',{attrs:{"name":"rate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var passed = ref.passed;
                    var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'Rate is required' : null,"hasSuccess":passed,"name":"rate"},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", $$v)},expression:"form.rate"}})]}}],null,true)})],1)]),(_vm.form.company_earning)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('el-switch',{attrs:{"disabled":"","active-value":1,"inactive-value":0,"active-text":"Accrue","inactive-text":"","active-color":"#13ce66","inactive-color":"#909399"},model:{value:(_vm.form.company_earning.is_accruable),callback:function ($$v) {_vm.$set(_vm.form.company_earning, "is_accruable", $$v)},expression:"form.company_earning.is_accruable"}})],1)]):_vm._e(),(_vm.form.company_earning)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('el-switch',{attrs:{"disabled":"","active-value":1,"inactive-value":0,"active-text":"Taxable","inactive-text":"","active-color":"#13ce66","inactive-color":"#909399"},model:{value:(_vm.form.company_earning.type),callback:function ($$v) {_vm.$set(_vm.form.company_earning, "type", $$v)},expression:"form.company_earning.type"}})],1)]):_vm._e()]),_c('p-button',{attrs:{"type":"info","native-type":"submit","size":"small"}},[_vm._v("Save")]),_c('p-button',{attrs:{"type":"button","size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }