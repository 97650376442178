<template>
  <div>
    <div v-if="employee_tax_info && !showTaxInfoForm" class="row ">
      <el-descriptions class="margin-top col-md-6" title="Info" :column="1" size="small" border v-if="employee_tax_info.type == 0">
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Tax Type
            </template>
            {{ $store.state.tax_types[employee_tax_info.type] }}
          </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top col-md-6" title="Info" :column="1" size="small" border v-if="employee_tax_info.type == 1">
        <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Tax Type
            </template>
            {{ $store.state.tax_types[employee_tax_info.type] }}
          </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            W4 Type
          </template>
          {{ $store.state.w4_types[employee_tax_info.w4_type] }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Payment Method
          </template>
          {{ $store.state.payment_methods[employee_tax_info.payment_method] }}
        </el-descriptions-item>
        <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Home Zip 
            </template>
            {{ employee_tax_info.home_zip }} - {{ employee_tax_info.home_state }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Work Zip
            </template>
            {{ employee_tax_info.work_zip }} - {{ employee_tax_info.work_state }}
          </el-descriptions-item>
      </el-descriptions>
      <div class="col-md-12"><hr></div>
      <el-descriptions class="margin-top col-md-6" title="Federal" :column="1" size="small" border v-if="employee_tax_info.type == 1">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Is federal exempted?
          </template>
          {{ employee_tax_info.is_federal_exempted ? 'Yes':'No'}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Federal Filing Status
          </template>
          {{ $store.state.federal_filing_statuses[employee_tax_info.federal_filing_status] }}
        </el-descriptions-item>
        <el-descriptions-item>
            <template slot="label">
            <i class="el-icon-user"></i>
            Children dependents
          </template>
          {{ employee_tax_info.children_dependents }}
        </el-descriptions-item>
        <el-descriptions-item>
              <template slot="label">
              <i class="el-icon-user"></i>
              Other dependents
            </template>
            {{ employee_tax_info.other_dependents }}
          </el-descriptions-item>
          <el-descriptions-item>
              <template slot="label">
              <i class="el-icon-user"></i>
              Total dependents
            </template>
            {{ employee_tax_info.total_dependents }}
          </el-descriptions-item>
          <el-descriptions-item>
                <template slot="label">
                <i class="el-icon-user"></i>
                Federal additional deductions
              </template>
              ${{ employee_tax_info.federal_additional_deductions }}
            </el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top col-md-6" title="State" :column="1" size="small" border v-if="employee_tax_info.type == 1">
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Is state exempted?
            </template>
            {{ employee_tax_info.is_federal_exempted ? 'Yes' : 'No' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              State Filing Status
            </template>
            {{ $store.state.federal_filing_statuses[employee_tax_info.state_filing_status] }}
          </el-descriptions-item>
          <el-descriptions-item>
              <template slot="label">
              <i class="el-icon-user"></i>
              Children dependents
            </template>
            {{ employee_tax_info.state_children_dependents }}
          </el-descriptions-item>
          <el-descriptions-item>
                <template slot="label">
                <i class="el-icon-user"></i>
                Other dependents
              </template>
              {{ employee_tax_info.state_other_dependents }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                <i class="el-icon-user"></i>
                Total dependents
              </template>
              {{ employee_tax_info.state_total_dependents }}
            </el-descriptions-item>
            <el-descriptions-item>
                  <template slot="label">
                  <i class="el-icon-user"></i>
                  Federal additional deductions
                </template>
                ${{ employee_tax_info.state_additional_deductions }}
              </el-descriptions-item>
        </el-descriptions>



      <div class="col-md-12 mt-2">
        <el-button size="small" @click="handleAddInfo">Update tax info</el-button>
      </div>
    </div>

    <employee-tax-info-form  v-if="showTaxInfoForm" :employee="employee" :taxInfo="employee_tax_info"
        @save="setTaxInfo($event)"></employee-tax-info-form>
  </div>
</template>
<script>

import EmployeePersonalInfoForm from './EmployeePersonalInfoForm.vue';
import EmployeeTaxInfoForm from './EmployeeTaxInfoForm.vue';

export default {
  props: ['employee'],
  components: { EmployeePersonalInfoForm, EmployeeTaxInfoForm },
  data() {
    return {
      showTaxInfoForm: false,
      employee_tax_info: null,
    };
  },
  watch: {
    employee: function () {
      this.employee_tax_info = Object.assign({}, this.employee.employee_tax_info)
    }
  },
  created: function () {
    this.employee_tax_info = Object.assign({}, this.employee.employee_tax_info)
  },
  methods: {
    setTaxInfo(employee_tax_info) {
      this.showTaxInfoForm = false
      if (employee_tax_info) this.employee_tax_info = Object.assign({}, employee_tax_info)
    },
    handleAddInfo() {
      this.showTaxInfoForm = true
    },
  }
}
</script>
<style></style>
