<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <company-form @save="getCompanies()" v-if="showForm" :company="selected"></company-form>
          </div>
        </div>

      </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Companies</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term"
              addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="companies" style="width: 100%">
            

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="FEIN">
                <template slot-scope="props">
                  {{ props.row.fein }}
                </template>
              </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                    <i class="fa fa-edit"></i>
                  </el-button>
                  <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Company } from '@/resources/company'
import CompanyForm from './CompanyForm.vue'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'

export default {
  components: { CompanyForm, ConfirmDelete },
  data() {
    return {
      showDelete: false,
      showForm: false,
      companies: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getCompanies()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getCompanies()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getCompanies()
    }
  },
  created: function () {
    this.getCompanies()
  },
  methods: {
    handleEdit(company) {
      this.showForm = true
      this.selected =  Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
    },
    handleDelete(payroll) {
      this.$loading = true
      this.$loadingText = "Removing"

      Company.delete({ id: payroll.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Company has been removed',
          });
          this.getCompanies()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getCompanies() {
      this.handleClose()
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      Company.get({ params: params })
        .then(result => {
          if (result.data.companies)
            this.companies = result.data.companies
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch companies',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
