var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticStyle:{"width":"250px","display":"block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(!_vm.form.delete_)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card bg-light-gray"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"company_earning","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"default-first-option":true,"readonly":!_vm.editable,"clearable":"","filterable":"","placeholder":"Name","remote-method":_vm.getCompanyEarnings,"loading":_vm.loading,"remote":""},model:{value:(_vm.form.company_earning),callback:function ($$v) {_vm.$set(_vm.form, "company_earning", $$v)},expression:"form.company_earning"}},_vm._l((_vm.company_earnings),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name+' - ' + _vm.$store.state.earning_types[item.type],"value":item}})}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Name is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Hours")]),_c('ValidationProvider',{attrs:{"name":"hours","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{attrs:{"readonly":!_vm.editable,"type":"number","error":failed ? 'Start date is required' : null,"hasSuccess":passed,"name":"hours"},model:{value:(_vm.form.hours),callback:function ($$v) {_vm.$set(_vm.form, "hours", $$v)},expression:"form.hours"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Rate")]),_c('ValidationProvider',{attrs:{"name":"rate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{attrs:{"readonly":!_vm.editable,"type":"number","error":failed ? 'Rate is required' : null,"hasSuccess":passed,"name":"rate"},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", $$v)},expression:"form.rate"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('el-switch',{attrs:{"disabled":"","active-value":1,"inactive-value":0,"active-text":"Accrue","inactive-text":"","active-color":"#13ce66","inactive-color":"#909399"},model:{value:(_vm.form.is_accruable),callback:function ($$v) {_vm.$set(_vm.form, "is_accruable", $$v)},expression:"form.is_accruable"}})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('el-switch',{attrs:{"disabled":"","active-value":1,"inactive-value":0,"active-text":"Taxable","inactive-text":"","active-color":"#13ce66","inactive-color":"#909399"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)]),_c('div',{staticClass:"col-md-12 mb-2"},[_c('div',[_vm._v("Amount:")]),_vm._v(" "+_vm._s(_vm.money(_vm.form.amount))+" "),(_vm.form.id)?_c('span',{staticClass:"ytd"},[_vm._v(" (YTD: "+_vm._s(_vm.money(_vm.form.ytd_amount))+")")]):_vm._e()]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Workers Comp")]),_c('ValidationProvider',{attrs:{"name":"hours","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{attrs:{"readonly":!_vm.editable,"type":"text","error":failed ? 'Start date is required' : null,"hasSuccess":passed,"name":"hours"},model:{value:(_vm.form.wc_code),callback:function ($$v) {_vm.$set(_vm.form, "wc_code", $$v)},expression:"form.wc_code"}})]}}],null,true)})],1)])]),(_vm.editable)?_c('confirm-delete',{attrs:{"klass":"btn-block","label":"Delete"},on:{"onClick":function($event){return _vm.handleDelete(_vm.form)}}}):_vm._e()],1)])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }