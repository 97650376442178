<template>
<div>
  <div class="row">
    <div class="col-md-12">
      <el-alert type="success" :closable="false" effect="dark">You have the option to move the employee cards both horizontally, either from left to right or right to left.</el-alert>
    </div>

    <div class="col-md-5">
      <h3>Employees in the Work Schedule</h3>
      <div class="row">
        <div class="col-sm-4">
          <div>
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-md-12 no-data-text" v-if="!employees.length">
          No employees
        </div>
      </div>
      <draggable group="people" :list="employees" style=";min-height: 400px;" class="row" @change="employeesListUpdated">
        <div class="col-md-4" v-for="employee, i in employees" :key="i">
          <employee-mini-card :employee="employee" @click="removeEmployee(employee)" :buttonText="'Remove'" :buttonType="'info'" :plain="true"></employee-mini-card>
        </div>
      </draggable>

      <div class="pull-right">
        <p-pagination class="" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>

    <div class="col-md-1"></div>
    <div class="col-md-5">
      <h3>Search Employees</h3>
      <draggable-employees-list @click="addEmployee($event)" :addEmployee="returnEmployee"></draggable-employees-list>
    </div>

  </div>

</div>
</template>

<script>
import draggable from "vuedraggable";
import DraggableEmployeesList from '../Company/Employees/DraggableEmployeesList.vue';
import EmployeeMiniCard from '../Company/Employees/EmployeeMiniCard.vue';
import {
  WorkSchedule
} from '@/resources/work_schedule'

export default {
  props: {
    work_schedule: {
      type: Object,
      default: null
    }
  },
  components: {
    draggable,
    DraggableEmployeesList,
    EmployeeMiniCard
  },
  data() {
    return {
      employees: [],
      pagination: {
        perPage: 6,
        currentPage: 1,
        total: 0
      },
      term: null,
      returnEmployee: null
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getEmployees()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getEmployees()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getEmployees()
    }
  },
  created: function () {
    this.getEmployees()
  },
  methods: {
    removeEmployee(employee) {
      this.$loading = true
      WorkSchedule.removeEmployee(this.work_schedule.id, {
          employee_id: employee.id,
          work_schedule_id: this.work_schedule.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee removed',
          });
          this.returnEmployee = Object.assign({}, employee)

        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
          this.getEmployees()
        })
    },
    addEmployee(employee) {
      this.$loading = true
      WorkSchedule.addEmployee(this.work_schedule.id, {
          employee_id: employee.id,
          work_schedule_id: this.work_schedule.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee added',
          });

        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
          this.getEmployees()
        })
    },
    employeesListUpdated(evt) {
      let employee = evt.added ? evt.added.element : evt.removed.element
      let added = evt.added

      if (added) {
        this.addEmployee(employee)
      } else {
        this.removeEmployee(employee)
      }
    },
    getEmployees() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      WorkSchedule.getEmployees(this.work_schedule.id, {
          params: params
        })
        .then(result => {
          if (result.data.employees)
            this.employees = result.data.employees
          this.pagination = result.data.meta
          this.$emit('update', result.data.meta.total)
        })
        .catch(() => {

        }).finally(() => {})
    }
  }
}
</script>

<style lang="scss"></style>
