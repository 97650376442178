<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showPaycheck" direction="btt" :before-close="getPaychecks" size="90%"
      :destroy-on-close="true">
      <paycheck-view :paycheck_id="selected.id" v-if="selected" @close="getPaychecks()"></paycheck-view>
    </el-drawer>

    <el-drawer title="" :visible.sync="showAddForm" direction="btt" :before-close="getPaychecks" size="90%"
      :destroy-on-close="true">
      <add-employee-to-payroll-form v-if="selected" :payroll="selected.payroll"
        @close="getPaychecks()"></add-employee-to-payroll-form>
    </el-drawer>

    <el-drawer title="" :visible.sync="showReports" direction="btt" :before-close="getPaychecks" size="90%"
      :destroy-on-close="true">
      <payroll-reports-list :payroll="selected" @close="getPaychecks()" style="padding:10px"></payroll-reports-list>
    </el-drawer>

    <el-drawer title="" :visible.sync="showTaxes" direction="btt" :before-close="getPaychecks" size="90%"
      :destroy-on-close="true">
      <paycheck-taxes-override @close="getPaychecks" :paycheck="selected"></paycheck-taxes-override>
    </el-drawer>

    <el-drawer title="" :visible.sync="showPayrollForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
          <payroll-form :payroll="selected" @close="getPaychecks()"></payroll-form>
        </div>
      </div>
    </el-drawer>

    <div class="col-md-12 card" v-if="payroll">
      <div class="card-header " style="padding: 10px">
        <page-header :title="'Manage Paychecks - ' + (payroll ? payroll.check_date : '')"
          :url="'/company/payrolls/'+payroll.payroll_group_id+'/list'"></page-header>
      </div>
    </div>
    <div class="col-md-12 card">
      <div class="card-body row" v-if="payroll">
        <div class="col-md-12 row mb-2">
          <div class="col-md-4">
            <div>Payroll Between: {{ payroll.pay_start_date }} - {{ payroll.pay_end_date }}</div>
            <div>Schedule Type: {{ $store.state.schedule_types[payroll.schedule_type] }}</div>
            <div>Payroll Type: {{ $store.state.payroll_types[payroll.payroll_type] }}</div>
          </div>
          <div class="col-md-8" style="text-align: right;">
            <el-button size="mini" type="info" @click="getPaychecks()" class="ml-2">REFRESH DATA</el-button>
            <el-button size="mini" type="success" @click="handleEditPayroll()" class="ml-2 mr-2" plain
              v-if="payroll.status == 0">EDIT PAYROLL</el-button>
            <confirm-delete @onClick="handleDeleteAll()" :label="'Delete All Paychecks'"
              v-if="payroll.status == 0"></confirm-delete>
          </div>
        </div>
        <div class="col-md-12 mb-2">
          <el-alert :closable="false" center show-icon type="info" effect="dark" v-if="payroll.status == 0">PAYROLL IN
            PROGRESS</el-alert>
          <el-alert :closable="false" center show-icon type="warning" effect="dark" v-if="payroll.status == 1">WAITING
            FO APPROVAL</el-alert>
          <el-alert :closable="false" center show-icon type="success" effect="dark"
            v-if="payroll.status == 2">COMPLETED</el-alert>
        </div>
        <div class="col-md-12">

          <hr>
        </div>
        <div class="col-sm-3">
          <div class="">
            <el-input prefix-icon="nc-icon nc-zoom-split" size="small" class="input-sm" placeholder="Search"
              v-model="term">
              <el-button slot="append" type="primary" size="small" @click="handleForm"
                :disabled="payroll.loading_status == 1" v-if="payroll.status == 0">Add Employees +</el-button>
            </el-input>

          </div>
        </div>
        <div class="col-sm-9">
          <div class="pull-right">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

        </div>

        <div class="col-sm-12 mt-2" v-loading="loading_paychecks">
          <div v-if="payroll.remarks">
            <el-alert :closable="false" show-icon effect="dark" style="font-size: 20px;" type="warning" title="Note:"
              :description="payroll.remarks || 'Adding 1 out of 100 employees'"></el-alert>
            <hr>
          </div>
          <el-table class="table-striped font-12" header-row-class-name="text-primary" :data="paychecks"
            style="width: 100%">

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Full Name">
              <template slot-scope="props">
                {{ props.row.employee.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="30" fixed="right" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ $store.state.tax_types[props.row.paycheck_tax_info.type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions text-right" label="Earnings">
              <template slot-scope="props">
                <div class="text-right">{{ money(props.row.total_taxable_earnings) }}</div>
              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions text-right">
              <template slot="header">
                <div>Other</div>
                <div>Earnings</div>
              </template>
              <template slot-scope="props">
                <div class="text-right">{{ money(props.row.total_non_taxable_earnings) }}</div>
              </template>
            </el-table-column>
            <el-table-column :min-width="80" fixed="right" class-name="td-actions text-right">
              <template slot="header">
                <div>Pre-Tax</div>
                <div>Deductions</div>
              </template>
              <template slot-scope="props">
                <div class="text-right">{{ money(props.row.total_pre_tax_deductions) }}</div>

              </template>
            </el-table-column>

            <el-table-column :min-width="80" fixed="right" class-name="td-actions text-right">
              <template slot="header">
                <div>Other</div>
                <div>Deductions</div>
              </template>
              <template slot-scope="props">
                <div class="text-right">{{ money(props.row.total_other_tax_deductions) }}</div>

              </template>
            </el-table-column>
            <el-table-column :min-width="50" fixed="right" class-name="td-actions text-right" label="Taxes">
              <template slot-scope="props">
                <div class="text-right">
                  <el-link type="success" class="text-green" @click="handleShowTaxes(props.row)"> {{
                    money(props.row.total_employee_taxes) }}</el-link>
                </div>

              </template>
            </el-table-column>
            <el-table-column :min-width="80" fixed="right" class-name="td-actions text-right" label="Check Amount">
              <template slot-scope="props">
                <div class="text-right">{{ money(props.row.check_amount) }}</div>

              </template>
            </el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions text-right" label="Actions">
              <template slot-scope="props">
                <el-button class="mr-2" v-if="payroll.status == 2" size="mini" type="default" link
                  @click="handleDownload(props.row)">
                  PAYSTUB <i class="fa fa-file"></i>
                </el-button>

                <el-button class="mr-2" v-if="payroll.status == 0" size="mini" type="default" link
                  @click="handleEdit(props.row.id)">
                  EDIT <i class="fa fa-edit"></i>
                </el-button>
                <confirm-delete @onClick="handleDelete(props.row)" v-if="payroll.status == 0"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>

    <div class="col-md-12 text-center" v-if="payroll && !payroll.invoice">
      <confirm-button :label="'SUBMIT'" :type="'primary'" v-if="payroll.status == 0"
        @onClick="submitPayroll()"></confirm-button>
      <confirm-button :label="'APPROVE TO COMPLETE'" :type="'success'" class="ml-2 mr-2" v-if="payroll.status == 1"
        @onClick="completePayroll()"></confirm-button>
      <confirm-button :label="'UNSUBMIT'" :type="'warning'" v-if="payroll.status > 0"
        @onClick="handleUnsubmit()"></confirm-button>
    </div>
    <div class="col-md-12 text-center" v-if="payroll && payroll.status == 2">
      <hr>
      <el-button class="" size="mini" type="default" link @click="handleShowReports()">
        REPORTS <i class="fa fa-file"></i>
      </el-button>
    </div>

    <div class="col-md-12">
      <payroll-summary :payroll="payroll"></payroll-summary>
    </div>
    <div class="col-md-12 text-center mt-3" v-if="payroll && payroll.invoice">
      <el-alert type="info" effect="dark">
        The invoice has already been generated. To modify this payroll, you'll need to delete the invoice first.
      </el-alert>
    </div>
  </div>
</template>

<script>
import {
  Payroll
} from '@/resources/payroll'
import {
  Paycheck
} from '@/resources/paycheck'
import AddEmployeeToPayrollForm from '../AddEmployeeToPayrollForm.vue'
import ConfirmDelete from '../../../../Common/ConfirmDelete.vue'
import PaycheckView from './PaycheckView.vue'
import PayrollSummary from '../PayrollSummary.vue'
import ConfirmButton from '../../../../Common/ConfirmButton.vue'
import PayrollForm from '../PayrollForm.vue'
import PayrollReportsList from '../Reports/PayrollReportsList.vue'
import PaycheckTaxesOverride from './PaycheckTaxesOverride.vue'
// import PayrollForm from './PayrollForm.vue'

export default {
  components: {
    AddEmployeeToPayrollForm,
    ConfirmDelete,
    PaycheckView,
    PayrollSummary,
    ConfirmButton,
    PayrollForm,
    PayrollReportsList,
    PaycheckTaxesOverride
  },
  data() {
    return {
      visible: false,
      showTaxes: false,
      showReports: false,
      showPayrollForm: false,
      showDelete: false,
      showAddForm: false,
      showPaycheck: false,
      paychecks: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      payroll: null,
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      loading_paychecks: false,
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPaychecks()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPaychecks()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      let page = parseInt(this.$route.query.page)
      if (page != this.pagination.currentPage) this.$router.push({
        path: this.$route.fullPath,
        query: {
          page: this.pagination.currentPage
        }
      });
      if (!this.$loading) this.getPaychecks()
    }
  },
  created: function () {
    const currentRoute = this.$router.currentRoute;
    if (currentRoute.query.paycheck_id) {
      this.handleEdit(parseInt(currentRoute.query.paycheck_id))
    } else {
      this.getPaychecks()
    }

  },
  methods: {
    handleShowTaxes(paycheck) {
      this.showTaxes = true
      this.selected = Object.assign({}, paycheck)
    },
    handleShowReports() {
      this.showReports = true
      this.selected = Object.assign({}, this.payroll)
    },
    handleEditPayroll() {
      this.showPayrollForm = true
      this.selected = Object.assign({}, this.payroll)
    },
    handleUnsubmit() {
      this.$loading = true

      Payroll.updateStatus({
          id: this.payroll.id,
          status: 0,
          payroll_group_id: this.payroll.payroll_group_id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'The payroll submission has been retracted.',
          });
          this.getPaychecks()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    completePayroll() {
      this.$loading = true

      Payroll.updateStatus({
          id: this.payroll.id,
          status: 2,
          payroll_group_id: this.payroll.payroll_group_id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payroll is now completed',
          });
          this.getPaychecks()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    submitPayroll() {
      this.$loading = true

      Payroll.updateStatus({
          id: this.payroll.id,
          status: 1,
          payroll_group_id: this.payroll.payroll_group_id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payroll has been submitted',
          });
          this.getPaychecks()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDeleteAll() {
      this.$loading = true
      this.$loadingText = "Removing"

      Payroll.deleteAllPaychecks({
          id: this.payroll.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'All paychecks have been removed',
          });
          this.getPaychecks()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDelete(paycheck) {
      this.$loading = true
      this.$loadingText = "Removing"

      Paycheck.delete({
          id: paycheck.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Paycheck has been removed',
          });
          this.getPaychecks()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleView(group) {

    },
    handleDownload(paycheck) {
      this.$loading = true
      Paycheck.downloadPaystub(paycheck.id)
        .then(result => {
          const link = document.createElement('a');
          link.setAttribute("target", '_blank')
          link.setAttribute('href', result.data.paystub.app_url); // Replace 'path_to_your_file' with the file URL or data
          link.setAttribute('download', result.data.paystub.filename); // Replace 'filename.ext' with the desired file name and extension

          // Simulate click event
          link.click();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleEdit(id) {
      this.showPaycheck = true
      this.selected = Object.assign({}, {
        id: id
      })

      const currentRoute = this.$router.currentRoute;
      if (parseInt(currentRoute.query.paycheck_id) !== id) {
        const updatedQuery = {
          ...currentRoute.query,
          paycheck_id: id
        };
        this.$router.push({
          path: currentRoute.path,
          query: updatedQuery
        });
      }
    },
    handleClose() {
      this.showDelete = false
      this.showAddForm = false
      this.showPaycheck = false
      this.showPayrollForm = false
      this.showReports = false
      this.showTaxes = false
    },
    handleForm() {
      this.showAddForm = true
      this.selected = {
        payroll: this.payroll
      }

    },
    deleteItem() {

    },
    setRoute() {
      let page = parseInt(this.$route.query.page)
      if (!page) {
        this.$router.push({
          path: this.$route.fullPath,
          query: {
            page: this.pagination.currentPage
          }
        });
      } else {
        this.pagination.currentPage = page
      }
    },
    getPayroll() {
      Payroll.show(this.$route.params.payroll_id)
        .then(result => {
          this.payroll = result.data.payroll
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPaychecks() {
      this.getPayroll()
      this.loading_paychecks = true
      this.$loading = true
      this.handleClose()
      this.setRoute()

      let payrollId = this.$route.params.payroll_id
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        payroll_id: payrollId
      }

      const currentRoute = this.$router.currentRoute;
      if (currentRoute.query.paycheck_id) {
        const updatedQuery = {
          ...currentRoute.query,
          paycheck_id: null
        };
        this.$router.push({
          path: currentRoute.path,
          query: updatedQuery
        });
      }

      Paycheck.get({
          params: params
        })
        .then(result => {
          if (result.data.paychecks)
            this.paychecks = result.data.paychecks
          this.pagination = result.data.meta
          // this.payroll = result.data.payroll
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
          this.loading_paychecks = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
