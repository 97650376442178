<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Position' : 'Create New Position' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'Name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <p-button type="info" native-type="submit" size="small">Save</p-button><p-button type="button" size="small"
            @click="close()">Close</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Position } from '@/resources/position'

extend("required", required);

export default {
  props: ['position'],
  data() {
    return {
      form: {
        name: null
      }
    };
  },
  watch: {
    position: function () {
      if (this.position) this.form = Object.assign({}, this.position)
    }
  },
  created: function () {
    if (this.position) this.form = Object.assign({}, this.position)
  },
  beforeDestroy() {
    this.form = {
      name: null
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? Position.update(this.form) : Position.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Position has been saved',
          });
          this.$emit('close', result.data.position)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
