<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group">
        <label>Companies</label>
        <el-select
          v-model="value"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="Please enter a keyword"
          :remote-method="remoteMethod"
          :loading="$loading">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
     
      <div class="text-right"><p-button type="success" native-type="submit">Save</p-button></div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { User } from '@/resources/user'
import { Company } from '@/resources/company'

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("regex", regex);

export default {
  props: ['user'],
  components: {},
  data() {
    return {
      value: [],
      options: [],
      form: {
        companies: []
      }
    };
  },
  created: function () {
    this.form = Object.assign({}, this.user)
    this.getUserCompanies()
  },
  watch: {
    value: function() {
      console.log(this.value)
    }
  },
  methods: {
    getUserCompanies() {
      let params = {page: 1, per_page: 10, user_id: this.user.id }
      Company.get({ params: params })
        .then(result => {
          if (result.data.companies)
            this.form.companies = result.data.companies
          let self = this
          this.form.companies.forEach(function (company) {
            self.value.push(company.name)
          })
          this.remoteMethod()
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch companies',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.$loading = true
        let params = { term: query, page: 1, per_page: 10 }
        Company.get({ params: params })
          .then(result => {
            if (result.data.companies) {
              this.options = result.data.companies.map(company => {
                return { value: company.name };
              });
            }
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              title: "Error",
              text: 'Unable to fetch companies',
            });

          }).finally(() => {
            this.$loading = false
          })
      }
    },
    submit() {
      this.$loading = true

      User.updateCompanies({id: this.user.id, companies: this.value})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Account updated',
          });

        })
        .catch(() => {
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
