<template>
  <div class="row" v-if="payroll">
    <div class="col-md-12">
      <h4>Payroll Summary</h4>
    </div>
    <div class="col-md-3">
      <el-card  class="bg-light-gray">
        <div>Total Earnings</div>
        <div class="font-50">{{ money(payroll.total_taxable_earnings + payroll.total_non_taxable_earnings) }}</div>
      </el-card>
    </div>
    <div class="col-md-3">
        <el-card  class="bg-light-blue">
          <div>Total Deductions</div>
          <div class="font-50">{{ money(payroll.total_pre_tax_deductions + payroll.total_other_tax_deductions)}}</div>
        </el-card>
      </div>
      <div class="col-md-3">
        <el-card  class="bg-light-orange">
          <div>Total Taxes</div>
          <div class="font-50"> {{ money(payroll.total_employee_taxes)}}</div>
        </el-card>
      </div>
      <div class="col-md-3">
        <el-card class="bg-light-green">
          <div>Total Check Amount</div>
            <div class="font-50">{{ money(payroll.total_check_amount)}}</div>
          </el-card>
      </div>
  </div>
</template>
<script>


export default {
  props: ['payroll'],
  data() {
    return {
      
    };
  },
  methods: {
   
  }
}
</script>
<style></style>
