<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ form.id ? 'Update Job' : 'Create Job' }}
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Title</label>
          <ValidationProvider name="title" rules="required" v-slot="{ passed, failed }">
            <el-input v-model="form.title" placeholder="Title" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
            </el-input>
            <span class="error-text" v-if="failed">Title is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Description</label>
          <ValidationProvider name="description">
            <el-input type="textarea" :rows="2" v-model="form.description"></el-input>
          </ValidationProvider>
        </div>

        <div class="form-group">
            <label>Workers Comp Code</label>
            <ValidationProvider name="wc_code">
              <el-input type="text" :rows="2" v-model="form.wc_code"></el-input>
            </ValidationProvider>
          </div>

        <div class="text-right">
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Close</el-button>
        </div>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  CompanyJob
} from '@/resources/company_job'

extend("required", required);

export default {
  props: ['company_job'],
  data() {
    return {
      form: {
        title: null,
        description: null,
        wc_code: null
      }
    };
  },
  watch: {
    company_job: function () {
      if (this.company_job) this.form = Object.assign({}, this.company_job)
    }
  },
  created: function () {
    if (this.company_job) this.form = Object.assign({}, this.company_job)
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? CompanyJob.update(this.form) : CompanyJob.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Job has been saved',
          });
          this.$emit('close', result.data.company_job)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
