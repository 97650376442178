<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this deduction? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :append-to-body="true" :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <employee-default-deduction-form :default_deduction="selected" :employee="employee"
            @close="getDefaultDeductions"></employee-default-deduction-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12">
      <div class="card-body row">
        <div class="col-sm-6">
          <el-button type="primary" size="mini" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">

        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="default_deductions"
            style="width: 100%">


            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.company_deduction.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ $store.state.deduction_types[props.row.company_deduction.type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Amount">
              <template slot-scope="props">
                {{ money(props.row.amount) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" size="small" link @click="handleEdit(props.row)" v-if="edit">
                  <i class="fa fa-edit"></i>
                </el-button>

                <confirm-delete @onClick="handleDelete(props.row)" :label="''"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { EmployeeDefaultDeduction } from '@/resources/employee_default_deduction'
import EmployeeDefaultDeductionForm from './EmployeeDefaultDeductionForm.vue'
import ConfirmDelete from '../../../../Common/ConfirmDelete.vue'

export default {
  components: { EmployeeDefaultDeductionForm, ConfirmDelete },
  props: {
    edit: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    remove: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    select: {
      type: Boolean,
      description: 'Heading Title',
      default: false
    },
    employee: {
      type: Object,
      description: 'Employee Object',
      required: true
    }
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      default_deductions: [],
      selected: null,
      term: null
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getDefaultDeductions()
      }
    },
    employee: function () {
      this.getDefaultDeductions()
    }
  },
  created: function () {
    if (this.employee) this.getDefaultDeductions()
  },
  methods: {
    handleSelect(department) {
      this.$emit('onSelect', department)
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(data) {
      this.$loading = true
      EmployeeDefaultDeduction.delete({ id: data.id, employee_id: this.employee.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Default deduction has been deleted',
          });
          this.getDefaultDeductions()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleForm() {
      this.showForm = true
    },
    deleteItem() {

    },
    getDefaultDeductions() {
      this.handleClose()
      let params = { term: this.term, employee_id: this.employee.id }
      this.$loading = true
      EmployeeDefaultDeduction.get({ params: params })
        .then(result => {
          if (result.data.default_deductions)
            this.default_deductions = result.data.default_deductions
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
