var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The email field format is invalid' : null,"hasSuccess":passed,"name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|password_regex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The password should have a minimum length of 8 characters and must include both a letter and a number.' : null,"hasSuccess":passed,"name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1)])])]),_c('div',{staticClass:"card-footer text-right"},[_c('el-button',{attrs:{"type":"info","native-type":"submit","size":"small"}},[_vm._v("Save")]),_c('el-button',{staticClass:"ml-2",attrs:{"type":"button","size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }