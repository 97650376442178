<template>
  <div class="card card-user" v-if="employee">
    <div class="image">
      <img src="@/assets/images/profile_banner.jpg" alt="...">
    </div>
    <div class="card-body">
      <div class="author">
        <a>
          <img class="avatar border-gray" :src="employee.profile_picture" alt="...">
          <h5 class="title">{{ employee.full_name }}</h5>
        </a>
        <p class="description">
          {{ employee.email }}
        </p>
      </div>
      <p class="description text-center">
        {{ employee.formatted_ssn }} <br>
        {{ employee.position ? employee.position.name : ''}} <br>
        {{ employee.department ? employee.department.name : '' }}
      </p>
    </div>
    <div class="card-footer">
      <hr>
      <div class="button-container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-6 ml-auto">
            <h5>{{ employee.status == 1 ? 'ACTIVE':'INACTIVE' }}
              <br>
              <small>STATUS</small>
            </h5>
          </div>
          <div class="col-lg-4 col-md-6 col-6 ml-auto mr-auto">
            <h5> {{ employee.last_check_date || 'N/A' }}
              <br>
              <small>LATEST CHECKDATE</small>
            </h5>
          </div>
          <div class="col-lg-3 mr-auto">
            <h5>N/A
              <br>
              <small>PAYCHECKS</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>

    export default {
        name: 'employee-card',
        props: ['employee'],
    }
  
  </script>
  <style>
  
  </style>
  