import api from './api'

const API = '/api/employees'

export const Employee = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  getPaychecks: function(id,params) {
    return api.get(API + '/' + id + '/paychecks', {params: params})
  },
  create: function(params) {
    return api.create(API, params)
  },
  savePersonalInfo: function(id,form) {
    return api.create(API + '/' + id + '/personal-info', form)
  },
  saveTaxInfo: function(id,form) {
    return api.create(API + '/' + id + '/tax-info', form)
  },
  saveUser: function(id,form) {
    return api.create(API + '/' + id + '/user-account', form)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
