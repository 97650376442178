import api from './api'

const API = '/api/payrolls/reports'

export const PayrollReport = {
  reset: function(id, form) {
    return api.update(API+'/'+id+'/reset')
  },
  get: function(params) {
    return api.get(API, {params: params})
  },
  getPayrollsReadyToInvoice: function(params) {
    return api.get(`${API}/ready-to-invoice`, params)
  },
  generateInvoice: function(id, form) {
    return api.create(`${API}/${id}/generate-invoice`, form)
  },
  downloadPayrollSummary: function(params) {
    return api.create(`${API}/payroll-summary`, params)
  },
  downloadPaycheckDetail: function(params) {
    return api.create(`${API}/paycheck-detail`, params)
  },
  downloadPaystubs: function(params) {
    return api.create(`${API}/download-paystubs`, params)
  },
  generateReport: function(params) {
    return api.create(`${API}/generate-report`, params)
  },
  downloadTaxSummary: function(params) {
    return api.create(`${API}/download-tax-summary`, params)
  },
  getSummary: function(params) {
    return api.create(`${API}/get-summary`, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  deleteAllPaychecks: function(params) {
    return api.delete(API+'/'+params.id+'/delete-paychecks', params)
  },
  updateStatus: function (params) {
    return api.update(API+'/'+params.id+'/update-status', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
