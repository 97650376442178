<template>
<div class="row">
  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div style="padding: 20px;">
      <company-job-form v-if="showForm" :company_job="selected" @close="handleClose()"></company-job-form>
    </div>
  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-header">
      <div class="category">Manage Jobs</div>
    </div>
    <div class="card-body row">
      <div class="col-sm-6">
        <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
          <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-button type="primary" size="mini" class="ml-2" @click="handleForm">New +</el-button>
      </div>
      <div class="col-sm-6">
        <div class="pull-right">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-sm-12 mt-2">
        <el-table class="table-striped" header-row-class-name="text-primary" :data="company_jobs" style="width: 100%">

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Title">
            <template slot-scope="props">
              {{ props.row.title }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Description">
            <template slot-scope="props">
              {{ props.row.description }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Workers Comp">
            <template slot-scope="props">
              {{ props.row.wc_code }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
            <template slot-scope="props">

              <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
              <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  CompanyJob
} from '@/resources/company_job'
import CompanyJobForm from './CompanyJobForm.vue'
import ConfirmDelete from '../../../Common/ConfirmDelete.vue'

export default {
  components: {
    ConfirmDelete,
    CompanyJobForm,
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      company_jobs: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getJobs()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getJobs()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getJobs()
    }
  },
  created: function () {
    this.getJobs()
  },
  methods: {
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false

      this.getJobs()
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    handleDelete(item) {
      this.$loading = true
      this.$loadingText = "Removing"

      CompanyJob.delete({
          id: item.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Job has been removed',
          });
          this.getJobs()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getJobs() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      }
      this.$loading = true
      CompanyJob.get({
          params: params
        })
        .then(result => {
          if (result.data.company_jobs)
            this.company_jobs = result.data.company_jobs
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
