<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div v-if="paycheck" class="row" style="margin-right: 0;">

        <div class="col-md-12" style="text-align: center;">
        </div>

        <div class="col-md-9">
          <div class="row">
            <div class="col-md-12" v-if="paycheck.dependent_paychecks.length">
              <el-alert :closable="false" type="warning">
                <h4>Multiple Paychecks</h4>
                <div style="font-weight: bold;">The system has detected that this employee has multiple paychecks in the
                  current payroll.</div>
                <div>Note that all employees' paycheck taxes will be recalculated to ensure accurate tax updates.</div>
              </el-alert>
            </div>
            <div class="col-md-12" v-if="paycheck.work_schedule_employee">
              <el-alert :closable="false" type="success">
                <div>Work Order Detected</div>
                <div style="font-weight: bold;">{{ paycheck.work_schedule_employee.work_schedule.name }}</div>
                <div>Job : {{ paycheck.work_schedule_employee.work_schedule.company_job.title }} - {{
                  paycheck.work_schedule_employee.work_schedule.company_job.wc_code }}</div>
              </el-alert>
            </div>
            <div class="col-md-12">
              <div>
                <h5 style="font-size: 15px;font-weight: bold;margin-left: 10px;">Check Date: {{ paycheck.check_date }} |
                  {{ paycheck.schedule_type_label }}</h5>
              </div>
            </div>
            <div class="col-md-8">

              <earnings-list :paycheck="paycheck" @update="setEarnings($event)"></earnings-list>
              <hr>
              <deductions-list :paycheck="paycheck" @update="setDeductions($event)"></deductions-list>
            </div>
            <div class="col-md-4">
              <el-descriptions class="margin-top" title="Summary" :column="1" size="small" border>

                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-star-on"></i>
                    Taxable Earnings
                  </template>
                  {{ money(getTaxableEarnings()) }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-star-on"></i>
                    Non-Taxable Earnings
                  </template>
                  {{ money(getNonTaxableEarnings()) }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-star-on"></i>
                    Pre-tax Deductions
                  </template>
                  {{ money(getPreTaxDeductions()) }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-star-on"></i>
                    Other Deductions
                  </template>
                  {{ money(getOtherTaxDeductions(),2) }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-star-on"></i>
                    Employee Taxes
                  </template>
                  {{ money(getTaxes(), 2) }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">
                    <i class="el-icon-star-on"></i>
                    Employer Taxes
                  </template>
                  {{ money(getEmployerTaxes(), 2) }}
                </el-descriptions-item>
              </el-descriptions>
              <h5 style="text-align: right;padding-right: 10px;color: #6bd098;" class="pt-3">Check Amount: {{
                money(getCheckAmount(), 2) }}</h5>
              <hr>
              <taxes-list :paycheck="paycheck" @update="getPaycheck"></taxes-list>
              <hr>
              <taxes-list :paycheck="paycheck" :is_employee="false" @update="getPaycheck"></taxes-list>
            </div>

          </div>

        </div>
        <div class="col-md-3">
          <el-card>
            <div class="card-header">
              <div style="text-align: center;">
                <el-link :disabled="!paycheck.previous_paycheck_id"
                  :class="paycheck.previous_paycheck_id ? 'text-green':''"
                  @click="handleNextPaycheck(paycheck.previous_paycheck_id)"><i class="fa fa-arrow-left"></i>
                  Prev</el-link>
                <el-link :disabled="!paycheck.next_paycheck_id" :class="paycheck.next_paycheck_id ? 'text-green' : ''"
                  style="margin-left: 5px" @click="handleNextPaycheck(paycheck.next_paycheck_id)">Next <i
                    class="fa fa-arrow-right"></i></el-link>
                <hr>
              </div>
              <el-button type="primary" native-type="submit" size="small" v-if="paycheck.editable"
                class="btn-block">Save Paycheck</el-button>
            </div>
            <div class="card-body">
              <div class="author">
                <a>
                  <!-- <img class="avatar border-gray" src="@/assets/images/default-avatar.png" alt="..."> -->
                  <img class="avatar border-gray" :src="paycheck.employee.profile_picture" alt="...">

                  <p class="description text-center mt-2">
                    <span class="title text-primary" style="font-size: 20px;font-weight: bold;">{{
                      paycheck.employee.full_name }}</span> <br>
                    {{ paycheck.employee.masked_ssn }} <br>
                    {{ paycheck.employee.position ? paycheck.employee.position.name+'-'+
                    (paycheck.employee.position.wc_code || '') : '' }} <br>
                    {{ paycheck.employee.department ? paycheck.employee.department.name : '' }}
                  </p>
                </a>
              </div>
              <div class="text-center">
                <el-descriptions class="margin-top" title="Details" :column="1" size="small" border>
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      SSN
                    </template>
                    {{ paycheck.employee.formatted_ssn }}
                  </el-descriptions-item>

                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      Payment Method
                    </template>
                    {{ $store.state.payment_methods[paycheck.paycheck_tax_info.payment_method] }}
                  </el-descriptions-item>

                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      Tax Type
                    </template>
                    {{ $store.state.tax_types[paycheck.paycheck_tax_info.type] }} <span
                      v-if="paycheck.paycheck_tax_info.has_multiple_jobs">(Multiple Jobs)</span>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      Home State
                    </template>
                    {{ paycheck.paycheck_tax_info.home_state }} - {{ paycheck.paycheck_tax_info.home_zip }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      Work State
                    </template>
                    {{ paycheck.paycheck_tax_info.work_state }} - {{ paycheck.paycheck_tax_info.work_zip }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      Federal
                    </template>
                    {{ $store.state.federal_filing_statuses[paycheck.paycheck_tax_info.federal_filing_status] }} : {{
                    paycheck.paycheck_tax_info.total_dependents }} Allowances
                  </el-descriptions-item>
                  <el-descriptions-item v-if="paycheck.paycheck_tax_info.is_federal_exempted">
                    <template slot="label">
                    </template>
                    <el-tag>Exempted</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item v-if="paycheck.paycheck_tax_info.federal_additional_deductions">
                    <template slot="label">
                      Addtl Tax
                    </template>
                    <span>+ {{ money(paycheck.paycheck_tax_info.federal_additional_deductions) }}</span>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-star-on"></i>
                      State
                    </template>
                    {{ $store.state.federal_filing_statuses[paycheck.paycheck_tax_info.state_filing_status] }} : {{
                    paycheck.paycheck_tax_info.state_total_dependents }} Allowances
                  </el-descriptions-item>
                  <el-descriptions-item v-if="paycheck.paycheck_tax_info.is_state_exempted">
                    <template slot="label">
                    </template>
                    <el-tag>Exempted</el-tag>
                  </el-descriptions-item>
                  <el-descriptions-item v-if="paycheck.paycheck_tax_info.state_additional_deductions">
                    <template slot="label">
                      Addtl Tax
                    </template>
                    <span>+ {{ money(paycheck.paycheck_tax_info.state_additional_deductions) }}</span>
                  </el-descriptions-item>
                </el-descriptions>
                <hr>
                <el-button size="mini" @click="handleEditTaxInfo()">Edit Tax Details</el-button>

              </div>

            </div>
          </el-card>

        </div>
      </div>
    </form>

    <el-drawer :append-to-body="true" title="" :visible.sync="showTaxInfoForm" direction="btt" size="90%"
      :destroy-on-close="true">
      <paycheck-tax-info-form :taxInfo="paycheck.paycheck_tax_info" v-if="paycheck"
        @save="submit()"></paycheck-tax-info-form>
    </el-drawer>

  </ValidationObserver>
</template>

<script>
import {
  Paycheck
} from '@/resources/paycheck'
import EarningsList from './Earnings/EarningsList.vue';
import DeductionsList from './Deductions/DeductionsList.vue';
import TaxesList from './Taxes/TaxesList.vue';
import {
  Message
} from 'element-ui';
import PaycheckTaxInfoForm from './PaycheckTaxInfoForm.vue';

export default {
  props: ['paycheck_id'],
  components: {
    EarningsList,
    DeductionsList,
    TaxesList,
    PaycheckTaxInfoForm
  },
  data() {
    return {
      paycheck: null,
      showTaxInfoForm: false
    };
  },
  watch: {
    paycheck_id: function () {
      if (this.paycheck_id) this.getPaycheck()
    }
  },
  created: function () {
    if (this.paycheck_id) this.getPaycheck()
  },
  beforeDestroy() {
    this.paycheck = null
  },
  methods: {
    handleNextPaycheck(id) {
      this.$router.replace({
        query: {
          ...this.$route.query,    // Keep the existing query parameters
          paycheck_id: id         // Update the `paycheck_id` to 111
        }
      });

      this.getPaycheck(id)
    },
    handleEditTaxInfo() {
      this.showTaxInfoForm = true
    },
    getTaxableEarnings() {
      let total = 0
      this.paycheck.earnings.forEach(function (earning) {
        if (!earning.delete_ && earning.type == 1) total += parseFloat(earning.amount)
      })
      return total;
    },
    getNonTaxableEarnings() {
      let total = 0
      this.paycheck.earnings.forEach(function (earning) {
        if (!earning.delete_ && earning.type == 0) total += parseFloat(earning.amount)
      })
      return total;
    },
    getPreTaxDeductions() {
      let total = 0
      this.paycheck.deductions.forEach(function (deduction) {
        if (!deduction.delete_ && deduction.type >= 1) total += deduction.amount
      })
      return total;
    },
    getOtherTaxDeductions() {
      let total = 0
      this.paycheck.deductions.forEach(function (deduction) {
        if (!deduction.delete_ && deduction.type == 0) total += deduction.amount
      })
      return total;
    },
    getTaxes() {
      let total = 0
      this.paycheck.paycheck_taxes.forEach(function (tax) {
        if (!tax.delete_ && tax.is_employee) total += tax.amount
      })
      return total;
    },
    getEmployerTaxes() {
      let total = 0
      this.paycheck.paycheck_taxes.forEach(function (tax) {
        if (!tax.delete_ && !tax.is_employee) total += tax.amount
      })
      return total;
    },
    getCheckAmount() {
      return this.getTaxableEarnings() + this.getNonTaxableEarnings() - this.getPreTaxDeductions() - this.getOtherTaxDeductions() - this.getTaxes()
    },
    close() {
      this.$emit('close', null)
    },
    setDeductions(deductions) {
      this.paycheck.deductions = deductions
    },
    setEarnings(earnings) {
      this.paycheck.earnings = earnings
    },
    submit() {
      this.showTaxInfoForm = false
      this.$loading = true
      this.$loadingText = "Saving Paycheck ...."
      let form = this.getForm(this.paycheck)
      Paycheck.update(form)
        .then(result => {
          Message({
            message: result.data.message,
            type: 'success'
          });
          this.paycheck = null
          this.paycheck = result.data.paycheck
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getForm(paycheck) {
      let form = Object.assign({}, paycheck)
      form.earnings = []
      form.deductions = []

      paycheck.earnings.forEach(earning => {
        if (!earning.delete_) form.earnings.push(earning)
      });

      paycheck.deductions.forEach(deduction => {
        if (!deduction.delete_) form.deductions.push(deduction)
      });

      return form
    },
    getPaycheck(id) {
      this.$loading = true
      this.showTaxInfoForm = false
      Paycheck.show(id || this.paycheck_id)
        .then(result => {
          this.paycheck = result.data.paycheck
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
