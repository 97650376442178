<template>
  <el-card class="mt-2">
    <el-form>
      <label>In what manner would you prefer to arrange the employees across all reports?</label>
      <el-form-item label="">

        <el-select v-model="form.order_employees_by" size="small" @change="submit">
          <el-option label="FIRST NAME" value="first_name"></el-option>
          <el-option label="LAST NAME" value="last_name"></el-option>
          <el-option label="SSN" value="ssn"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

  </el-card>
</template>
<script>

import { CompanySetting } from '@/resources/company/company_setting'
import {
  mapState
} from 'vuex';

export default {
  data() {
    return {
      form: {
        order_employees_by: null
      }
    };
  },
  watch: {
    'form.order_employees_by': function () {
      // this.submit()
    },
  },
  created: function () {
    if (this.user.company.company_setting) {
      this.form = Object.assign({}, this.user.company.company_setting)
    }
  },
  methods: {
    submit() {
      this.$loading = true

      CompanySetting.setEmployeeOrder(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee order has been set',
          });
          this.user.company.company_setting.order_employees_by = this.form.order_employees_by
          this.$store.commit('saveUser', this.user);
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  },
  computed: {
    ...mapState(['user'])
  },
}
</script>
<style></style>
