<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <h4>{{ form.id ? "Update ":'New ' }} Invoice </h4>
  <form :model="form" @submit.prevent="handleSubmit(submit)">
    <div class="row">
      <div class="col-md-12">
        Company: {{ form.company ? form.company.name: 'N/A' }}
        <hr>
      </div>
      <div class="col-md-5">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Invoice Number</label>
              <el-input readonly v-model="form.code" disabled></el-input>
            </div>
            <div class="form-group">
                <label>Status</label>
                <ValidationProvider name="status" rules="required" v-slot="{ passed, failed }">
              <el-select :default-first-option="true" v-model="form.status" placeholder="Status" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]" >
                <el-option v-for="item,i in $store.state.invoice_status" :key="i" :label="item" :value="i">
                </el-option>
              </el-select>

              <span class="error-text" v-if="failed">Name is required</span>
            </ValidationProvider>

              </div>
            <div class="form-group">
              <label>Invoice Date</label>
              <ValidationProvider name="invoice_date" rules="required" v-slot="{ passed, failed }">
                <el-date-picker v-model="form.invoice_date" class="form-group" :class="[
                      { 'has-danger': failed },
                      { 'has-success': passed }]" type="date" placeholder="" size="mini" format="yyyy-MM-dd"></el-date-picker>

                <span class="error-text" v-if="failed">Invoice date is required</span>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label>Due Date</label>

              <ValidationProvider name="due_date" rules="required" v-slot="{ passed, failed }">
                <el-date-picker v-model="form.due_date" class="form-group" :class="[
                      { 'has-danger': failed },
                      { 'has-success': passed }]" type="date" placeholder="" format="yyyy-MM-dd"></el-date-picker>

                <span class="error-text" v-if="failed">Due date is required</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-6">
            <div class="">
              <div class="form-group">
                <label>Recipient Name</label>
                <ValidationProvider name="recipient_name" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Recipient name is required' : null" :hasSuccess="passed" name="recipient_name" v-model="form.recipient_name">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Recipient Address</label>
                <ValidationProvider name="recipient_address" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Recipient address format is invalid' : null" :hasSuccess="passed" name="recipient_address" v-model="form.recipient_address">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="">
              <div class="form-group">
                <label>Sender Name</label>
                <ValidationProvider name="from_name" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Sender name is required' : null" :hasSuccess="passed" name="from_name" v-model="form.from_name">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Sender Address</label>
                <ValidationProvider name="from_address" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Sender address is required' : null" :hasSuccess="passed" name="from_address" v-model="form.from_address">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <h5>Items</h5>
        <table class="table table-border" style="width: 100%">
          <thead>
            <tr>
              <th style="width: 250px;">Name</th>
              <th>Description</th>
              <th style="width: 80px;">Qty</th>
              <th style="width: 100px;">Rate</th>
              <th style="width: 200px;">Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item, i in form.invoice_items" :key="i">
              <td style="vertical-align: baseline;">
                <div class="form-group">
                  <ValidationProvider :name="'code_' + i" rules="required" v-slot="{ passed, failed }">
                    <fg-input type="text" :error="failed ? 'Item name is required' : null" :hasSuccess="passed" v-model="item.code">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </td>
              <td style="vertical-align: baseline;">
                <div class="form-group">
                  <ValidationProvider :name="'description_' + i" rules="required" v-slot="{ passed, failed }">
                    <fg-input type="text" :error="failed ? 'Item description is required' : null" :hasSuccess="passed" v-model="item.description">
                    </fg-input>
                  </ValidationProvider>
                </div>
              </td>
              <td style="vertical-align: baseline;">
                <el-input v-model="item.quantity" @input="updateTotal(item)"></el-input>
              </td>
              <td style="vertical-align: baseline;">
                <el-input v-model="item.rate" @input="updateTotal(item)"></el-input>
              </td>
              <td style="vertical-align: baseline;">
                <el-input readonly v-model="item.amount"></el-input>
              </td>
              <td style="vertical-align: baseline;">
                <el-button type="danger" size="mini" @click="remove(i)"><i class="fa fa-trash"></i></el-button>
              </td>
            </tr>
            <tr>
              <td style="vertical-align: baseline;text-align: center;" colspan="6">
                <el-button type="default" size="mini" @click="add()"><i class="fa fa-plus"></i> ADD</el-button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

    </div>
    <hr>
    <div>

      <div class=" text-right">
        <div style="font-weight: bold;font-size: 20px;color: peru;">
          Total Amount: {{ money(form.total) }}
        </div>
        <el-button type="info" size="small" native-type="submit">Save</el-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  Invoice
} from '@/resources/invoice'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

extend("required", required);

export default {
  props: ['invoice'],
  data() {
    return {
      form: null
    }
  },
  created: function () {
    if (this.invoice) this.form = Object.assign({}, this.invoice)
  },
  watch: {
    'form.invoice_items': {
      handler: function (newVal) {
        this.computeTotal()
      },
      deep: true
    },
  },
  methods: {
    add() {
      this.form.invoice_items.push({
        code: null,
        description: null,
        quantity: 0,
        rate: 0,
        amount: 0
      })
    },
    getActiveItems() {
      let list = JSON.parse(JSON.stringify(this.form.invoice_items));
      this.form.invoice_items = []
      let self = this
      list.forEach(function (item) {
        if (!item.to_remove) self.form.invoice_items.push(item)
      })
    },
    updateTotal(item) {
      item.amount = (item.rate) * item.quantity
    },
    remove(i) {
      this.form.invoice_items[i].to_remove = true
      this.getActiveItems()
    },
    computeTotal() {
      let self = this
      this.form.total = 0
      this.form.invoice_items.forEach(item => {
        if (!item.to_remove) self.form.total += item.amount
      });
    },
    submit() {
      this.$loading = true
      this.$loadingText = 'Saving Invoice. Please wait...'

      let res = this.form.id ? Invoice.update(this.form) : Invoice.create(this.form)
      res.then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Invoice has been saved',
          });

          this.$emit('close', true)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style lang="scss"></style>
