<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Group' : 'Create New Payroll Group' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'Name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { PayrollGroup } from '@/resources/payroll_group'

extend("required", required);

export default {
  props: ['payroll_group'],
  data() {
    return {
      form: {
        name: null
      }
    };
  },
  watch: {
    payroll_group: function () {
      if (this.payroll_group) this.form = Object.assign({}, this.payroll_group)
    }
  },
  created: function () {
    if (this.payroll_group) this.form = Object.assign({}, this.payroll_group)
  },
  beforeDestroy() {
    this.form = {
      name: null
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? PayrollGroup.update(this.form) : PayrollGroup.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payroll group has been saved',
          });
          this.$emit('close', result.data.department)
        })
        .catch(error => {
          
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
