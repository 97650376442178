<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Employee' : 'Create New Employee' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>First Name</label>
            <ValidationProvider name="first_name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'First name field is required' : null" :hasSuccess="passed" name="first_name"
                v-model="form.first_name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Middle Name</label>
            <ValidationProvider name="middle_name"  v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'Middle name field is required' : null" :hasSuccess="passed" name="middle_name"
                v-model="form.middle_name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Last Name</label>
            <ValidationProvider name="last_name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'Last name field is required' : null" :hasSuccess="passed" name="last_name"
                v-model="form.last_name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>SSN</label>
            <ValidationProvider name="ssn" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'SSN field is required' : null" :hasSuccess="passed" name="ssn"
                v-model="form.ssn">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Department <el-link type="primary" @click="showDepartment = true">(Create New Department +)</el-link></label>
            <div class="row col-md-4"  v-if="showDepartment">
              <department-form @close="checkDepartment($event)"></department-form>
            </div>
            <ValidationProvider name="department_id" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The recipient field is required' : null" :hasSuccess="passed"
                name="department_id" v-model="form.department_id" :readonly="true"  style="display: none;">
              </fg-input>

            <search-department @update="checkDepartment($event)" :passed="passed" :error="failed?'Department is required':null" :department="form.department" v-if="!showDepartment"></search-department>

            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Position <el-link type="primary" @click="showPosition = true">(Create New Position +)</el-link></label>
              <div class="row col-md-4"  v-if="showPosition">
                <position-form @close="checkPosition($event)"></position-form>
              </div>
              <ValidationProvider name="position_id" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The recipient field is required' : null" :hasSuccess="passed"
                  name="position_id" v-model="form.position_id" :readonly="true"  style="display: none;">
                </fg-input>

              <search-position @update="checkPosition($event)" :passed="passed" :error="failed ? 'Position is required' : null" :position="form.position" v-if="!showPosition"></search-position>

              </ValidationProvider>
            </div>
          
        </div>
        <div class="card-footer text-right">
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()" class="ml-2">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Employee } from '@/resources/employee'
import SearchDepartment from '../../../Common/SearchDepartment.vue';
import DepartmentForm from '../Departments/DepartmentForm.vue';
import SearchPosition from '../../../Common/SearchPosition.vue';
import PositionForm from '../Positions/PositionForm.vue';


extend("required", required);

export default {
  props: ['employee'],
  components: { SearchDepartment, DepartmentForm, SearchPosition, PositionForm },
  data() {
    return {
      showDepartment: false,
      showPosition: false,
      form: {
        first_name: null,
        middle_name: null,
        last_name: null,
        ssn: null,
        department_id: null,
        position_id: null
      }
    };
  },
  watch: {
    user: function () {
      if (this.employee) {
        this.form = Object.assign({}, this.employee)
        if (this.form.department) this.form.department_id = this.form.department.id
        if (this.form.position) this.form.position_id = this.form.position.id
      }
    }
  },
  created: function () {
    if (this.employee) this.form = Object.assign({}, this.employee)
    if (this.form.department) this.form.department_id = this.form.department.id
    if (this.form.position) this.form.position_id = this.form.position.id
  },
  beforeDestroy() {
    this.form = {
      first_name: null,
      middle_name: null,
      last_name: null,
      ssn: null,
      department_id: null,
      position_id: null
    }
  },
  methods: {
    close() {
      this.$emit('save', false)
    },
    checkDepartment(department) {
      this.showDepartment = false
      if (department) {
        this.form.department = department
        this.form.department_id = department.id
      }
    },
    checkPosition(position) {
      this.showPosition = false
      if (position) {
        this.form.position = position
        this.form.position_id = position.id
      }
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? Employee.update(this.form) : Employee.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee has been saved',
          });
          this.$emit('save', result.data.employee)
        })
        .catch(error => {
          
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
