import api from './api'

const API = '/api/payrolls/list'

export const Payroll = {
  get: function(params) {
    return api.get(API, params)
  },
  getAll: function(params) {
    return api.get(`${API}/all`, params)
  },
  getPayrollsReadyToInvoice: function(params) {
    return api.get(`${API}/ready-to-invoice`, params)
  },
  generateInvoice: function(id, form) {
    return api.create(`${API}/${id}/generate-invoice`, form)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  emailPaystubs: function(id) {
    return api.create(`${API}/${id}/email-paystubs`)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  deleteAllPaychecks: function(params) {
    return api.delete(API+'/'+params.id+'/delete-paychecks', params)
  },
  updateStatus: function (params) {
    return api.update(API+'/'+params.id+'/update-status', params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
