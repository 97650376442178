import api from './api'

const API = '/api/payrolls/paychecks'

export const Paycheck = {
  get: function(params) {
    return api.get(API, params)
  },
  checkEmployeePayroll: function(params) {
    return api.get(API+'/check-employee-payroll', params)
  },
  createPaychecksFromDepartment: function(params) {
    return api.create(API+'/create-paychecks-from-department', params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  downloadPaystub: function(id, params) {
    return api.get( `${API}/${id}/download-paystub`, params)
  }, 
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  saveComputeFlag: function(id, form) {
    return api.update(API+'/'+id+'/save-compute-flag', form)
  },
  saveTaxes: function(paycheck_id, form) {
    return api.update(API+'/'+paycheck_id+'/save-taxes', form)
  },
  addTax: function(paycheck_id, form) {
    return api.update(API+'/'+paycheck_id+'/add-tax', form)
  },
  saveTaxInfo: function(paycheck_id, form) {
    return api.update(API+'/'+paycheck_id+'/save-tax-info', form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
