<template>
  <el-card v-if="employee">
    <div class="row">
      <div class="col-md-12">
        <h3>{{ employee.full_name }}</h3>
        <hr>
      </div>
      <div class="col-md-12">
        <el-table class="table-striped" header-row-class-name="text-primary" :data="paychecks" style="width: 100%">
          <el-table-column class-name="td-actions" label="Check Date">
            <template slot-scope="props">
              {{ props.row.check_date }}
            </template>
          </el-table-column>
          <el-table-column class-name="td-actions" label="Earnings">
            <template slot-scope="props">
              <div>Taxable {{ money(props.row.total_taxable_earnings, 2) }}</div>
              <div>NonTax {{ money(props.row.total_non_taxable_earnings, 2) }}</div>
            </template>
          </el-table-column>

          <el-table-column class-name="td-actions" label="YTD">
            <template slot-scope="props">
              <div> {{ money(props.row.ytd.total_taxable_earnings, 2) }}</div>
              <div> {{ money(props.row.ytd.total_non_taxable_earnings, 2) }}</div>
            </template>
          </el-table-column>

          <el-table-column class-name="td-actions" label="Deductions">
            <template slot-scope="props">
              <div>Pre-Tax {{ money(props.row.total_pre_tax_deductions, 2) }}</div>
              <div>After {{ money(props.row.total_other_tax_deductions, 2) }}</div>
            </template>
          </el-table-column>
          <el-table-column class-name="td-actions" label="YTD">
            <template slot-scope="props">
              <div> {{ money(props.row.ytd.total_pre_tax_deductions, 2) }}</div>
              <div> {{ money(props.row.ytd.total_other_tax_deductions, 2) }}</div>
            </template>
          </el-table-column>
          <el-table-column class-name="td-actions" label="Taxes">
            <template slot-scope="props">
              <div>Employee {{ money(props.row.total_employee_taxes,2) }}</div>
              <div>Employer {{ money(props.row.total_employer_taxes, 2) }}</div>
            </template>
          </el-table-column>
          <el-table-column class-name="td-actions" label="YTD">
            <template slot-scope="props">
              <div> {{ money(props.row.ytd.total_employee_taxes, 2) }}</div>
              <div> {{ money(props.row.ytd.total_employer_taxes, 2) }}</div>
            </template>
          </el-table-column>

          <el-table-column class-name="td-actions" label="Net">
            <template slot-scope="props">
              <div>{{ money(props.row.check_amount, 2) }}</div>
            </template>
          </el-table-column>
          <el-table-column class-name="td-actions" label="YTD">
            <template slot-scope="props">
              <div>{{ money(props.row.ytd.total_check_amount, 2) }}</div>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} paychecks</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
          :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </el-card>
</template>

<script>

import {
  Employee
} from '@/resources/employee'

import PPagination from 'src/components/UIComponents/Pagination.vue'


export default {
  props: ['employee'],
  components: { PPagination },
  data() {
    return {
      paychecks: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      selected: null,
      term: null
    }
  },
  created: function () {
    this.paychecks = []
    if (this.employee) this.getPaychecks()
  },
  watch: {
    employee: function () {
      if (this.employee) this.getPaychecks()
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPaychecks()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPaychecks()
    }
  },
  methods: {
    getPaychecks() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        employee_id: this.employee.id
      }
      this.$loading = true
      this.paychecks = []
      Employee.getPaychecks(this.employee.id, params)
        .then(result => {
          if (result.data.paychecks)
            this.paychecks = result.data.paychecks
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch paychecks',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
