<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ form.id ? 'Update Tax' : 'Create Tax Rate' }}
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Name</label>
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
            <el-input v-model="form.name" placeholder="Name" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
            </el-input>
            <span class="error-text" v-if="failed">Name is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Year</label>
          <ValidationProvider name="year" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.year" placeholder="Select year" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              <el-option :value="y" :label="y" v-for="y, i in $store.state.years" :key="i">{{ y }}</el-option>
            </el-select>
            <span class="error-text" v-if="failed">Year is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Rate (%)</label><br>
          <ValidationProvider name="rate" v-slot="{ failed }">
            <el-input-number v-model="form.rate" style="width: 300px;"></el-input-number>
            <span class="error-text" v-if="failed">Rate is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Wage base Limit</label><br>
          <ValidationProvider name="limit" v-slot="{ failed }">
            <el-input-number v-model="form.limit" style="width: 300px;"></el-input-number>
            <span class="error-text" v-if="failed">Limit is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <el-switch style="display: block" v-model="form.type" active-color="#13ce66" inactive-color="#ff4949" active-text="Federal" :active-value="0" :inactive-value="1" inactive-text="State">
          </el-switch>
        </div>

        <div class="form-group">
          <el-switch style="display: block" v-model="form.is_employee" active-color="#13ce66" inactive-color="#ff4949" active-text="Employee" :active-value="1" :inactive-value="0" inactive-text="Employer">
          </el-switch>
        </div>

        <el-button type="info" native-type="submit" size="small">Save</el-button>
        <el-button type="button" size="small" @click="close()">Close</el-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  TaxRate
} from '@/resources/tax_rate'

extend("required", required);

export default {
  props: ['company_state'],
  data() {
    return {
      form: {
        state: null,
        year: null,
        account_number: null,
        unemployment_rate: 0
      }
    };
  },
  watch: {
    company_state: function () {
      if (this.company_state) this.form = Object.assign({}, this.company_state)
    }
  },
  created: function () {
    if (this.company_state) this.form = Object.assign({}, this.company_state)
  },
  beforeDestroy() {
    this.form = {
      state: null,
      year: null,
      account_number: null,
      unemployment_rate: 0
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? TaxRate.update(this.form) : TaxRate.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Tax has been saved',
          });
          this.$emit('close', result.data.tax_rate)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
