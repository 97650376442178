<template>
  <div v-if="paycheck">
    <el-drawer title="" :visible.sync="showAddTax" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true" :append-to-body="true">
      <div class="container">
        <tax-form :paycheck="paycheck" :is_employee="is_employee" @close="updatePaycheck"></tax-form>
      </div>
    </el-drawer>

    <h5 class="bold">{{ is_employee ? 'Employee Taxes' : 'Employer Taxes' }}</h5>
    <el-descriptions class="margin-top" :column="1" v-if="list.length" size="small" border>

      <el-descriptions-item v-for="(tax,i) in list" :key="tax.name"
        :contentClassName="tax.tax_rate && tax.tax_rate.capped ? 'capped font-15' :' font-15'">
        <template slot="label">
          <div>
            <i class="el-icon-star-on"></i>
            {{ tax.name }} <span v-if="tax.is_modified">(MODIFIED)</span>
          </div>
        </template>
        <div v-if="!enable_modify">
          {{ money(tax.amount) }} <span class="ytd">(YTD:{{ money(tax.ytd_amount) }} <span style="    color: #ef8157;"
              v-if="tax.tax_rate && tax.tax_rate.max_tax">/
              {{
              money(tax.tax_rate.max_tax) }} LIMIT</span> )</span>
          <div v-if="tax.tax_rate" class="ytd">
            <span>{{ tax.tax_rate.rate }}% |</span> <span>WB:{{ money(tax.tax_rate.limit) }}</span>
          </div>
        </div>
        <div v-if="enable_modify">
          <el-input type="number" placeholder="0.00" v-model="tax.amount">
            <el-button slot="append" icon="el-icon-close" @click="handleRemove(i, tax)"
              v-if="!required_taxes.includes(tax.name)"></el-button>
          </el-input>
        </div>
      </el-descriptions-item>
      <el-descriptions-item contentClassName="bg-light-yellow font-15">
        <template slot="label">
          <div>
            <i class="el-icon-star-on"></i>
            TOTAL TAXES
          </div>
        </template>
        <span>{{ money(totalTaxes) }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-alert :closable="false">Disable the Tax Calculation if you want to modify the taxes</el-alert>

    <br>
    <div v-if="paycheck && paycheck.id">
      <div style="text-align: center;">
        <el-switch v-model="compute_tax" active-text="Enable Tax Calculation" inactive-text="Disabled">
        </el-switch>
        <br>
      </div>
      <el-button style="float: right;" v-if="allowModify && !enable_modify" type="primary" @click="handleShowModify()"
        size="mini">MODIFY</el-button>

      <el-button style="float: right;" v-if="allowModify && enable_modify" size="mini" type="primary"
        @click="handleSaveTaxes()"><i class="fa fa-save"></i> {{ saving ? 'SAVING' : 'SAVE' }}</el-button>
      <el-button style="float: right;" v-if="allowModify && enable_modify" size="mini" type="warning" plain
        @click="handleShowAddTax()"><i class="fa fa-plus"></i> ADD TAX</el-button>
      <br>
    </div>
    <el-alert v-for="cap,i in capped_taxes" :key="i" :closable="false" type="error">
      {{ cap }}
    </el-alert>
    <hr>
  </div>
</template>
<script>

import {
  Paycheck
} from '@/resources/paycheck'
import TaxForm from './TaxForm.vue'

export default {
  components: { TaxForm },
  props: {
    paycheck: {
      type: Object,
      description: 'Heading Title',
      default: null
    },
    is_employee: {
      type: Boolean,
      description: 'class name',
      default: true
    },
  },
  data() {
    return {
      showAddTax: false,
      saving: false,
      taxes: [],
      selected: null,
      total_non_taxable: 0,
      total_taxable: 0,
      total: 0,
      capped_taxes: [],
      list: [],
      enable_modify:false,
      required_taxes: ['FIT', 'FICA SS', 'FICA MEDICARE'],
      compute_tax: true
    }
  },
  watch: {
    compute_tax: function () {
      this.saveComputeFlag()
    },
    paycheck: {
      handler: function (newVal) {
        if (this.paycheck) {
          this.taxes = this.paycheck.paycheck_taxes
          this.getTaxes()
        }
      },
      deep: true
    },
    taxes: {
      handler: function (newVal) {
        // this.$emit('update', newVal)
      },
      deep: true
    },
  },
  created: function () {
    this.taxes = []
    if (this.paycheck) {
      this.taxes = this.paycheck.paycheck_taxes
      this.getTaxes()
    }
  },
  computed: {
    totalTaxes: function () {
      return this.list.reduce((sum, item) => sum + parseFloat(item.amount), 0);
    },
    allowModify: function () {
      return this.is_employee ? this.paycheck && !this.compute_tax : this.paycheck && !this.compute_tax
    }
  },
  methods: {
    updatePaycheck(paycheck) {
      this.handleClose()
      if (paycheck) this.$emit('update', paycheck)
    },
    handleClose() {
      this.showAddTax = false
    },
    saveComputeFlag(){
      if (this.is_employee && this.paycheck.compute_employee_taxes == this.compute_tax) return false
      if (!this.is_employee && this.paycheck.compute_employer_taxes == this.compute_tax) return false

      this.$loading = true
      this.saving = true

      Paycheck.saveComputeFlag(this.paycheck.id, { compute_tax: this.compute_tax, is_employee: this.is_employee})
        .then(result => {
         this.$emit('update', result.data.paycheck)
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: "Error",
            text: 'Unable to save this time',
          });
        }).finally(() => {
          this.$loading = false
          this.saving = false
        })
    },
    handleShowAddTax() {
      this.showAddTax = true
    },
    handleSaveTaxes() {
      this.$loading = true
      this.saving = true

      Paycheck.saveTaxes(this.paycheck.id, {taxes: this.list, is_employee: this.is_employee})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Taxes saved.',
          });
          this.enable_modify = false
          this.taxes = result.data.paycheck.paycheck_taxes
          this.$emit('update', result.data.paycheck)
          this.getTaxes()
        })
        .catch(error => {
          console.log(error)
          this.$notify({
            type: 'error',
            title: "Error",
            text: 'Unable to save this time',
          });
        }).finally(() => {
          this.$loading = false
          this.saving = false
        })
    },
    handleRemove(i, tax) {
      this.taxes.splice(i, 1)
      this.taxes.forEach(function (t) {
        
      })
    },
    handleShowModify() {
      this.enable_modify = true
    },
    getTaxes() {
      let list = []
      let isEmployee = this.is_employee
      let cappedTaxes = []

      this.compute_tax = this.is_employee ? this.paycheck.compute_employee_taxes : this.paycheck.compute_employer_taxes
      this.taxes.forEach(tax => {
        if (tax.is_employee == isEmployee) {
          list.push(tax)
          if (tax.tax_rate && tax.tax_rate.capped) {
            let message = "The " + tax.name + " has already reached its "+this.money(tax.tax_rate.max_tax) +" cap."
            cappedTaxes.push(message)
          }
        }
      });

      this.capped_taxes = cappedTaxes
      this.list = list
    }
  }
}
</script>
<style lang="scss">
.capped {
  background: #ffe6e6;
}
</style>
