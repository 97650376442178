<template>
<div class="row">
  <el-drawer title="" :visible.sync="showCompanyDeductionForm" direction="btt" :before-close="handleClose" size="90%" :append-to-body="true" :destroy-on-close="true">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <company-deduction-form @close="getCompanyDeductions()" v-if="showCompanyDeductionForm"></company-deduction-form>
      </div>
    </div>
  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-body row">
      <div class="col-sm-12 mt-2" style="overflow: overlay;">
        <div>DEDUCTIONS</div>
        <div><el-link type="primary" @click="addDeductionItem()">Add deduction item</el-link></div>
        <table style="width: 400px">
          <tr>
            <td v-for="deduction, i in deductions" :key="i">
              <transition name="el-zoom-in-center">
                <deduction-form :deduction="deduction" v-if="!deduction.delete_" @update="setDeduction($event, i)" :editable="paycheck.editable"></deduction-form>
              </transition>

            </td>
            <td>
              <el-button size="mini" class="ml-2" @click="handleAddDeduction()" v-if="paycheck.editable">ADD +</el-button>
            </td>
          </tr>
        </table>

      </div>
    </div>
  </div>

</div>
</template>

<script>
import CompanyDeductionForm from '../../../Deductions/CompanyDeductionForm.vue'
import {
  CompanyDeduction
} from '@/resources/company_deduction'
import DeductionForm from './DeductionForm.vue'

export default {
  components: {
    DeductionForm, CompanyDeductionForm
  },
  props: ['paycheck'],
  data() {
    return {
      deductions: [],
      selected: null,
      company_earnings: [],
      showCompanyDeductionForm: false
    }
  },
  watch: {
    paycheck: {
      handler: function (newVal) {
        if (this.paycheck) this.deductions = this.paycheck.deductions
      },
      deep: true
    },
    deductions: {
      handler: function (newVal) {
        this.$emit('update', newVal)
      },
      deep: true
    },
  },
  created: function () {
    if (this.paycheck) this.deductions = this.paycheck.deductions
  },
  computed: {

  },
  methods: {
    handleClose() {
      this.showCompanyDeductionForm = false
    },
    addDeductionItem() {
      this.showCompanyDeductionForm = true
    },
    getCompanyDeductions() {
      let params = {
        page: 1,
        per_page: 20
      }
      this.loading = true
      this.showCompanyDeductionForm = false
      CompanyDeduction.get({
        params: params
      })
        .then(result => {
          if (result.data.company_deductions)
            this.company_deductions = result.data.company_deductions
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleAddEarning() {
      this.earnings.push({})
    },
    handleAddDeduction() {
      this.deductions.push({})
    },
    setDeduction(newItem, index) {
      let list = Object.assign([], this.deductions);
      list[index] = Object.assign({}, newItem)
      this.deductions = Object.assign([], list);
    },
  }
}
</script>

<style lang="scss"></style>
