<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this report? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Reports</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="">
            <el-input prefix-icon="nc-icon nc-zoom-split" size="small" class="input-sm" placeholder="Search"
              v-model="term">
              <el-button @click="getReports" slot="append" type="primary" size="small">Refresh</el-button>
            </el-input>

          </div>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

        </div>

        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="payroll_reports"
            style="width: 100%">

            <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Report">
              <template slot-scope="props">
                {{ props.row.report_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Data">
              <template slot-scope="props">
                {{ props.row.params_data }}
              </template>
            </el-table-column>

            <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="File">
              <template slot-scope="props">
                <el-link v-if="props.row.file" :href="props.row.file.url" target="_blank"
                  :download="props.row.file.filename"> DOWNLOAD</el-link>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Remarks">
              <template slot-scope="props">
                {{ props.row.remarks }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <span class="text-info" v-if="props.row.status == 0">NEW</span>
                <span class="text-warning" v-if="props.row.status == 1">GENERATING</span>
                <span class="text-success" v-if="props.row.status == 2">SUCCESS</span>
                <span class="text-danger" v-if="props.row.status == 3">ERROR</span>

              </template>
            </el-table-column>

            <el-table-column :min-width="90" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">


                <el-button type="default" link @click="handleReset(props.row)" size="mini" v-if="props.row.status > 0">
                  <i class="fa fa-refresh"></i>
                </el-button>
                <confirm-delete class="ml-2" @onClick="handleDelete(props.row)" :label="''"
                  v-if="props.row.status != 1"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { PayrollReport } from '@/resources/payroll_report'
import ConfirmDelete from '../../../../Common/ConfirmDelete.vue'

export default {
  components: {ConfirmDelete  },
  props: ['trigger'],
  data() {
    return {
      showDelete: false,
      showForm: false,
      payroll_reports: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    trigger: function () {
      this.getReports()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getReports()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getReports()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getReports()
    }
  },
  created: function () {
    this.getReports()
  },
  methods: {
    handleReset(model) {
      this.$loading = true
      PayrollReport.reset(model.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Report has been reset',
          });
          this.getReports()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(item) {
      this.$loading = true
      this.$loadingText = "Removing"

      PayrollReport.delete({
        id: item.id
      })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Report has been removed',
          });
          this.getReports()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleForm() {
      this.showForm = true
    },
    getReports() {
      this.handleClose()
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      PayrollReport.get(params)
        .then(result => {
          if (result.data.payroll_reports)
            this.payroll_reports = result.data.payroll_reports
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch reports',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
