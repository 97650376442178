<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group">
              <label>Name</label>
              <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                  v-model="form.name">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="form-group">
              <label>Email</label>
              <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The email field format is invalid' : null" :hasSuccess="passed"
                  name="email" v-model="form.email">
                </fg-input>
              </ValidationProvider>
            </div>
          
            <div class="form-group">
                <label>Password</label>
                <ValidationProvider vid="confirmation"
                  name="password"
                    :rules="{
                      required: true,
                      regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                    }"
                v-slot="{ passed, failed }">
                  <fg-input type="password" :error="failed ? 'The Password must be at least 8 characters and has a number' : null" :hasSuccess="passed"
                    name="password" v-model="form.password">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label>Confirm Password</label>
                <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ passed, failed }">
                  <fg-input type="password" :error="failed ? 'The Confirm field is required' : null" :hasSuccess="passed"
                    name="confirm" v-model="form.confirmPassword">
                  </fg-input>
                </ValidationProvider>
              </div>
          <div class="text-right"><p-button type="success" native-type="submit">Save</p-button></div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { User } from '@/resources/user'

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("regex", regex);

export default {
  props: ['user'],
  components: { },
  data() {
    return {
      form: {
        email: "",
        name: "",
        password: null
      },
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.user)
  },
  methods: {
    submit() {
      this.loading = true

      User.updateCredential(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Account updated',
          });

          this.$store.commit('saveUser', result.data.user);
        })
        .catch(error => {
          // let message = error.response.data.message || 'Unable to save this time'
          // this.$notify({
          //   type: 'danger',
          //   title: "Error",
          //   text: message,
          // });
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
