<template>
  <div v-if="payroll" class="row" style="padding: 10px">
    <el-dialog
      title="Attention"
      :visible.sync="showConfirm"
      width="30%"
      :append-to-body="true"
      :before-close="handleClose">
      <div v-if="selected">
        <span>Employee {{ this.selected.first_name }} {{ this.selected.last_name }} has already been added in the payroll. Do you want to add a duplicate paycheck ?</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="confirmAdd()">Yes</el-button>
      </span>
    </el-dialog>
    
    <div class="col-md-12">
      <hr>
      <div class="row">
        <div class="col-md-5">
          <h5>Add employees by department</h5>
          <departments-list :select="true" :edit="false" :remove="false" @onSelect="handleSelectDepartment($event)"></departments-list>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6">
          <h5>Or select employee to add</h5>
          <employees-list :title="'Search Employees'" :add="false" :select="true" @onSelect="handleSelectEmployee($event)"></employees-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { Paycheck } from '@/resources/paycheck'
import EmployeesList from '../Employees/EmployeesList.vue';
import DepartmentsList from '../Departments/DepartmentsList.vue';

export default {
  components: { EmployeesList, DepartmentsList },
  props: {
    payroll: {
      type: Object,
      description: 'Payroll',
      default: null
    },
  },
  data() {
    return {
      showConfirm: false,
      selected: null
    };
  },
  methods: {
    confirmAdd() {
      this.handleClose()
      this.createPaycheck(this.selected)
    },
    handleClose() {
      this.showConfirm = false   
    },
    handleSelectDepartment(department) {
      if (!department.employees_count) {
        this.$notify({
          type: 'danger',
          title: "Error",
          text: "Department has no employees",
        });
        return false
      }

      this.$loading = true
       
      Paycheck.createPaychecksFromDepartment({ department_id: department.id, payroll_id: this.payroll.id })
        .then(result => {

           this.$notify({
            type: 'success',
            title: "Success",
            text: result.data.message,
           });
          
          if (result.data.processing == true) {
            this.$emit('close', false)
          }
        })
        .catch(() => {


        }).finally(() => {
          this.$loading = false
        })
    },
    handleSelectEmployee(employee) {
      //check if employees already in the payroll
      this.$loading = true
      Paycheck.checkEmployeePayroll({ params: {employee_id: employee.id, payroll_id: this.payroll.id} })
        .then(result => {
          if (result.data.paycheck) {
            // add confirmation to add a duplicate record 
            this.showConfirm = true
            this.selected = Object.assign({}, employee)
          } else {
            this.createPaycheck(employee)
          }
        })
        .catch(() => {
         

        }).finally(() => {
          this.$loading = false
        })
    },

    createPaycheck(employee) {
      Paycheck.create({ employee_id: employee.id, payroll_id: this.payroll.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee '+employee.first_name+' has been added to payroll successfully',
          });
        })
        .catch(() => {


        }).finally(() => {
          this.$loading = false
        })
    }
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>