var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticStyle:{"width":"250px","display":"block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(!_vm.form.delete_)?_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card bg-light-gray"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"company_deduction","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
              { 'has-danger': failed },
              { 'has-success': passed }],attrs:{"default-first-option":true,"readonly":!_vm.editable,"clearable":"","filterable":"","placeholder":"Name","remote-method":_vm.getCompanyDeductions,"loading":_vm.loading,"remote":""},model:{value:(_vm.form.company_deduction),callback:function ($$v) {_vm.$set(_vm.form, "company_deduction", $$v)},expression:"form.company_deduction"}},_vm._l((_vm.company_deductions),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name + ' - ' + _vm.$store.state.deduction_types[item.type],"value":item}})}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Name is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Amount")]),_c('ValidationProvider',{attrs:{"name":"amount","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('fg-input',{attrs:{"readonly":!_vm.editable,"type":"number","error":failed ? 'Amount is required' : null,"hasSuccess":passed,"name":"amount"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group bg-dark-gray text-white",class:[
                  { 'has-danger': failed },
                  { 'has-success': passed }],attrs:{"disabled":true,"placeholder":"Select","type":"primary"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.$store.state.deduction_types),function(f,i){return _c('el-option',{key:i,attrs:{"value":i,"label":f}},[_vm._v(_vm._s(f))])}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Deduction type is required")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-12"},[_c('div',[_vm._v("Amount:")]),_vm._v(" "+_vm._s(_vm.money(_vm.form.amount))+" "),(_vm.form.id)?_c('span',{staticClass:"ytd"},[_vm._v(" (YTD: "+_vm._s(_vm.money(_vm.form.ytd_amount))+")")]):_vm._e()])]),(_vm.editable)?_c('confirm-delete',{attrs:{"klass":"btn-block","label":"Delete"},on:{"onClick":function($event){return _vm.handleDelete(_vm.form)}}}):_vm._e()],1)])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }