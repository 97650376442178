<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            Personal Info
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Marital Status</label>
                <el-select v-model="form.marital_status" placeholder="Select">
                  <el-option value="Single">Single</el-option>
                  <el-option value="Married">Married</el-option>
                  <el-option value="Separated">Separated</el-option>
                  <el-option value="Divorced">Divorced</el-option>
                  <el-option value="Widowed">Widowed</el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Gender</label>
                <el-select v-model="form.gender" placeholder="Select">
                  <el-option value="male">Male</el-option>
                  <el-option value="female">Female</el-option>
                  <el-option value="non_binary">Non-Binary</el-option>
                  <el-option value="genderqueer">Genderqueer</el-option>
                  <el-option value="genderfulid">Genderfluid</el-option>
                  <el-option value="agender">Agender</el-option>
                  <el-option value="two_spirit">Two-Spirit</el-option>
                  <el-option value="bigender">Bigender</el-option>
                  <el-option value="androgynous">Androgynous</el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Date of Birth</label>
                <el-date-picker
                  v-model="form.birth_date"
                  type="date"
                  placeholder="Pick a day"
                  :picker-options="pickerOptions">
                </el-date-picker>  
              </div>
            </div>
            <div class="col-md-12"><hr></div>
            <div class="col-md-6">
              <address-form :address="form.personal_address" :title="'Personal Address'" @validated="setPersonalAddress($event)" ref="personalAddress"></address-form>
            </div>
            <div class="col-md-6">
                <address-form :address="form.work_address" :title="'Work Address'" @validated="setWorkAddress($event)" ref="workAddress"></address-form>
              </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button size="small" @click="close()" class="ml-2">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Employee } from '@/resources/employee'
import AddressForm from '../../Address/AddressForm.vue';

extend("required", required);

export default {
  components: { AddressForm },
  props: ['employee'],
  data() {
    return {
      showDepartment: false,
      showPosition: false,
      form: {
        gender: null,
        marital_status: null,
        birth_date: null,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    };
  },
  watch: {
    employee: function () {
      if (this.employee && this.employee.employee_info) this.form = Object.assign({}, this.employee.employee_info)
    }
  },
  created: function () {
    if (this.employee && this.employee.employee_info) this.form = Object.assign({}, this.employee.employee_info)
  },
  beforeDestroy() {
    this.form = {
      gender: null,
      marital_status: null,
      birth_date: null,
    }
  },
  methods: {
    setPersonalAddress(validated, address) {
      if (validated) {
        this.form.personal_address = Object.assign({}, address)
      }
    },
    setWorkAddress(validated, address) {
      if (validated) {
        this.form.work_address = Object.assign({}, address)
      }
    },
    close() {
      this.$emit('save', false)
    },
    submit() {
      this.$loading = true
      this.setPersonalAddress(true, this.$refs['personalAddress'].form)
      this.setWorkAddress(true, this.$refs['workAddress'].form)

      Employee.savePersonalInfo(this.employee.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee info has been saved',
          });
          this.$emit('save', result.data.employee_info)
        })
        .catch(error => {
          
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
