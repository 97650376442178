<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showCompanyEarningForm" direction="btt" :before-close="handleClose" size="90%"
    :append-to-body="true"
        :destroy-on-close="true">
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6">
            <company-earning-form @close="getCompanyEarnings()" v-if="showCompanyEarningForm"></company-earning-form>
          </div>
        </div>
      </el-drawer>

    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-12 mt-2" style="overflow: overlay;">
          <div>EARNINGS</div>
          <div><el-link type="primary" @click="addEarningItem()">Add earning item</el-link></div>
          <table style="width: 400px" v-if="company_earnings.length">
            <tr>
              <td v-for="earning, i in earnings" :key="i" >
                <transition name="el-zoom-in-center">
                  <earning-form :earning="earning" v-if="!earning.delete_" @update="setEarning($event, i)" :editable="paycheck.editable" :company_earnings="company_earnings"></earning-form>
                </transition>
                
              </td>
              <td>
                <el-button size="mini" class="ml-2" @click="handleAddEarning()" v-if="paycheck.editable">ADD +</el-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import EarningForm from './EarningForm.vue'
import {
  CompanyEarning
} from '@/resources/company_earning'
import CompanyEarningForm from '../../../Earnings/CompanyEarningForm.vue'

export default {
  components: { EarningForm, CompanyEarningForm },
  props: ['paycheck'],
  data() {
    return {
      earnings: [],
      selected: null,
      total_non_taxable: 0,
      total_taxable: 0,
      total: 0,
      company_earnings: [],
      showCompanyEarningForm: false
    }
  },
  watch: {
    earnings: {
      handler: function (newVal) {
        this.$emit('update', newVal)
      },
      deep: true
    },
    paycheck: {
      handler: function (newVal) {
        if (this.paycheck) this.earnings = this.paycheck.earnings
      },
      deep: true
    },
  },
  created: function () {
    if (this.paycheck) this.earnings = this.paycheck.earnings
    this.getCompanyEarnings()
  },
  computed: {
    
  },
  methods: {
    handleClose() {
      this.showCompanyEarningForm = false
    },
    addEarningItem() {
      this.showCompanyEarningForm = true
    },
    getCompanyEarnings() {
      let params = {
        page: 1,
        per_page: 20
      }
      this.loading = true
      this.showCompanyEarningForm = false
      CompanyEarning.get({
        params: params
      })
        .then(result => {
          if (result.data.company_earnings)
            this.company_earnings = result.data.company_earnings
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleAddEarning() {
      this.earnings.push({})
    },
    setEarning(newEarning, index) {
      let list = Object.assign([], this.earnings);
      list[index] = Object.assign({}, newEarning)
      this.earnings = Object.assign([], list);
      this.total_non_taxable = this.totalNonTaxableEarnings()
      this.total_taxable = this.totalTaxableEarnings()
      this.total = this.totalEarnings()
    },
    totalTaxableEarnings() {
      let total = 0;
      this.earnings.forEach(function (earning) {
        if (!earning.delete_ && earning.type == 1) total += earning.amount
      })

      return total;
    },
    totalNonTaxableEarnings() {
      let total = 0;
      this.earnings.forEach(function (earning) {
        if (!earning.delete_ && earning.type != 1) total += earning.amount
      })

      return total;
    },
    totalEarnings() {
      return this.totalTaxableEarnings() + this.totalNonTaxableEarnings()
    }
  }
}
</script>
<style lang="scss"></style>
