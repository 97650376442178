<template>
  <div class="card card-user" v-if="employee">
    <div class="image">
      <img src="@/assets/images/profile_banner.jpg" alt="...">
    </div>
    <div class="card-body">
      <div class="author">
        <a>
          <img class="avatar border-gray" :src="employee.profile_picture" alt="...">
          <h5 class="title" style="font-size: 15px;">{{ employee.full_name }}</h5>
        </a>
        <p class="description">
          {{ employee.email }}
        </p>
      </div>
      <p class="description text-center">
        {{ employee.masked_ssn }} <br>
        {{ employee.position ? employee.position.name : '' }} <br>
        {{ employee.department ? employee.department.name : '' }}
      </p>
      <div class="description text-center">
        <el-tag v-if="!employee.work_schedule" type="success" class="text-center">Available</el-tag>
        <el-tag  v-else> {{ 'Working in ' + employee.work_schedule.company_job.title + " (" + employee.work_schedule.company_job.wc_code + ")" }}</el-tag>
      </div>
    </div>
    <div class="card-footer">
      <hr>
      <div class="button-container">
         <el-button size="small" type="primary" link @click="handleShow(employee)" v-if="view">
            VIEW <i class="fa fa-eye"></i>
          </el-button>
          <el-button type="success" size="small" @click="handleSelect(employee)" v-if="select">SELECT</el-button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'employee-card',
  props: {
    employee: {
      type: Object,
      description: 'Heading Title',
      default: null
    },
    view: {
      type: Boolean,
      description: 'view employee',
      default: true
    },
    select: {
      type: Boolean,
      description: 'view employee',
      default: false
    },
  },
  methods: {
    handleShow(employee) {
      this.$emit('handleShow', employee)
    },
    handleSelect(employee) {
      this.$emit('handleSelect', employee)
    },
  }
}

</script>
<style></style>
  