<template>
  <div class="container" v-if="paycheck" style="padding: 20px">
    <taxes-list :paycheck="selected" :is_employee="true" @update="getPaycheck"></taxes-list>
    <taxes-list :paycheck="selected" :is_employee="false" @update="getPaycheck"></taxes-list>
  </div>
</template>

<script>
import {
  Paycheck
} from '@/resources/paycheck'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import TaxesList from './Taxes/TaxesList.vue';

extend("required", required);

export default {
  components: { TaxesList },
  props: ['paycheck'],
  data() {
    return {
      selected: null
    }
  },
  created: function () {
    this.getPaycheck()
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
    getPaycheck() {
      this.$loading = true
      this.showTaxInfoForm = false
      Paycheck.show(this.paycheck.id)
        .then(result => {
          this.selected = result.data.paycheck
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
   
  }
}
</script>

<style lang="scss"></style>
