var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Deduction' : 'Create Deduction')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
              { 'has-danger': failed },
              { 'has-success': passed }],model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Deduction name is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group bg-dark-gray text-white",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"Select","type":"primary"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.$store.state.deduction_types),function(f,i){return _c('el-option',{key:i,attrs:{"value":i,"label":f}},[_vm._v(_vm._s(f))])}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Deduction type is required")]):_vm._e()]}}],null,true)})],1),_c('el-button',{attrs:{"type":"info","native-type":"submit","size":"small"}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"button","size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }