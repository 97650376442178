var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Payroll' : 'Create New Payroll')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Type")]),_c('el-select',{attrs:{"type":"number"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('el-option',{attrs:{"value":1,"label":"FEDERAL"}},[_vm._v("FEDERAL")]),_c('el-option',{attrs:{"value":2,"label":"STATE"}},[_vm._v("STATE")])],1)],1),(_vm.form.type == 2)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Select the State Tax Type")]),_c('el-select',{on:{"change":_vm.handleSetStateType},model:{value:(_vm.state_type),callback:function ($$v) {_vm.state_type=$$v},expression:"state_type"}},[(_vm.is_employee)?_c('el-option',{attrs:{"value":"STATE","label":"STATE"}},[_vm._v("STATE TAX")]):_vm._e(),(_vm.is_employee)?_c('el-option',{attrs:{"value":"SDI","label":"SDI"}},[_vm._v("SDI")]):_vm._e(),(_vm.is_employee)?_c('el-option',{attrs:{"value":"LOCAL","label":"LOCAL"}},[_vm._v("LOCAL TAX")]):_vm._e(),(!_vm.is_employee)?_c('el-option',{attrs:{"value":"UNEMPLOYMENT","label":"UNEMPLOYMENT"}},[_vm._v("UNEMPLOYMENT")]):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Tax Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
  { 'has-danger': failed },
  { 'has-success': passed }],attrs:{"placeholder":"Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Name is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Amount")]),_c('el-input',{attrs:{"type":"number"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1),_c('el-button',{attrs:{"type":"info","native-type":"submit","size":"small"}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"button","size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Close")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }