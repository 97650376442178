<template>
  <el-card>
    <el-avatar :size="50" :src="circleUrl"></el-avatar>
    <div>{{ employee.full_name }}</div>
    <div>{{ employee.masked_ssn }}</div>
    <div>
      <el-button class="btn-block" size="mini" :type="buttonType" @click="handleAdd" :plain="plain">{{buttonText}}</el-button>
    </div>
  </el-card>
</template>

<script>


export default {
  props: {
    employee: {
      type: Object,
      description: 'Heading Title',
      default: null
    },
    buttonText: {
      type: String,
      description: 'Heading Title',
      default: 'ADD'
    },
    buttonType: {
      type: String,
      description: 'Heading Title',
      default: 'primary'
    },
    plain: {
      type: Boolean,
      description: 'Heading Title',
      default: false
    },
  },
  data() {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    }
  },
  methods: {
    handleAdd() {
      this.$emit('click', this.employee)
    }
  }
}
</script>

<style lang="scss"></style>
