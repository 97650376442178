<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update company' : 'Create new company' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>FEIN</label>
            <ValidationProvider name="fein" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The fein field format is invalid' : null" :hasSuccess="passed"
                name="fein" v-model="form.fein">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Company } from '@/resources/company'

extend("required", required);

export default {
  props: ['company'],
  data() {
    return {
      form: {
        fein: "",
        name: "",
      }
    };
  },
  watch: {
    company: function () {
      if (this.company) this.form = Object.assign({}, this.company)
    }
  },
  created: function () {
    if (this.company) this.form = Object.assign({}, this.company)
  },
  beforeDestroy() {
    this.form = {
      fein: "",
      name: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      let request = this.form.id ? Company.update(this.form) : Company.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Company has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
