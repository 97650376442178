<template>
  <div class="row">
    <div class="col-md-4">
      <el-card>
        <div class="row">
          <div class="col-sm-12">
            <div>
              <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
          <div class="col-md-12">
            <el-table class="table-striped" header-row-class-name="text-primary" :data="employees" style="width: 100%">
              <el-table-column fixed="right" class-name="td-actions" label="Name">
                <template slot-scope="props">
                  {{ props.row.full_name }} <span class="note">({{ props.row.formatted_ssn }})</span>
                  <el-button plain size="mini" style="float: right" @click="handleSelect(props.row)">SELECT</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </el-card>
    </div>
    <div class="col-md-8">
      <selected-employee-paychecks-list :employee="selected" v-if="selected"></selected-employee-paychecks-list>
    </div>
  </div>
</template>

<script>
import {
  PayrollReport
} from '@/resources/payroll_report'
import {
  Employee
} from '@/resources/employee'

import PPagination from 'src/components/UIComponents/Pagination.vue'
import SelectedEmployeePaychecksList from './SelectedEmployeePaychecksList.vue'


export default {
  components: { PPagination, SelectedEmployeePaychecksList },
  data() {
    return {
      employees: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      selected: null,
      term: null
    }
  },
  created: function () {
    this.getEmployees()
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getEmployees()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getEmployees()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getEmployees()
    }
  },
  methods: {
    handleSelect(employee) {
      this.selected = Object.assign({}, employee)
    },
    getEmployees() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        department_ids: this.department_ids
      }
      this.$loading = true
      Employee.get({
          params: params
        })
        .then(result => {
          if (result.data.employees)
            this.employees = result.data.employees
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch employees',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
