<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            Tax Info
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Tax Type</label>
                <el-select v-model="form.type" placeholder="Select">
                  <el-option :value="i" :label="pm" v-for="pm, i in $store.state.tax_types" :key="i">{{ pm }}</el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row" v-if="form.type == 1">
            <div class="col-md-2">
              <div class="form-group">
                <label>W4 Type</label>
                <el-select v-model="form.w4_type" placeholder="Select">
                  <el-option :value="i" :label="type" v-for="type, i in $store.state.w4_types" :key="i">{{ type
                  }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Payment Method</label>
                <el-select v-model="form.payment_method" placeholder="Select">
                  <el-option :value="i" :label="pm" v-for="pm, i in $store.state.payment_methods" :key="i">{{ pm
                  }}</el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Work Zip</label>
                <ValidationProvider name="work_zip" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Work zip is required' : null" :hasSuccess="passed"
                    name="work_zip" v-model="form.work_zip">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Home Zip</label>
                <ValidationProvider name="home_zip" rules="required" v-slot="{ passed, failed }">
                  <fg-input type="text" :error="failed ? 'Home zip is required' : null" :hasSuccess="passed"
                    name="home_zip" v-model="form.home_zip">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-7">
              <div class="form-group">
            
                <el-checkbox v-model="form.has_multiple_jobs" :checked="form.has_multiple_jobs == 1">Has multiple job ?</el-checkbox>
              </div>
            </div>
            <div class="col-md-12">
              <hr>
              <h5>Federal Info</h5>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Federal Filing Status</label>
                <el-select v-model="form.federal_filing_status" placeholder="Select">
                  <el-option :value="i" :label="f" v-for="f, i in $store.state.federal_filing_statuses" :key="i">{{ f
                  }}</el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Children Dependents</label>
                <el-input-number v-model="form.children_dependents" :min="0" :max="10"></el-input-number>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Other Dependents</label>
                <el-input-number v-model="form.other_dependents" :min="0" :max="10"></el-input-number>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Additional Deductions</label>
                <el-input v-model="form.federal_additional_deductions" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <el-checkbox v-model="form.is_federal_exempted" :checked="form.is_federal_exempted == 1">Federal Exempted ?</el-checkbox>
              </div>
            </div>

            <div class="col-md-12">
              <hr>
              <h5>State Info</h5>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>State Filing Status</label>
                <el-select v-model="form.state_filing_status" placeholder="Select">
                  <el-option :value="i" :label="f" v-for="f, i in $store.state.federal_filing_statuses" :key="i">{{ f
                  }}</el-option>
                </el-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Children Dependents</label>
                <el-input-number v-model="form.state_children_dependents" :min="0" :max="10"></el-input-number>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Other Dependents</label>
                <el-input-number v-model="form.state_other_dependents" :min="0" :max="10"></el-input-number>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Additional Deductions</label>
                <el-input v-model="form.state_additional_deductions" type="number"></el-input>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <el-checkbox v-model="form.is_state_exempted" :checked="form.is_state_exempted == 1">State Exempted ?</el-checkbox>
              </div>
            </div>

          </div>

        </div>
        <div class="card-footer text-right">
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button size="small" @click="close()" class="ml-2">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Paycheck } from '@/resources/paycheck'

extend("required", required);

export default {
  props: ['taxInfo'],
  data() {
    return {
      form: {
        has_multiple_jobs: false,
        w4_type: 1,
        payment_method: 0,
        is_federal_exempted: false,
        federal_filing_status: 1,
        children_dependents: 0,
        other_dependents: 0,
        total_dependents: 0,
        deductions: 0,
        other_income: 0,
        federal_additional_deductions: 0,
        is_state_exempted: false,
        state_filing_status: 1,
        state_children_dependents: 0,
        state_other_dependents: 0,
        state_total_dependents: 0,
        state_additional_deductions: 0
      }
    };
  },
  watch: {
    taxInfo: function () {
      if (this.taxInfo) this.form = Object.assign({}, this.taxInfo)
      this.checkZip()
    }
  },
  created: function () {
    if (this.taxInfo) this.form = Object.assign({}, this.taxInfo)
    this.checkZip()
  },
  beforeDestroy() {
    this.form = {
      has_multiple_jobs: false,
      w4_type: 1,
      payment_method: null,
      is_federal_exempted: false,
      federal_filing_status: 1,
      children_dependents: 0,
      other_dependents: 0,
      total_dependents: 0,
      deductions: 0,
      other_income: 0,
      federal_additional_deductions: 0,
      is_state_exempted: false,
      state_filing_status: 1,
      state_children_dependents: 0,
      state_other_dependents: 0,
      state_total_dependents: 0,
      state_additional_deductions: 0
    }
  },
  methods: {
    checkZip() {

      // if (!this.form.work_zip && this.taxInfo) {
      //   if (this.employee.employee_info.work_address) this.form.work_zip = this.employee.employee_info.work_address.zip
      // }

      // if (!this.form.home_zip && this.employee.employee_info) {
      //   if (this.employee.employee_info.personal_address) this.form.home_zip = this.employee.employee_info.personal_address.zip
      // }
    },
    close() {
      this.$emit('save', false)
    },
    submit() {
      this.$loading = true

      Paycheck.saveTaxInfo(this.form.paycheck_id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Employee tax info has been saved',
          });
          this.$emit('save', result.data.tax_info)
        })
        .catch(error => {
          
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
