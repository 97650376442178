<template>
  <div>
    <div v-if="employee && !showForm" class="row ">
      <el-descriptions class="margin-top col-md-6" title="" :column="1" size="small" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Full Name
          </template>
          {{ form.full_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            SSN
          </template>
          {{ form.ssn }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Position
          </template>
          {{ form.position ? form.position.name : 'N/A' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Department
          </template>
          {{ form.department ? form.department.name : 'N/A' }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="col-md-12 mt-2">
        <el-button size="small" @click="handleAddInfo">Update Details</el-button>
      </div>
    </div>

    <employee-form v-if="showForm" :employee="form"
        @save="setEmployee($event)"></employee-form>
  </div>
</template>
<script>
import EmployeeForm from './EmployeeForm.vue';

export default {
  props: ['employee'],
  components: { EmployeeForm },
  data() {
    return {
      showForm: false,
      form: null
    };
  },
  watch: {
    employee: function () {
      this.form = Object.assign({}, this.employee)
    }
  },
  created: function () {
    this.form = Object.assign({}, this.employee)
  },
  methods: {
    setEmployee(employee) {
      this.showForm = false
      if (employee) {
        this.form = Object.assign({}, employee)
        this.$emit('save', this.form)
      }
    },
    handleAddInfo() {
      this.showForm = true
    },
  }
}
</script>
<style></style>
