<template>
  <el-popconfirm confirm-button-text='OK' cancel-button-text='No, Thanks' icon="el-icon-info" icon-color="red"
    title="Are you sure about this?" @confirm="onClick()">
    <el-button size="mini" :type="type" slot="reference" class="" :class="klass">{{ label }} <i
        :class="icon"></i></el-button>
  </el-popconfirm>
</template>
<script>

export default {
  props: {
    label: {
      type: String,
      description: 'Heading Title',
      default: null
    },
    klass: {
      type: String,
      description: 'class name',
      default: null
    },
    type: {
      type: String,
      description: 'type',
      default: null
    },
    icon: {
      type: String,
      description: 'example fa fa-trash',
      default: null
    }
  },
  data() {
    return {

    };
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
};
</script>

<style scoped>
.error-text {
  display: block;
}
</style>