<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update State' : 'Create State Unemployment' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>State</label>
            <ValidationProvider name="state" rules="required" v-slot="{ passed, failed }">
              <el-select filterable v-model="form.state" placeholder="Select state" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                <el-option :value="s.abbreviation" :label="s.name" v-for="s, i in $store.state.states" :key="i">{{ s.name }}</el-option>
              </el-select>
              <span class="error-text" v-if="failed">State is required</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Year</label>
            <ValidationProvider name="year" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.year" placeholder="Select year" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                <el-option :value="y" :label="y" v-for="y, i in $store.state.years" :key="i">{{ y }}</el-option>
              </el-select>
              <span class="error-text" v-if="failed">Year is required</span>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Account Number</label>
              <ValidationProvider name="account_number" v-slot="{ failed }">
                <el-input v-model="form.account_number"></el-input>
                <span class="error-text" v-if="failed">Account number required</span>
              </ValidationProvider>
            </div>

            <div class="form-group">
                <label>Unemployment Rate (%)</label><br>
                <ValidationProvider name="unemployment_rate" v-slot="{ failed }">
                  <el-input-number v-model="form.unemployment_rate" style="width: 300px;"></el-input-number>
                  <span class="error-text" v-if="failed">Unemployment rate is required</span>
                </ValidationProvider>
              </div>
          
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { CompanyState } from '@/resources/company_state'

extend("required", required);

export default {
  props: ['company_state'],
  data() {
    return {
      form: {
        state: null,
        year: null,
        account_number: null,
        unemployment_rate: 0
      }
    };
  },
  watch: {
    company_state: function () {
      if (this.company_state) this.form = Object.assign({}, this.company_state)
    }
  },
  created: function () {
    if (this.company_state) this.form = Object.assign({}, this.company_state)
  },
  beforeDestroy() {
    this.form = {
      state: null,
      year: null,
      account_number: null,
      unemployment_rate: 0
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? CompanyState.update(this.form) : CompanyState.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'State has been saved',
          });
          this.$emit('close', result.data.state)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
