<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
     
      <h5 class="info-text">{{ title || 'Address'}}</h5>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <label>Street 1</label>
          <ValidationProvider name="street1" rules="required" v-slot="{ passed, failed }">
            <fg-input name="street1" :error="failed ? 'The Street line 1 field is required' : null" :hasSuccess="passed"
              placeholder="Street line 1 (required)" v-model="form.street1" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>

        <div class="col-md-12">
          <label>Street 2 (ootional)</label>
          <ValidationProvider name="street2" v-slot="{ passed, failed }">
            <fg-input name="fein" :error="failed ? 'The FEIN field is required' : null" :hasSuccess="passed"
              placeholder="Street line 2" v-model="form.street2" addon-left-icon="nc-icon nc-circle-10">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <label>City/County</label>
          <ValidationProvider name="city" rules="required" v-slot="{ passed, failed }">
            <fg-input name="city" :error="failed ? 'The City field is required' : null" :hasSuccess="passed"
              placeholder="City (required)" v-model="form.city" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-7">
          <div class="form-group">
            <label>State</label><br>
            <ValidationProvider name="State" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.state" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                <el-option :value="s.state" :label="s.state + ' - ' + s.state_complete" v-for="s in $store.state.states" :key="s.state">{{ s.state }} - {{ s.state_complete }}</el-option>
              </el-select><br>
              <span class="error-text" v-if="failed">State is required</span>
            
            </ValidationProvider>
          </div>
        </div>
        <div class="col-md-5">
          <label>Zip Code</label>
          <ValidationProvider name="zip" rules="required" v-slot="{ passed, failed }">
            <fg-input name="zip" :error="failed ? 'The Zip field is required' : null" :hasSuccess="passed"
              placeholder="Zip Code (required)" v-model="form.zip" addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  props: ['address','title'],
  data() {
    return {
      form: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        zip:null
      }
    };
  },
  watch: {
    address: function () {
      if (this.address) {
        this.form = Object.assign({}, this.address)
      }
    },
    form: {
      handler: function (newVal) {
        // this.validate()
        // this.$emit('validated', true, this.form, 'address')
      },
      deep: true
    },
  },
  created: function () {
    if (this.address) this.form = Object.assign({}, this.address)
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        this.$emit('validated', res, this.form, 'address')
        return res
      })
    }
  }
}
</script>
<style lang="scss">
.card-wizard .picture-container .picture {
  input[type="file"] {
    display: none;
  }
}
</style>
