<template>
  <div >
    <div class="row">
      <div class="col-sm-4">
        <div >
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-md-12 no-data-text" v-if="!employees.length">
        No employees
      </div>
    </div>
    <draggable group="people" :list="employees" style=";min-height: 400px;" class="row">
        <div class="col-md-4"  v-for="employee,i in employees" :key="i">
          <employee-mini-card :employee="employee" @click="handleClick(employee)" :plain="true"></employee-mini-card>
        </div>
    </draggable>

    <div class="col-md-12">
      <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
      </p-pagination>
    </div>
  </div>
</template>

<script>

import draggable from "vuedraggable";
import { Employee } from '@/resources/employee'
import EmployeeMiniCard from './EmployeeMiniCard.vue';

export default {
  components: {
    draggable,
    EmployeeMiniCard,
  },
  props: ['addEmployee'],
  data() {
    return {
      list: [],
      employees: [],
      pagination: {
        perPage: 6,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    addEmployee: function () {
      this.getEmployees()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getEmployees()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getEmployees()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getEmployees()
    }
  },
  created: function () {
    this.getEmployees()
  },
  methods: {
    handleClick(employee) {
      this.$emit('click', employee)
    },
    getEmployees() {
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      Employee.get({ params: params })
        .then(result => {
          if (result.data.employees)
            this.employees = result.data.employees
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch employees',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
