<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ form.id ? 'Update Default Earning' : 'Create New Default Earning' }}
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Name</label>
              <ValidationProvider name="company_earning" rules="required" v-slot="{ passed, failed }">
                <el-select filterable v-model="form.company_earning" placeholder="Name" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]" :remote-method="getCompanyEarnings" :loading="loading" remote>
                  <el-option v-for="item in company_earnings" :key="item.id" :label="item.name" :value="item">
                  </el-option>
                </el-select>

                <span class="error-text" v-if="failed">Name is required</span>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Hours</label>
              <ValidationProvider name="hours" rules="" v-slot="{ passed, failed }">
                <fg-input type="number" :error="failed ? 'Start date is required' : null" :hasSuccess="passed" name="hours" v-model="form.hours">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Rate</label>
              <ValidationProvider name="rate" rules="" v-slot="{ passed, failed }">
                <fg-input type="number" :error="failed ? 'Rate is required' : null" :hasSuccess="passed" name="rate" v-model="form.rate">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-6" v-if="form.company_earning">
            <div class="form-group">
              <el-switch disabled v-model="form.company_earning.is_accruable" :active-value="1" :inactive-value="0" active-text="Accrue" inactive-text="" active-color="#13ce66" inactive-color="#909399">
              </el-switch>
            </div>
          </div>
          <div class="col-md-6" v-if="form.company_earning">
            <div class="form-group">
              <el-switch disabled v-model="form.company_earning.type" :active-value="1" :inactive-value="0" active-text="Taxable" inactive-text="" active-color="#13ce66" inactive-color="#909399">
              </el-switch>
            </div>
          </div>
          <!-- <div class="col-md-12 mb-2">
            <div>Amount:</div>
            {{ money(form.amount) }} <span class="ytd" v-if="form.id"> (YTD: {{ money(form.ytd_amount) }})</span>
          </div> -->
          
        </div>

        <p-button type="info" native-type="submit" size="small">Save</p-button>
        <p-button type="button" size="small" @click="close()">Close</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  EmployeeDefaultEarning
} from '@/resources/employee_default_earning'

import {
  CompanyEarning
} from '@/resources/company_earning'

extend("required", required);

export default {
  props: ['default_earning', 'employee'],
  data() {
    return {
      form: {
        company_earning: null
      },
      company_earnings: [],
      loading: false
    };
  },
  watch: {
    default_earning: function () {
      if (this.default_earning) this.form = Object.assign({}, this.default_earning)
    }
  },
  created: function () {
    if (this.default_earning) this.form = Object.assign({}, this.default_earning)
    if (this.form.company_earning) this.company_earnings = [this.form.company_earning]
  },
  beforeDestroy() {
    this.form = {
      company_earning: null
    }
  },
  methods: {
    getCompanyEarnings(term) {
      let params = {
        term: term,
        page: 1,
        per_page: 5
      }
      this.loading = true
      CompanyEarning.get({
          params: params
        })
        .then(result => {
          if (result.data.company_earnings)
            this.company_earnings = result.data.company_earnings
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true
      this.form.employee_id = this.employee.id
      this.form.company_earning_id = this.form.company_earning.id
      let request = this.form.id ? EmployeeDefaultEarning.update(this.form) : EmployeeDefaultEarning.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Default earning has been saved',
          });
          this.$emit('close', result.data.default_earning)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
