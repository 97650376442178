<template>
  <div class="row">
    <el-drawer :title="selected && selected.id ? 'Edit':'New'" :visible.sync="showForm" direction="rtl"
      :before-close="handleClose">
      <div style="padding: 10px;">
        <user-edit-form :user="selected" @close="getUsers()"></user-edit-form>
      </div>
    </el-drawer>

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this item? </p>
        <p-button type="success" size="sm" icon @click="deleteItem()">
          <i class="fa fa-edit"></i>
        </p-button>
      </div>

    </el-drawer>


    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Users</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-5">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select class="select-primary" v-model="status" placeholder="Per page">
            <el-option class="select-primary" v-for="item,i in status_list" :key="i" :label="item" :value="i">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-1">
          <p-button type="primary" size="sm" style="float:right" @click="handleNew()">
            <i class="fa fa-plus"></i> NEW
          </p-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="users" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div style="padding-left: 50px">
                  <p>Roles: {{ props.row.role_names.join(", ") }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="column in userColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label">
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Roles">
              <template slot-scope="props">
                {{ props.row.role_names.join(", ") }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Current Company">
              <template slot-scope="props">
                {{ props.row.company ? props.row.company.name : 'N/A' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Companies">
              <template slot-scope="props">
                <el-tag type="primary">{{ props.row.companies.length }}</el-tag>
              </template>
            </el-table-column>


            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <p-button type="default" link>
                  <router-link :to="{ name: 'UserEdit', params: { id: props.row.id }}"><i
                      class="fa fa-edit"></i></router-link>
                </p-button>

                <p-button type="danger" size="sm" icon @click="handleDelete(props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import { User } from '@/resources/user'

  import Vue from 'vue'
  import {Table, TableColumn, Select, Option, Drawer} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
import UserEditForm from './UserEditForm.vue'
  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(Drawer)

  export default{
    components: {
      PPagination, UserEditForm
    },
    data () {
      return {
        selected: null,
        showForm: false,
        showDelete: false,
        users: [],
        perPageOptions: [5,10,15],
        pagination: {
          perPage: 10,
          currentPage: 1,
          total: 0
        },
        term: null,
        status_list: ['Pending','Active','Inactive'],
        status: 1,
        searchQuery: '',
        propsToSearch: ['name', 'email', 'age'],
        userColumns: [
          {
            prop: 'name',
            label: 'Name',
            minWidth: 200
          },
          {
            prop: 'email',
            label: 'Email',
            minWidth: 250
          }
        ],
      }
  },
  watch: {
    status: function () {
      this.getUsers()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getUsers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getUsers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getUsers()
    }
  },
    created: function() {
      this.getUsers()
    },
  methods: {
    handleNew() {
      this.showForm = true
      this.selected = {}
    },
      handleClose() {
        this.showDelete = false
        this.showForm = false
      },
      handleDelete(data) {
        this.showDelete = true
      },
      deleteItem() {

      },
    getUsers() {
      this.$loading = true
        this.handleClose()
        User.get({params: {term: this.term, status: this.status,page: this.pagination.currentPage, per_page: this.pagination.perPage}})
        .then(result => {
        if(result.data.users)
            this.users = result.data.users
            this.pagination = result.data.meta
        })
        .catch( () => {
        this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch users',
          });

        }).finally( () => {
          this.$loading = false
        })
      }
    }
  }
</script>
<style lang="scss">

</style>
