<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Import' : 'New Import' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Type</label>
            <el-select v-model="form.import_type">
              <el-option v-for="type,i in $store.state.payroll_import_types" :key="i" :value="i" :label="type" ></el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label>File</label>
            <file-upload :multiple="false" @change="setFiles($event)"></file-upload>
          </div>
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { PayrollImport } from '@/resources/payroll_import'
import FileUpload from '../../../../Dashboard/Views/Components/FileUpload.vue';

extend("required", required);

export default {
  components: { FileUpload },
  props: ['payroll_import'],
  data() {
    return {
      form: {
        name: null
      }
    };
  },
  watch: {
    payroll_import: function () {
      if (this.payroll_import) this.form = Object.assign({}, this.payroll_import)
    }
  },
  created: function () {
    if (this.payroll_import) this.form = Object.assign({}, this.payroll_import)
  },
  beforeDestroy() {
    this.form = {
      name: null
    }
  },
  methods: {
    setFiles(files) {
      if (files && files.length) {
        let file = files[0]
        this.form.file = file
      }
    },
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let formData = new FormData()
      formData.append('files[0]', this.form.file?.raw)
      formData.append('import_type', this.form.import_type)
      formData.append('id', this.form.id)

      let request = this.form.id ? PayrollImport.update(formData) : PayrollImport.create(formData)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Import has been queued',
          });
          this.$emit('close')
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
