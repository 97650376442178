<template>
  <div class="row">
    <div class="col-md-12">
      <h5>PAYROLL REPORT</h5>
    </div>
    <div class="col-md-12">
      <el-button plain @click="handleDownloadPayrollSummary" size="mini">PAYROLL SUMMARY</el-button>
      <el-button plain @click="handleDownloadPaystubs" size="mini">ALL PAYSTUBS</el-button>
      <el-button plain @click="handleDownloadTaxSummary" size="mini">TAX SUMMARY</el-button>
    </div>
  </div>
</template>
<script>

import { PayrollReport } from '@/resources/payroll_report'

export default {
  props: ['payroll'],
  data() {
    return {
      
    }
  },
  methods: {
    handleDownloadTaxSummary() {
      this.$loading = true
      this.$loadingText = "Generating Report. Please wait..."
      PayrollReport.downloadTaxSummary({payroll_id: this.payroll.id })
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
   handleDownloadPayrollSummary() {
      this.$loading = true
      this.$loadingText = "Generating Report. Please wait..."
      PayrollReport.downloadPayrollSummary({payroll_id: this.payroll.id })
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadPaystubs() {
      this.$loading = true
      this.$loadingText = "Generating Report. Please wait..."
      PayrollReport.downloadPaystubs({ payroll_id: this.payroll.id })
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    downloadFile(result) {
      if (result.data.file) {
        const link = document.createElement('a');
        link.setAttribute('href', result.data.file.link); // Replace 'path_to_your_file' with the file URL or data
        link.setAttribute('download', result.data.file.filename); // Replace 'filename.ext' with the desired file name and extension
        link.click();
      } else {
        this.$notify({
          type: 'success',
          title: "Success",
          text: 'Report has qeued. Please check the Payroll Reports Status',
        });
        this.$emit('close', true)
      }
    },
  }
}
</script>
<style lang="scss"></style>
