<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Earning Item' : 'Create Earning Item' }}
          </h5>
          <small>Generate or modify an item for use in adding earnings to a paycheck</small>
        </div>
        <div class="card-body">
          <div class="form-group">
              <label>Name</label>
              <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                <el-input v-model="form.name" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]"></el-input>
                <span class="error-text" v-if="failed">Earning name is required</span>
              </ValidationProvider>
            </div>


          <div class="form-group">
            <label>Multiplier</label>
            <ValidationProvider name="state" rules="required" v-slot="{ passed, failed }">
              <el-select filterable v-model="form.multiplier" placeholder="Select state" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                <el-option :value="m.value" :label="m.label" v-for="m, i in $store.state.multipliers" :key="i">{{ m.label
                }}</el-option>
              </el-select>
              <span class="error-text" v-if="failed">State is required</span>
            </ValidationProvider>
          </div>
         <div class="form-group">
            <small>Generally, income that is subject to taxation tends to align with the concept of being accrual-based.</small><br>
            <el-switch
              v-model="form.is_accruable"
              :active-value="1"
              :inactive-value="0"
              active-text="Accruable"
              inactive-text=""
              active-color="#13ce66"
              inactive-color="#909399">
            </el-switch>
          </div>

          <div class="form-group">
            <el-switch
              v-model="form.type"
              :active-value="1"
              :inactive-value="0"
              active-text="Subject to tax"
              inactive-text=""
              active-color="#13ce66"
              inactive-color="#909399">
            </el-switch>
          </div>

          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Cancel</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { CompanyEarning } from '@/resources/company_earning'

extend("required", required);

export default {
  props: ['company_earning'],
  data() {
    return {
      form: {
        name: null,
        is_accruable: 1,
        multiplier: 1,
        type: 1
      }
    };
  },
  watch: {
    company_earning: function () {
      if (this.company_earning) this.form = Object.assign({}, this.company_earning)
    }
  },
  created: function () {
    if (this.company_earning) this.form = Object.assign({}, this.company_earning)
  },
  beforeDestroy() {
    this.form = {
      name: null,
      is_accruable: 1,
      multiplier: 1,
      type: 1
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? CompanyEarning.update(this.form) : CompanyEarning.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Earning has been saved',
          });
          this.$emit('close', result.data.company_earning)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
