<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this import? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <payroll-import-form :payroll_import="selected" @close="getImports"></payroll-import-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Imports</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="">
            <el-input prefix-icon="nc-icon nc-zoom-split" size="small" class="input-sm" placeholder="Search"
              v-model="term">
              <el-button slot="append" type="primary" size="small" @click="handleForm">New +</el-button>
            </el-input>

          </div>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

        </div>

        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="payroll_imports"
            style="width: 100%">

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ $store.state.payroll_import_types[props.row.import_type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="File">
              <template slot-scope="props">
                <el-link :href="props.row.file.url" target="_blank" :download="props.row.file.filename"> {{
                  props.row.file.filename }}</el-link>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Remarks">
              <template slot-scope="props">
                {{ props.row.remarks }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Status">
              <template slot-scope="props">
                <el-tag type="info" v-if="props.row.status == 0">NEW</el-tag>
                <el-tag type="warning" v-if="props.row.status == 1">GENERATING</el-tag>
                <el-tag type="success" v-if="props.row.status == 2">SUCCESS</el-tag>
                <el-tag type="danger" v-if="props.row.status == 3">ERROR</el-tag>

              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" link @click="handleEdit(props.row)" size="mini"
                  v-if="props.row.status != 2 && props.row.status != 1">
                  <i class="fa fa-edit"></i>
                </el-button>

                <el-button type="default" link @click="handleReset(props.row)" size="mini"
                  v-if="props.row.status == 2 || props.row.status == 3">
                  <i class="fa fa-refresh"></i>
                </el-button>

                <confirm-delete class="ml-2" @onClick="handleDelete(props.row)" :label="''"
                  v-if="props.row.status != 1"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { PayrollImport } from '@/resources/payroll_import'
import PayrollImportForm from './PayrollImportForm.vue'
import ConfirmDelete from '../../../../Common/ConfirmDelete.vue'

export default {
  components: { PayrollImportForm, ConfirmDelete },
  data() {
    return {
      showDelete: false,
      showForm: false,
      payroll_imports: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getImports()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getImports()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getImports()
    }
  },
  created: function () {
    this.getImports()
  },
  methods: {
    handleReset(model) {
      this.$loading = true
      PayrollImport.reset(model.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Import has been reset',
          });
          this.getImports()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleView(group) {
      this.$router.push({ name: 'PayrollsList', params: { id: group.id } })
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(item) {
      this.$loading = true
      this.$loadingText = "Removing"

      PayrollImport.delete({
        id: item.id
      })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Import has been removed',
          });
          this.getImports()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleForm() {
      this.showForm = true
    },
    deleteItem() {

    },
    getImports() {
      this.handleClose()
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      PayrollImport.get({ params: params })
        .then(result => {
          if (result.data.payroll_imports)
            this.payroll_imports = result.data.payroll_imports
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch imports',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
