<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true" :append-to-body="true">
    <div style="padding: 20px;">
      <company-job-form v-if="showForm" @close="setJob($event)"></company-job-form>
    </div>
  </el-drawer>

  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ form.id ? 'Update Work Schedule' : 'Create Work Schedule' }}
        </h5>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Name</label>
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
            <el-input v-model="form.name" placeholder="Name" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
            </el-input>
            <span class="error-text" v-if="failed">Name is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Start Date</label>

          <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
            <el-date-picker v-model="form.start_date" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]" type="date" placeholder="" format="yyyy-MM-dd"></el-date-picker>

            <span class="error-text" v-if="failed">Start date is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>End Date</label>

          <ValidationProvider name="end_date" rules="required" v-slot="{ passed, failed }">
            <el-date-picker v-model="form.end_date" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]" type="date" placeholder="" format="yyyy-MM-dd"></el-date-picker>

            <span class="error-text" v-if="failed">End date is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Job</label>
          <ValidationProvider name="year" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.company_job_id" placeholder="Select job" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]" filterable remote :remote-method="getJobs" @change="handleCreateJob">
              <el-option v-for="company_job in company_jobs" :key="company_job.id" :label="company_job.title+' - '+company_job.wc_code" :value="company_job.id">
              </el-option>
              <el-option :value="null">Create Job +</el-option>
            </el-select>
            <span class="error-text" v-if="failed">Job is required</span>
          </ValidationProvider>
        </div>

        <div class="text-right">
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Close</el-button>
        </div>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  WorkSchedule
} from '@/resources/work_schedule'
import {
  CompanyJob
} from '@/resources/company_job'
import CompanyJobForm from '../Company/Jobs/CompanyJobForm.vue';

extend("required", required);

export default {
  components: {
    CompanyJobForm
  },
  props: ['work_schedule'],
  data() {
    return {
      form: {
        start_date: null,
        end_date: null
      },
      company_jobs: [],
      showForm: false
    };
  },
  watch: {
    work_schedule: function () {
      if (this.work_schedule) this.form = Object.assign({}, this.work_schedule)
    },
  },
  created: function () {
    if (this.work_schedule) {
      this.form = Object.assign({}, this.work_schedule)
      this.company_jobs.push(this.work_schedule.company_job)

    } else {
      this.getJobs()
    }

  },
  methods: {
    setJob(job) {
      if (job) {
        this.company_jobs.push(job)
        this.form.company_job_id = job.id
      }
      this.handleClose()
    },
    handleClose() {
      this.showForm = false
    },
    handleCreateJob(event) {
      if (!event) this.showForm = true
    },
    close() {
      this.$emit('close', null)
    },
    getJobs(term) {
      let params = {
        term: term,
        page: 1,
        per_page: 5
      }
      CompanyJob.get({
          params: params
        })
        .then(result => {
          if (result.data.company_jobs)
            this.company_jobs = result.data.company_jobs
        })
        .catch(() => {

        }).finally(() => {})
    },
    submit() {
      this.$loading = true

      if (this.form.status == null) this.form.status = 1

      let request = this.form.id ? WorkSchedule.update(this.form) : WorkSchedule.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Work schedule has been saved',
          });
          this.$emit('close', result.data.work_schedule)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
