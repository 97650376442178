<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this earning? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :append-to-body="true"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <employee-default-earning-form :default_earning="selected" :employee="employee" @close="getDefaultEarnings"></employee-default-earning-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12">
      <div class="card-body row">
        <div class="col-sm-6">
          <el-button type="primary" size="mini" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
        
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="default_earnings" style="width: 100%">


            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.company_earning.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ $store.state.earning_types[props.row.company_earning.type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Accruable">
                <template slot-scope="props">
                  {{ props.row.company_earning.type ? "Yes": 'No' }}
                </template>
              </el-table-column>

              <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Hours">
                  <template slot-scope="props">
                    {{ props.row.hours }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Rate">
                    <template slot-scope="props">
                      {{ money(props.row.rate) }}
                    </template>
                  </el-table-column>

                  <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Amount">
                      <template slot-scope="props">
                        {{ money(props.row.amount) }}
                      </template>
                    </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" size="small" link @click="handleEdit(props.row)" v-if="edit">
                  <i class="fa fa-edit"></i>
                </el-button>
                <confirm-delete @onClick="handleDelete(props.row)" :label="''"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { EmployeeDefaultEarning } from '@/resources/employee_default_earning'
import EmployeeDefaultEarningForm from './EmployeeDefaultEarningForm.vue'
import ConfirmDelete from '../../../../Common/ConfirmDelete.vue'

export default {
  components: { EmployeeDefaultEarningForm, ConfirmDelete },
  props: {
    edit: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    remove: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    select: {
      type: Boolean,
      description: 'Heading Title',
      default: false
    },
    employee: {
      type: Object,
      description: 'Employee Object',
      required: true
    }
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      default_earnings: [],
      selected: null,
      term:null
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
       this.getDefaultEarnings()
      }
    },
    employee: function () {
      this.getDefaultEarnings()
    }
  },
  created: function () {
    if (this.employee) this.getDefaultEarnings()
  },
  methods: {
    handleSelect(department) {
      this.$emit('onSelect', department)
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(data) {
      this.$loading = true
      EmployeeDefaultEarning.delete({id: data.id, employee_id: this.employee.id})
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Default earning has been deleted',
          });
          this.getDefaultEarnings()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleForm() {
      this.showForm = true
    },
    deleteItem() {

    },
    getDefaultEarnings() {
      this.handleClose()
      let params = { term: this.term, employee_id: this.employee.id }
      this.$loading = true
      EmployeeDefaultEarning.get({ params: params })
        .then(result => {
          if (result.data.default_earnings)
            this.default_earnings = result.data.default_earnings
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
