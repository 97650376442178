<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Company Invoices</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select class="select-default" v-model="status" placeholder="STATUS" size="small">
            <el-option class="select-default" v-for="item, i in $store.state.invoice_status" :key="item" :label="item"
              :value="i">
            </el-option>
          </el-select>
          <!-- <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button> -->
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="invoices" style="width: 100%">

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Number">
              <template slot-scope="props">
                {{ props.row.code }}
              </template>
            </el-table-column>
    
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Date">
              <template slot-scope="props">
                {{ props.row.invoice_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Due Date">
              <template slot-scope="props">
                {{ props.row.due_date }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Amount">
              <template slot-scope="props">
                <el-tag :type="$store.state.invoice_status_color[props.row.status]">{{
                  $store.state.invoice_status[props.row.status] }}</el-tag>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Amount">
              <template slot-scope="props">
                {{ money(props.row.total) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <el-button class="mr-1" size="mini" type="default" link @click="handleDownload(props.row)">
                  PDF <i class="fa fa-file"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  CompanyInvoice
} from '@/resources/company_invoice'

import {
  Invoice
} from '@/resources/invoice'

export default {
  data() {
    return {
      invoices: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
      status: 0,
    }
  },
  watch: {
    status: function () {
      this.getInvoices()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getInvoices()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getInvoices()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getInvoices()
    }
  },
  created: function () {
    this.getInvoices()
  },
  methods: {
    handleDownload(invoice) {
      this.$loading = true
      Invoice.downloadPdf(invoice.id)
        .then(result => {
          const link = document.createElement('a');
          link.setAttribute('href', result.data.pdf.link); // Replace 'path_to_your_file' with the file URL or data
          link.setAttribute('download', result.data.pdf.filename); // Replace 'filename.ext' with the desired file name and extension

          // Simulate click event
          link.click();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleClose() {
      this.getInvoices()
    },
    handleForm() {
      this.showForm = true
    },
    getInvoices() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        status: this.status,
        sum: true
      }
      this.$loading = true
      CompanyInvoice.get({
        params: params
      })
        .then(result => {
          if (result.data.invoices)
            this.invoices = result.data.invoices
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
