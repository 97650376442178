<template>
<div class="row">
  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div style="padding: 20px;">
      <invoice-form @save="getInvoices()" v-if="showForm" :invoice="selected" @close="handleClose()"></invoice-form>
    </div>
  </el-drawer>

  <el-drawer title="" :visible.sync="showPayrolls" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div v-if="showPayrolls">
      <payrolls-ready-to-invoice-list @close="getInvoices()"></payrolls-ready-to-invoice-list>
    </div>
  </el-drawer>

  <div class="col-md-12">
    <div class="row">
      <div class="col-md-3">
        <stats-card :type="'primary'" icon="fa fa-folder-open" :small-title="'Payrolls ready to Invoice'" :title="total_payrolls">
          <div class="stats link" slot="footer" @click="handleShowPayrolls">
            <i :class="'fa fa-external-link'"></i>
            Check out
          </div>
        </stats-card>
      </div>
      <div class="col-md-3">
          <stats-card :type="'danger'" icon="fa fa-exclamation-triangle" :small-title="'Unpaid Invoices'" :title="unpaid_invoices">
            <div class="stats link" slot="footer" >
              <i :class="'fa fa-external-link'"></i>
              
            </div>
          </stats-card>
        </div>
        <div class="col-md-3">
            <stats-card :type="'success'" icon="fa fa-money" :small-title="'Totail Paid Invoices'" :title="money(sum_of_paid_invoices)">
              <div class="stats link" slot="footer" >
                <i :class="'fa fa-external-link'"></i>
              
              </div>
            </stats-card>
          </div>
    </div>
  </div>

  <div class="col-md-12 card">
    <div class="card-header">
      <div class="category">Manage Invoices</div>
    </div>
    <div class="card-body row">
      <div class="col-sm-6">
        <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
          <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
        <el-select class="select-default" v-model="status" placeholder="STATUS" size="small">
            <el-option class="select-default" v-for="item,i in $store.state.invoice_status" :key="item" :label="item" :value="i">
            </el-option>
          </el-select>
        <!-- <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button> -->
      </div>
      <div class="col-sm-6">
        <div class="pull-right">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
      </div>
      <div class="col-sm-12 mt-2">
        <el-table class="table-striped" header-row-class-name="text-primary" :data="invoices" style="width: 100%">

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Number">
            <template slot-scope="props">
              {{ props.row.code }}
            </template>
          </el-table-column>
          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Company">
            <template slot-scope="props">
              {{ props.row.company.name }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Date">
            <template slot-scope="props">
              {{ props.row.invoice_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Due Date">
            <template slot-scope="props">
              {{ props.row.due_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Amount">
            <template slot-scope="props">
              <el-tag :type="$store.state.invoice_status_color[props.row.status]">{{ $store.state.invoice_status[props.row.status]}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Amount">
            <template slot-scope="props">
              {{ money(props.row.total) }}
            </template>
          </el-table-column>

          <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Actions">
            <template slot-scope="props">
              <el-button class="mr-1" size="mini" type="default" link @click="handleDownload(props.row)">
                PDF <i class="fa fa-file"></i>
              </el-button>

              <el-button type="default" link @click="handleEdit(props.row)" size="mini" v-if="props.row.status == 0" class="mr-1">
                <i class="fa fa-edit"></i>
              </el-button>
              <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'" v-if="props.row.status == 0"></confirm-delete>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  Payroll
} from '@/resources/payroll'
import {
  Invoice
} from '@/resources/invoice'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import InvoiceForm from './InvoiceForm.vue'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue'
import PayrollsReadyToInvoiceList from '../Company/Payrolls/PayrollsReadyToInvoiceList.vue'

export default {
  components: {
    ConfirmDelete,
    InvoiceForm,
    StatsCard,
    PayrollsReadyToInvoiceList,
  },
  data() {
    return {
      total_payrolls: 0,
      showDelete: false,
      showPayrolls: false,
      showForm: false,
      invoices: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      unpaid_invoices: 0,
      term: null,
      status: 0,
      sum_of_paid_invoices: 0
    }
  },
  watch: {
    status: function () {
      this.getInvoices()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getInvoices()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getInvoices()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getInvoices()
    }
  },
  created: function () {
    this.getPayrolls()
    this.getInvoices()
  },
  methods: {
    handleDownload(invoice) {
      this.$loading = true
      Invoice.downloadPdf(invoice.id)
        .then(result => {
          const link = document.createElement('a');
          link.setAttribute('href', result.data.pdf.link); // Replace 'path_to_your_file' with the file URL or data
          link.setAttribute('download', result.data.pdf.filename); // Replace 'filename.ext' with the desired file name and extension

          // Simulate click event
          link.click();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleShowPayrolls() {
      this.showPayrolls = true
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showPayrolls = false
      this.showForm = false

      this.getPayrolls()
      this.getInvoices()
    },
    handleForm() {
      this.showForm = true
    },
    handleDelete(item) {
      this.$loading = true
      this.$loadingText = "Removing"

      Invoice.delete({
          id: item.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Invoice has been removed',
          });
          this.getPayrolls()
          this.getInvoices()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPayrolls() {
      let params = {
        page: 1,
        per_page: 1
      }
      this.$loading = true
      Payroll.getPayrollsReadyToInvoice({
          params: params
        })
        .then(result => {
          this.getUnpaidInvoices()
          this.total_payrolls = result.data.meta.total
        })
        .catch(() => {}).finally(() => {
          this.$loading = false
        })
    },
    getUnpaidInvoices() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        status: 0
      }
      this.$loading = true
      Invoice.get({
        params: params
      })
        .then(result => {
    
          this.unpaid_invoices = result.data.meta.total
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getInvoices() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        status: this.status,
        sum: true
      }
      this.$loading = true
      Invoice.get({
          params: params
        })
        .then(result => {
          if (result.data.invoices)
            this.invoices = result.data.invoices
          this.pagination = result.data.meta
          this.sum_of_paid_invoices = result.data.sum_of_paid_invoices
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
