<template>
  <div class="row">
    <div class="col-md-12" v-if="form">
      <div class="row">
        <div class="col-md-2">
          <label style="margin-left: 10px;">CHECK DATE BETWEEN</label>
          <el-tag type="info">{{ form.start_date }} AND {{ form.end_date }}</el-tag>
        </div>
        <!-- <div class="col-md-2">
          <label>DEPARTMENT</label>
          <search-department @update="checkDepartment($event)" :department="form.department" :multiple="true"></search-department>
        </div> -->

        <div class="col-md-2">
          <label>Employee Name</label>
          <el-input size="small" v-model="form.term" placeholder="Search any keyword" @input="searchName" :clearable="true"></el-input>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-2">
      <el-table class="table-striped" header-row-class-name="text-primary" :data="employees" style="width: 100%">
        <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="SSN">
          <template slot-scope="props">
            {{ props.row.ssn }}
          </template>
        </el-table-column>
        <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="First Name">
          <template slot-scope="props">
            {{ props.row.first_name }}
          </template>
        </el-table-column>
        <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Last Name">
          <template slot-scope="props">
            {{ props.row.last_name }}
          </template>
        </el-table-column>
        <el-table-column :min-width="100" fixed="right" class-name="td-actions" label="Dept Name"
          v-if="form && !form.department_id">
          <template slot-scope="props">
            {{ props.row.dept_name }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Taxable Earnings">
          <template slot-scope="props">
            {{ money(props.row.total_taxable_earnings) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Non-Tax Earnings">
          <template slot-scope="props">
            {{ money(props.row.total_non_taxable_earnings) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Pre-Tax Deductions">
          <template slot-scope="props">
            {{ money(props.row.total_pre_tax_deductions) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Other Deductions">
          <template slot-scope="props">
            {{ money(props.row.total_other_tax_deductions) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Employee Taxes">
          <template slot-scope="props">
            {{ money(props.row.total_employee_taxes) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Employer Earnings">
          <template slot-scope="props">
            {{ money(props.row.total_employer_taxes) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" class-name="td-actions" label="Check Amount">
          <template slot-scope="props">
            {{ money(props.row.total_check_amount) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
      <p class="category">Found {{ pagination.total }} data</p>
    </div>
    <div class="col-sm-6">
      <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
        :total="pagination.total">
      </p-pagination>
    </div>
  </div>
</template>

<script>
import {
  PayrollReport
} from '@/resources/payroll_report'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import SearchDepartment from '../../../../Common/SearchDepartment.vue'

export default {
  components: {
    PPagination,
    SearchDepartment
  },
  props: ['form'],
  data() {
    return {
      employees: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        total: 0
      },
    }
  },
  created: function () {
    this.getEmployees()
  },
  watch: {
    'pagination.currentPage': function () {
      this.getEmployees()
    }
  },
  methods: {
    searchName() {
      if (!this.form.term || this.form.term.length >= 3) this.getEmployees()
    },
    checkDepartment(department) {
      this.form.department_ids = department ? department : null
      this.getEmployees()
    },
    getEmployees() {
      let form = Object.assign({}, this.form)
      form.start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      form.end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')
      form.show_employees = true
      form.page = this.pagination.currentPage
      this.$loading = true
      this.$loadingText = "Computing Summary. Please wait..."
      this.employees = []
      PayrollReport.getSummary(form)
        .then(result => {
          this.employees = result.data.employees
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
