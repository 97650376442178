<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this tax? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <tax-rate-form @close="getTaxes()" v-if="showForm" :company_state="selected"></tax-rate-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Tax Rates for computing taxes</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <!-- <el-select v-model="state" filterable clearable>
                <el-option v-for="s, i in $store.state.states" :key="i" :value="s.abbreviation" :label="s.name">{{ s.abbreviation }} - {{ s.name }}</el-option>
              </el-select> -->
            <el-select v-model="year">
              <el-option v-for="y, i in $store.state.years" :key="i" :value="y" :label="y">{{ y }}</el-option>
            </el-select>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-alert>Relevant for taxes calculated by multiplying the tax rate with the amount of taxable income.</el-alert>
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tax_rates" style="width: 100%">


            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Type">
                <template slot-scope="props">
                  {{ props.row.type == 0 ? 'FEDERAL':'STATE' }}
                </template>
              </el-table-column>

              <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="For EMployee?">
                <template slot-scope="props">
                  {{ props.row.is_employee ? 'YES':'NO' }}
                </template>
              </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Rate (%)">
              <template slot-scope="props">
                {{ props.row.rate }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Wage Base Limit">
              <template slot-scope="props">
                {{ props.row.limit }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" size="mini" link @click="handleEdit(props.row)" v-if="edit">
                  <i class="fa fa-edit"></i>
                </el-button>

                <el-button type="danger" size="mini" icon @click="handleDelete(props.row)" v-if="remove">
                  <i class="fa fa-times"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { TaxRate } from '@/resources/tax_rate'
import TaxRateForm from './TaxRateForm.vue';
const now = new Date();
export default {
  components: { TaxRateForm },
  props: {
    edit: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    remove: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    select: {
      type: Boolean,
      description: 'Heading Title',
      default: false
    },
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      tax_rates: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      year: now.getFullYear(),
      term: null,
    }
  },
  watch: {
    year: function () {
      this.getTaxes()
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTaxes()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTaxes()
    }
  },
  created: function () {
    this.getTaxes()
  },
  methods: {
    handleSelect(department) {
      this.$emit('onSelect', department)
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.selected = null
    },
    handleDelete(data) {
      this.showDelete = true
    },
    handleForm() {
      this.showForm = true
    },
    deleteItem() {

    },
    getTaxes() {
      this.handleClose()
      let params = { year: this.year, state: this.state, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      TaxRate.get({ params: params })
        .then(result => {
          if (result.data.tax_rates)
            this.tax_rates = result.data.tax_rates
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
