<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Payroll' : 'Create New Payroll' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Type</label>
            <el-select type="number" v-model="form.type">
              <el-option :value="1" label="FEDERAL">FEDERAL</el-option>
              <el-option :value="2" label="STATE">STATE</el-option>
            </el-select>
          </div>
          <div class="form-group" v-if="form.type == 2">
            <label>Select the State Tax Type</label>
            <el-select v-model="state_type" @change="handleSetStateType">
              <el-option value="STATE" label="STATE" v-if="is_employee">STATE TAX</el-option>
              <el-option value="SDI" label="SDI" v-if="is_employee">SDI</el-option>
              <el-option value="LOCAL" label="LOCAL" v-if="is_employee">LOCAL TAX</el-option>
              <el-option value="UNEMPLOYMENT" label="UNEMPLOYMENT" v-if="!is_employee">UNEMPLOYMENT</el-option>

            </el-select>
          </div>
          <div class="form-group">
            <label>Tax Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <el-input v-model="form.name" placeholder="Name" class="form-group" :class="[
    { 'has-danger': failed },
    { 'has-success': passed }]">
              </el-input>
              <span class="error-text" v-if="failed">Name is required</span>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Amount</label>
            <el-input type="number" v-model="form.amount"></el-input>
          </div>
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small" @click="close()">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Paycheck } from '@/resources/paycheck'

extend("required", required);

export default {
  props: ['paycheck', 'is_employee'],
  data() {
    return {
      form: {
        name: null,
        amount: 0,
        type: 2,
        is_employee: true
      },
      state_type: null
    };
  },
  watch: {
   
  },
  created: function () {
    console.log(this.paycheck)
  },
  methods: {
    handleSetStateType() {
      if (this.state_type == 'STATE') this.form.name = this.paycheck.paycheck_tax_info.home_state
      if (this.state_type == 'SDI') this.form.name = this.paycheck.paycheck_tax_info.home_state + ' SDI'
      if (this.state_type == 'LOCAL') this.form.name = this.paycheck.paycheck_tax_info.home_state + ' LOCAL'
      if (this.state_type == 'UNEMPLOYMENT') this.form.name = this.paycheck.paycheck_tax_info.work_state + ' UNEMPLOYMENT'
    },
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true

      this.form.paycheck_id = this.paycheck.id
      this.form.is_employee = this.is_employee

      Paycheck.addTax(this.paycheck.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payroll has been saved',
          });
          this.$emit('close', result.data.paycheck)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
