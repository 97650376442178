<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Default Deduction' : 'Create New Default Deduction' }}
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Name</label>
                <ValidationProvider name="company_deduction" rules="required" v-slot="{ passed, failed }">
                  <el-select filterable v-model="form.company_deduction" placeholder="Name" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]" :remote-method="getCompanyDeductions" :loading="loading" remote>
                    <el-option v-for="item in company_deductions" :key="item.id" :label="item.name" :value="item">
                    </el-option>
                  </el-select>

                  <span class="error-text" v-if="failed">Name is required</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Amount</label>
                <ValidationProvider name="amount" rules="" v-slot="{ passed, failed }">
                  <fg-input type="number" :error="failed ? 'Start date is required' : null" :hasSuccess="passed"
                    name="hours" v-model="form.amount">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Limit</label>
                <ValidationProvider name="limit" rules="" v-slot="{ passed, failed }">
                  <fg-input type="number" :error="failed ? 'Rate is required' : null" :hasSuccess="passed" name="limit"
                    v-model="form.limit">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
           
            <div class="col-md-6" v-if="form.company_deduction">
                <div class="form-group">
                  <label>Type</label>
                  <el-input :value="$store.state.deduction_types[form.company_deduction.type]" readonly> {{  }} </el-input>
                </div>
              
              </div>
            <!-- <div class="col-md-12 mb-2">
            <div>Amount:</div>
            {{ money(form.amount) }} <span class="ytd" v-if="form.id"> (YTD: {{ money(form.ytd_amount) }})</span>
          </div> -->

          </div>

          <p-button type="info" native-type="submit" size="small">Save</p-button>
          <p-button type="button" size="small" @click="close()">Close</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  EmployeeDefaultDeduction
} from '@/resources/employee_default_deduction'

import {
  CompanyDeduction
} from '@/resources/company_deduction'

extend("required", required);

export default {
  props: ['default_deduction', 'employee'],
  data() {
    return {
      form: {
        company_deduction: null
      },
      company_deductions: [],
      loading: false
    };
  },
  watch: {
    default_deduction: function () {
      if (this.default_deduction) this.form = Object.assign({}, this.default_deduction)
    }
  },
  created: function () {
    if (this.default_deduction) this.form = Object.assign({}, this.default_deduction)
    if (this.form.company_deduction) this.company_deductions = [this.form.company_deduction]
  },
  beforeDestroy() {
    this.form = {
      default_deduction: null
    }
  },
  methods: {
    getCompanyDeductions(term) {
      let params = {
        term: term,
        page: 1,
        per_page: 5
      }
      this.loading = true
      CompanyDeduction.get({
        params: params
      })
        .then(result => {
          if (result.data.company_deductions)
            this.company_deductions = result.data.company_deductions
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true
      this.form.employee_id = this.employee.id
      this.form.company_deduction_id = this.form.company_deduction.id
      let request = this.form.id ? EmployeeDefaultDeduction.update(this.form) : EmployeeDefaultDeduction.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Default deduction has been saved',
          });
          this.$emit('close', result.data.default_deduction)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
