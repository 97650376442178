<template>
  <div>
    <div v-if="employee_info && !showPersonalInfoForm" class="row ">
      <el-descriptions class="margin-top col-md-6" title="" :column="1" size="small" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            Gender
          </template>
          {{ employee_info.gender }}
        </el-descriptions-item>
        <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Marital Status
            </template>
            {{ employee_info.marital_status }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-date"></i>
              Birth Date
            </template>
            {{ employee_info.birth_date }}
          </el-descriptions-item>
          <el-descriptions-item>
              <template slot="label">
                <i class="el-icon-date"></i>
                Personal Address
              </template>
              {{ employee_info.personal_address ? employee_info.personal_address.full_address : 'N/A' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                  <i class="el-icon-date"></i>
                  Work Address
                </template>
                {{  employee_info.work_address ?  employee_info.work_address.full_address : 'N/A' }}
              </el-descriptions-item>
      </el-descriptions>
      <div class="col-md-12 mt-2">
        <el-button size="small" @click="handleAddInfo">Update personal info</el-button>
      </div>
    </div>

    <employee-personal-info-form  v-if="showPersonalInfoForm" :employee="employee" @save="setEmployeeInfo($event)"></employee-personal-info-form>
  </div>
</template>
<script>

import EmployeePersonalInfoForm from './EmployeePersonalInfoForm.vue';

export default {
  props: ['employee'],
  components: { EmployeePersonalInfoForm  },
  data() {
    return {
      showPersonalInfoForm: false,
      employee_info: null
    };
  },
  watch: {
    employee: function () {
          this.employee_info = Object.assign({}, this.employee.employee_info)
    }
  },
  created: function () {
    this.employee_info = Object.assign({}, this.employee.employee_info)
  },
  methods: {
    setEmployeeInfo(employee_info) {
      this.showPersonalInfoForm = false
      if (employee_info) this.employee_info =  Object.assign({}, employee_info)
    },
    handleAddInfo() {
      this.showPersonalInfoForm = true
    },
  }
}
</script>
<style></style>
