<template>
<div class="row">

  <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
    <div style="padding: 10px;">
      <p>Remove this group? <p-button type="danger" size="sm" icon @click="deleteItem()">
          <i class="fa fa-trash"></i>
        </p-button>
      </p>
    </div>

  </el-drawer>

  <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <payroll-form :payroll="selected" @close="getPayrolls()"></payroll-form>
      </div>
    </div>
  </el-drawer>

  <div class="col-md-12 card">
    <div class="card-header">
      <page-header :title="'Manage Payrolls - ' + (payroll_group ? payroll_group.name : '')" url="/company/payrolls"></page-header>
    </div>
    <div class="card-body row">
      <div class="col-sm-3">
        <div class="">
          <el-input prefix-icon="nc-icon nc-zoom-split" size="small" class="input-sm" placeholder="Search" v-model="term">
            <el-button slot="append" type="primary" size="small" @click="handleForm">New +</el-button>
          </el-input>

        </div>
      </div>
      <div class="col-sm-9">
        <div class="pull-right">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>

      </div>

      <div class="col-sm-12 mt-2">
        <div>
          <el-alert>You can only delete payroll if it has no paychecks or in progress status</el-alert>
        </div>
        <el-table class="table-striped" header-row-class-name="text-primary" :data="payrolls" style="width: 100%">

          <el-table-column :min-width="120" class-name="td-actions" label="Check Date">
            <template slot-scope="props">
              {{ props.row.check_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="120" class-name="td-actions" label="Period">
            <template slot-scope="props">
              {{ props.row.pay_start_date }} to {{ props.row.pay_end_date }}
            </template>
          </el-table-column>

          <el-table-column :min-width="100" class-name="td-actions" label="Status">
            <template slot-scope="props">
              <el-alert :title="$store.state.payroll_statuses[props.row.status]" :type="$store.state.payroll_status_color[props.row.status]" :closable="false">
              </el-alert>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" class-name="td-actions" label="Schedule">
            <template slot-scope="props">
              {{ $store.state.schedule_types[props.row.schedule_type] }}
            </template>
          </el-table-column>
          <el-table-column :min-width="120" class-name="td-actions" label="Type">
            <template slot-scope="props">
              {{ $store.state.payroll_types[props.row.payroll_type] }}
            </template>
          </el-table-column>
          <el-table-column :min-width="120" class-name="td-actions" label="Paychecks">
            <template slot-scope="props">
              <el-tag type="success">{{ props.row.paychecks_count }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column :min-width="120" class-name="td-actions" label="Auto-Payroll">
              <template slot-scope="props">
                {{ props.row.generate_next_payroll ? 'Yes' : 'No' }}
              </template>
            </el-table-column>

          <el-table-column :min-width="150" class-name="td-actions" label="Actions">
            <template slot-scope="props">

              <div >
                <el-tooltip class="item" effect="dark" content="Edit" placement="top-start">
                  <el-button type="default" link @click="handleEdit(props.row)" size="mini" v-if="props.row.status == 0">
                    <i class="fa fa-edit"></i>
                  </el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Email Paystub" placement="top-start">
                  <el-button type="default" link @click="handleSend(props.row)" size="mini" v-if="props.row.status == 2">
                    <i class="fa fa-envelope"></i>
                  </el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="View Paychecks" placement="top-start">
                     <el-button class="ml-2" type="success" size="mini" icon @click="handleView(props.row)">
                    <i class="fa fa-eye"></i>
                  </el-button>
                </el-tooltip>
                <confirm-delete class="ml-2" @onClick="handleDelete(props.row)" :label="''" v-if="props.row.status == 0"></confirm-delete>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-6 pagination-info">
        <p class="category">Found {{ pagination.total }} data</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
        </p-pagination>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  Payroll
} from '@/resources/payroll'
import PayrollForm from './PayrollForm.vue'
import ConfirmDelete from '../../../Common/ConfirmDelete.vue'

export default {
  components: {
    PayrollForm,
    ConfirmDelete
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      payrolls: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      payroll_group: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPayrolls()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPayrolls()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      let page = parseInt(this.$route.query.page)
      if (page != this.pagination.currentPage) this.$router.push({
        path: this.$route.fullPath,
        query: {
          page: this.pagination.currentPage
        }
      });
      if (!this.$loading) this.getPayrolls()
    }
  },
  created: function () {
    this.getPayrolls()
  },
  methods: {
    handleView(payroll) {
      let id = this.$route.params.id
      this.$router.push({
        name: 'PaychecksList',
        params: {
          id: id,
          payroll_id: payroll.id
        },
        query: {
          page: 1
        }
      });
    },
    handleEdit(payroll) {
      this.showForm = true
      this.selected = Object.assign({}, payroll)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleSend(payroll) {
      this.$loading = true
      this.$loadingText = "Sending paystubs to employees"

      Payroll.emailPaystubs(payroll.id)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Paystubs have been sent',
          });
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDelete(payroll) {
      this.$loading = true
      this.$loadingText = "Removing"

      Payroll.delete({
          id: payroll.id
        })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payroll has been removed',
          });
          this.getPayrolls()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleForm() {
      this.showForm = true
      this.selected = {
        payroll_group_id: this.payroll_group.id
      }
    },
    deleteItem() {

    },
    setRoute() {
      let page = parseInt(this.$route.query.page)
      if (!page) {
        this.$router.push({
          path: this.$route.fullPath,
          query: {
            page: this.pagination.currentPage
          }
        });
      } else {
        this.pagination.currentPage = page
      }
    },
    getPayrolls() {
      this.$loading = true
      this.handleClose()
      this.setRoute()

      let payrollGroupId = this.$route.params.id
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        payroll_group_id: payrollGroupId
      }

      Payroll.get({
          params: params
        })
        .then(result => {
          if (result.data.payrolls)
            this.payrolls = result.data.payrolls
          this.pagination = result.data.meta
          this.payroll_group = result.data.payroll_group
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
