<template>
  <div class="row">
    <el-drawer title="Employee Taxes" :visible.sync="showEmployeeTaxes" direction="btt" size="90%"
      :destroy-on-close="true">
      <div style="padding: 20px">
        <employee-taxes-list :summary="summary"></employee-taxes-list>
      </div>
    </el-drawer>

    <el-drawer title="Employees" :visible.sync="showEmployees" direction="btt" size="90%" :destroy-on-close="true">
      <div style="padding: 20px">
        <employees-payroll-summary-list :form="form" v-if="showEmployees"></employees-payroll-summary-list>
      </div>
    </el-drawer>

    <el-drawer title="Employees" :visible.sync="showEmployeesYtd" direction="btt" size="90%" :destroy-on-close="true"
      :before-close="handleClose">
      <div style="padding: 20px">
        <employee-ytd-history></employee-ytd-history>
      </div>
    </el-drawer>

    <el-dialog title="State Report" :visible.sync="showState" direction="btt" size="50%" :destroy-on-close="true"
      :before-close="handleClose">
      <div style="padding: 5px">
        <state-report-list :state="selectedState" v-if="selectedState" @click="handleGenerateState"></state-report-list>
      </div>
    </el-dialog>

    <div class="col-md-12">
      <h5>PAYROLL REPORTS</h5>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div>Payroll Summary</div>
        </div>
        <div class="col-md-3 mb-2">
          <el-card class="bg-light-blue">
            <div>Total Taxable Earnings</div>
            <div class="font-40">{{ money(summary.total_taxable_earnings) }}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-gray">
            <div>Total Non-Tax Earnings</div>
            <div class="font-40">{{ money(summary.total_non_taxable_earnings) }}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-blue">
            <div>Total Pre-Tax Deductions</div>
            <div class="font-40">{{ money(summary.total_pre_tax_deductions)}}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-yellow">
            <div>Total Other Deductions</div>
            <div class="font-40">{{ money(summary.total_other_tax_deductions)}}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-orange">
            <div>Total Employee Taxes <el-link class="text-primary" style="font-size: 12px"
                v-if="summary.total_employee_taxes" @click="handleShowEmployeeTaxes()">(VIEW)</el-link></div>
            <div class="font-40"> {{ money(summary.total_employee_taxes)}}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-red">
            <div>Total Employer Taxes</div>
            <div class="font-40"> {{ money(summary.total_employer_taxes)}}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-gray">
            <div>Total Employees</div>
            <div class="font-40"> {{ summary.total_employees }}</div>
          </el-card>
        </div>
        <div class="col-md-3">
          <el-card class="bg-light-green">
            <div>Total Check Amount</div>
            <div class="font-40">{{ money(summary.total_check_amount)}}</div>
          </el-card>
        </div>
        <div class="col-md-12">
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-12">
      <hr>
    </div>
    <div class="col-md-5 mb-3">
      <el-card>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <div class="">
              <div class="card-body">
                <div class="form-group">
                  <label>Date</label>
                  <ValidationProvider name="date" rules="required" v-slot="{ passed, failed }">
                    <el-date-picker v-model="date" type="daterange" start-placeholder="Start date"
                      end-placeholder="End date" :default-time="['00:00:00', '23:59:59']"
                      :picker-options="getPickerOptions()" :class="[
      { 'has-danger': failed },
      { 'has-success': passed }]">
                    </el-date-picker>
                    <br><span class="error-text" v-if="failed">Date is mandatory, and both the start and end dates
                      must
                      belong to the same year.</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <label>DEPARTMENT</label>
                  <search-department @update="checkDepartments($event)" :multiple="true"></search-department>
                </div>

                <div class="form-group">
                  <label>PAYROLLS</label>
                  <search-payroll @update="checkPayrolls($event)" :multiple="true" :start_date="form.start_date"
                    :end_date="form.end_date"></search-payroll>
                </div>
              </div>
              <div class="card-footer text-right">
                <el-button type="info" native-type="submit" size="mini">SEARCH</el-button>
                <el-button plain @click="handleShowEmployees" size="mini"
                  :disabled="!form.start_date || !form.end_date">SHOW EMPLOYEES</el-button>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <div class="col-md-12">
          <h5>System Reports</h5>
          <el-button plain @click="handleDownloadPayrollSummary" size="mini" class="margin-bottom-5">PAYROLL
            SUMMARY</el-button>
          <el-button plain @click="handleDownloadTaxSummary" size="mini" class="margin-bottom-5">TAX SUMMARY</el-button>
          <el-button plain @click="handleDownloadPaycheckDetail" size="mini" class="margin-bottom-5">PAYCHECK
            DETAILS</el-button>
          <el-button plain size="mini" class="margin-bottom-5" @click="handleGenerateReport('STATE TAXES SUMMARY')">STATE TAXES SUMMARY</el-button>
        </div>
        <div class="col-md-12 mt-3">
          <h5>Federal Reports</h5>
          <el-button plain size="mini" class="margin-bottom-5" @click="handleGenerateReport('940')">940 (FUTA
            REPORT)</el-button>
          <el-button plain size="mini" class="margin-bottom-5" @click="handleGenerateReport('941')">941 (FEDERAL TAX
            RETURN)</el-button>
          <el-button plain size="mini" class="margin-bottom-5">943 (AGRICULTURE)</el-button>
        </div>
        <div class="col-md-12 mt-3">
          <h5>State Reports</h5>
          <el-button plain size="mini" class="margin-bottom-5"
            @click="handleShowStateReport('CA')">CALIFORNIA</el-button>
          <el-button plain size="mini" class="margin-bottom-5">NEVADA</el-button>
          <el-button plain size="mini" class="margin-bottom-5">NEW YORK</el-button>
        </div>

      </el-card>

      <company-employee-order-form></company-employee-order-form>
    </div>
    <div class="col-md-7">
      <div class="row">
        <div class="col-md-12">
          <generated-reports-list :trigger="trigger_report"></generated-reports-list>
        </div>
        <div class="col-md-12">
          <h5>Employees YTD History</h5>
          <el-button plain size="mini" @click="handleEmployeesYtd">SHOW</el-button>
          <hr>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  PayrollReport
} from '@/resources/payroll_report'
import {
  extend
} from "vee-validate"
import {
  required
} from "vee-validate/dist/rules"
import EmployeesPayrollSummaryList from './EmployeesPayrollSummaryList.vue';
import CompanyEmployeeOrderForm from './CompanyEmployeeOrderForm.vue';
import SearchDepartment from '../../../../Common/SearchDepartment.vue';
import SearchPayroll from '../../../../Common/SearchPayroll.vue';
import EmployeeYtdHistory from './EmployeeYtdHistory.vue';
import GeneratedReportsList from './GeneratedReportsList.vue';
import StateReportList from './StateReportList.vue';
import EmployeeTaxesList from './EmployeeTaxesList.vue';

extend("required", required);

export default {
  components: {
    EmployeesPayrollSummaryList,
    CompanyEmployeeOrderForm,
    SearchDepartment,
    SearchPayroll,
    EmployeeYtdHistory,
    GeneratedReportsList,
    StateReportList,
    EmployeeTaxesList
  },
  props: ['payroll'],
  data() {
    return {
      date: null,
      form: {
        start_date: new Date(),
        end_date: new Date(),
        department_ids: []
      },
      summary: {
        total_check_amount: 0,
        total_employee_taxes: 0,
        total_employer_taxes: 0,
        total_non_taxable_earnings: 0,
        total_other_tax_deductions: 0,
        total_pre_tax_deductions: 0,
        total_taxable_earnings: 0
      },
      showEmployees: false,
      showEmployeesYtd: false,
      trigger_report: 0,
      showState: false,
      selectedState: null,
      showEmployeeTaxes: false
    }
  },
  watch: {
    date: function () {
      if (this.date) {
        this.form.start_date = this.$moment(this.date[0]).format('YYYY-MM-DD')
        this.form.end_date = this.$moment(this.date[1]).format('YYYY-MM-DD')
        if (this.$moment(this.date[0]).format('YYYY') != this.$moment(this.date[1]).format('YYYY')) {
          this.date = null
        }
      } else {
        this.form.start_date = null
        this.form.end_date = null
      }
    }
  },
  created: function () {
    this.date = [this.form.start_date, this.form.end_date]
    this.submit()
  },
  methods: {
    handleGenerateReport(name) {
      this.$loading = true
      PayrollReport.generateReport(this.prepareForm(name))
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleShowEmployeeTaxes() {
      this.showEmployeeTaxes = true
    },
    handleClose() {
      this.showEmployeeTaxes = false

      this.showEmployeesYtd = false
      this.selectedState = null
      this.showState = false
      this.showEmployees = false
    },
    handleShowStateReport(state) {
      this.showState = true
      this.selectedState = state
    },
    handleEmployeesYtd() {
      this.showEmployeesYtd = true
    },
    checkPayrolls(payroll_ids) {
      this.form.payroll_ids = payroll_ids
    },
    checkDepartments(department_ids) {
      this.form.department_ids = department_ids
    },
    getPickerOptions() {
      const startDate = this.date ? this.date[0] : null;
      const endDate = this.date ? this.date[1] : null;
      // Set the minimum date to January 1 of the start year
      const minDate = endDate ? new Date(endDate.getFullYear(), 0, 1) : null;

      // Set the maximum date to December 31 of the end year
      const maxDate = startDate ? new Date(startDate.getFullYear(), 11, 31) : null;
      return {
        disabledDate: (time) => {
          return minDate ? (time.getTime() < minDate.getTime()) : false || maxDate ? (time.getTime() > maxDate.getTime()) : null;
        },
      };
    },
    prepareForm(name) {
      let form = {
        start_date: this.$moment(this.date[0]).format('YYYY-MM-DD'),
        end_date: this.$moment(this.date[1]).format('YYYY-MM-DD'),
        department_ids: this.form.department_ids,
        payroll_ids: this.form.payroll_ids
      }
      if (name) form.name = name
      return form
    },
    submit() {
      this.$loading = true
      this.$loadingText = "Computing Summary. Please wait..."
      PayrollReport.getSummary(this.prepareForm())
        .then(result => {
          this.summary = result.data.summary
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleShowEmployees() {
      this.showEmployees = true
    },
    handleDownloadPaycheckDetail() {
      this.$loading = true
      this.$loadingText = "Generating Report. Please wait..."
      PayrollReport.downloadPaycheckDetail(this.prepareForm())
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleGenerateState(name) {
      this.$loading = true
      PayrollReport.generateReport(this.prepareForm(name))
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    downloadFile(result) {

      this.handleClose()
      if (result.data.file) {
        const link = document.createElement('a');
        link.setAttribute('href', result.data.file.link); // Replace 'path_to_your_file' with the file URL or data
        link.setAttribute('download', result.data.file.filename); // Replace 'filename.ext' with the desired file name and extension
        link.click();
      } else {
        this.$notify({
          type: 'success',
          title: "Success",
          text: 'Report has qeued. Please check the Payroll Reports Status',
        });
        this.trigger_report += 1

      }
    },
    handleDownloadPayrollSummary() {
      this.$loading = true
      this.$loadingText = "Generating Report. Please wait..."
      PayrollReport.downloadPayrollSummary(this.prepareForm())
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadTaxSummary() {
      this.$loading = true
      this.$loadingText = "Generating Report. Please wait..."
      PayrollReport.downloadTaxSummary(this.prepareForm())
        .then(result => {
          this.downloadFile(result)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style lang="scss"></style>
