var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h4',[_vm._v(_vm._s(_vm.form.id ? "Update ":'New ')+" Invoice ")]),_c('form',{attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_vm._v(" Company: "+_vm._s(_vm.form.company ? _vm.form.company.name: 'N/A')+" "),_c('hr')]),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Invoice Number")]),_c('el-input',{attrs:{"readonly":"","disabled":""},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Status")]),_c('ValidationProvider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"default-first-option":true,"placeholder":"Status"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.$store.state.invoice_status),function(item,i){return _c('el-option',{key:i,attrs:{"label":item,"value":i}})}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Name is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Invoice Date")]),_c('ValidationProvider',{attrs:{"name":"invoice_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
                      { 'has-danger': failed },
                      { 'has-success': passed }],attrs:{"type":"date","placeholder":"","size":"mini","format":"yyyy-MM-dd"},model:{value:(_vm.form.invoice_date),callback:function ($$v) {_vm.$set(_vm.form, "invoice_date", $$v)},expression:"form.invoice_date"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Invoice date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Due Date")]),_c('ValidationProvider',{attrs:{"name":"due_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
                      { 'has-danger': failed },
                      { 'has-success': passed }],attrs:{"type":"date","placeholder":"","format":"yyyy-MM-dd"},model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Due date is required")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Recipient Name")]),_c('ValidationProvider',{attrs:{"name":"recipient_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Recipient name is required' : null,"hasSuccess":passed,"name":"recipient_name"},model:{value:(_vm.form.recipient_name),callback:function ($$v) {_vm.$set(_vm.form, "recipient_name", $$v)},expression:"form.recipient_name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Recipient Address")]),_c('ValidationProvider',{attrs:{"name":"recipient_address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Recipient address format is invalid' : null,"hasSuccess":passed,"name":"recipient_address"},model:{value:(_vm.form.recipient_address),callback:function ($$v) {_vm.$set(_vm.form, "recipient_address", $$v)},expression:"form.recipient_address"}})]}}],null,true)})],1)]),_c('div',{},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sender Name")]),_c('ValidationProvider',{attrs:{"name":"from_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Sender name is required' : null,"hasSuccess":passed,"name":"from_name"},model:{value:(_vm.form.from_name),callback:function ($$v) {_vm.$set(_vm.form, "from_name", $$v)},expression:"form.from_name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Sender Address")]),_c('ValidationProvider',{attrs:{"name":"from_address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Sender address is required' : null,"hasSuccess":passed,"name":"from_address"},model:{value:(_vm.form.from_address),callback:function ($$v) {_vm.$set(_vm.form, "from_address", $$v)},expression:"form.from_address"}})]}}],null,true)})],1)])])])]),_c('div',{staticClass:"col-md-7"},[_c('h5',[_vm._v("Items")]),_c('table',{staticClass:"table table-border",staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"250px"}},[_vm._v("Name")]),_c('th',[_vm._v("Description")]),_c('th',{staticStyle:{"width":"80px"}},[_vm._v("Qty")]),_c('th',{staticStyle:{"width":"100px"}},[_vm._v("Rate")]),_c('th',{staticStyle:{"width":"200px"}},[_vm._v("Total")]),_c('th')])]),_c('tbody',[_vm._l((_vm.form.invoice_items),function(item,i){return _c('tr',{key:i},[_c('td',{staticStyle:{"vertical-align":"baseline"}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":'code_' + i,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Item name is required' : null,"hasSuccess":passed},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}})]}}],null,true)})],1)]),_c('td',{staticStyle:{"vertical-align":"baseline"}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":'description_' + i,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var passed = ref.passed;
                      var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'Item description is required' : null,"hasSuccess":passed},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}],null,true)})],1)]),_c('td',{staticStyle:{"vertical-align":"baseline"}},[_c('el-input',{on:{"input":function($event){return _vm.updateTotal(item)}},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('td',{staticStyle:{"vertical-align":"baseline"}},[_c('el-input',{on:{"input":function($event){return _vm.updateTotal(item)}},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}})],1),_c('td',{staticStyle:{"vertical-align":"baseline"}},[_c('el-input',{attrs:{"readonly":""},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1),_c('td',{staticStyle:{"vertical-align":"baseline"}},[_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.remove(i)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)])}),_c('tr',[_c('td',{staticStyle:{"vertical-align":"baseline","text-align":"center"},attrs:{"colspan":"6"}},[_c('el-button',{attrs:{"type":"default","size":"mini"},on:{"click":function($event){return _vm.add()}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" ADD")])],1)])],2)])])]),_c('hr'),_c('div',[_c('div',{staticClass:" text-right"},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"20px","color":"peru"}},[_vm._v(" Total Amount: "+_vm._s(_vm.money(_vm.form.total))+" ")]),_c('el-button',{attrs:{"type":"info","size":"small","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }