<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(submit)" v-loading="loading">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Signup
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
              <label>Name</label>
              <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The Name field is required' : null" :hasSuccess="passed"
                  name="name" v-model="form.name">
                </fg-input>
              </ValidationProvider>
            </div>
          <div class="form-group">
            <label>Email address</label>
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <fg-input type="email" :error="failed ? 'The Email field is required' : null" :hasSuccess="passed"
                name="email" v-model="form.email">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Password</label>
            <ValidationProvider vid="confirmation"
              name="password"
                :rules="{
                  required: true,
                  regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
                }"
            v-slot="{ passed, failed }">
              <fg-input type="password" :error="failed ? 'The Password must be at least 8 characters and has a number' : null" :hasSuccess="passed"
                name="password" v-model="form.password">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Confirm Password</label>
            <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ passed, failed }">
              <fg-input type="password" :error="failed ? 'The Confirm field is required' : null" :hasSuccess="passed"
                name="confirm" v-model="form.confirmPassword">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="success" native-type="submit" @click="checkBeforeSubmit()">Register</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { Signup } from '@/resources/signup'
import { Authenticate } from '@/resources/authenticate'
import Swal from 'sweetalert2'

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("regex", regex);

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        confirmPassword: null
      },
      loading: false
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        return res
      })
    },
    checkBeforeSubmit() {
      // console.log(this.validate())
    },
    submit() {
      this.loading = true
      Signup.register(this.form)
        .then(result => {
          this.autoLogin()
        })
        .catch(error => {
          this.$errors = error.response.data
        }).finally(() => {
          this.loading = false
        })
    },
    autoLogin() {
      let self = this
      this.loading = true
      Authenticate.login(this.form, function (user, message) {
        if (user) {
          self.success()
        } else {
          message = message || 'Unable to save this time'
          self.$notify({
            message: message,
            type: 'danger'
          })
        }
        self.loading = false
      })
    },
    async success() {
      const { value: res } = await Swal.fire({
        title: "Congratulations!",
        text: "You have successfully signed up. Click ok to login",
        icon: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false
      })

      this.$router.push({ name: 'Home', query: { refresh: true } })
    }
  }
}
</script>
<style></style>
