<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <company-deduction-form @close="getDeductions()" v-if="showForm" :company_deduction="selected"></company-deduction-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Company Deductions</div>
        <small>These deductions (items) are utilized within the payroll system, where settings whether pre-tax or after deduction
          status have already been established.</small>
      </div>
      <div class="card-body row">
        <div class="col-sm-3">
          <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
          </fg-input>
        </div>
        <div class="col-sm-9">
          <div class="pull-right">
            <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="company_deductions"
            style="width: 100%">

            <el-table-column :min-width="150" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Type">
              <template slot-scope="props">
                {{ $store.state.deduction_types[props.row.type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="70" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-button type="default" size="mini" link @click="handleEdit(props.row)" v-if="edit">
                  <i class="fa fa-edit"></i>
                </el-button>

                <confirm-delete @onClick="handleDelete(props.row)"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { CompanyDeduction } from '@/resources/company_deduction'
import CompanyDeductionForm from './CompanyDeductionForm.vue';
import ConfirmDelete from '../../../Common/ConfirmDelete.vue';
export default {
  components: { CompanyDeductionForm, ConfirmDelete },
  props: {
    edit:
    {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    remove: {
      type: Boolean,
      description: 'Heading Title',
      default: true
    },
    select: {
      type: Boolean,
      description: 'Heading Title',
      default: false
    },
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      company_deductions: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 20,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getDeductions()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getDeductions()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getDeductions()
    }
  },
  created: function () {
    this.getDeductions()
  },
  methods: {
    handleSelect(department) {
      this.$emit('onSelect', department)
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.selected = null
    },
    handleForm() {
      this.showForm = true
    },
    handleDelete(item) {
      this.$loading = true
      CompanyDeduction.delete({ id: item.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Deduction item has been removed',
          });
          this.getEarnings()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getDeductions() {
      this.handleClose()
      let params = { term: this.term, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      CompanyDeduction.get({ params: params })
        .then(result => {
          if (result.data.company_deductions)
            this.company_deductions = result.data.company_deductions
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
