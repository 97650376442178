<template>
  <div class="row">

    <el-drawer title="Attention" :visible.sync="showDelete" direction="rtl" :before-close="handleClose">
      <div style="padding: 10px;">
        <p>Remove this group? <p-button type="danger" size="sm" icon @click="deleteItem()">
            <i class="fa fa-trash"></i>
          </p-button></p>
      </div>

    </el-drawer>

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <payroll-group-form :payroll_group="selected" @close="getGroups()"></payroll-group-form>
        </div>
      </div>

    </el-drawer>

      <div class="col-md-7 card">
        <div class="card-header">
          <div class="category">Manage Payrolls</div>
        </div>
        <div class="card-body row">
          <div class="col-sm-6">
            <div class="">
              <el-input prefix-icon="nc-icon nc-zoom-split" size="small" class="input-sm" placeholder="Search"
                v-model="term">
                <el-button slot="append" type="primary" size="small" @click="handleForm">New +</el-button>
              </el-input>

            </div>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
                <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>

          </div>

          <div class="col-sm-12 mt-2">
            <el-table class="table-striped" header-row-class-name="text-primary" :data="payroll_groups"
              style="width: 100%">


              <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Name">
                <template slot-scope="props">
                  {{ props.row.name }}
                </template>
              </el-table-column>

              <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Payrolls">
                <template slot-scope="props">
                  {{ props.row.payrolls_count }}
                </template>
              </el-table-column>

              <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
                <template slot-scope="props">

                  <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                    <i class="fa fa-edit"></i>
                  </el-button>

                  <el-button type="success" size="mini" icon @click="handleView(props.row)" class="mr-2">
                    View Payrolls <i class="fa fa-eye"></i>
                  </el-button>

                  <confirm-delete></confirm-delete>


                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <stats-card :type="'primary'" icon="fa fa-globe" :small-title="'Payroll'" :title="'Imports'">
          <div class="stats" slot="footer">
            <el-link href="payroll-imports">
              <i :class="'fa fa-external-link'"></i>
              Open
            </el-link>
          </div>
        </stats-card>
      </div>
    </div>
</template>
<script>

import { PayrollGroup } from '@/resources/payroll_group'
import PayrollGroupForm from './PayrollGroupForm.vue'
import ConfirmDelete from '../../../Common/ConfirmDelete.vue'
import StatsCard from '../../../UIComponents/Cards/StatsCard.vue'

export default {
  components: { PayrollGroupForm, ConfirmDelete, StatsCard },
  data() {
    return {
      showDelete: false,
      showForm: false,
      payroll_groups: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 5,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getGroups()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getGroups()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getGroups()
    }
  },
  created: function () {
    this.getGroups()
  },
  methods: {
    handleView(group) {
      this.$router.push({ name: 'PayrollsList', params: { id: group.id } })
    },
    handleEdit(company) {
      this.showForm = true
      this.selected = Object.assign({}, company)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleDelete(data) {
      this.showDelete = true
    },
    handleForm() {
      this.showForm = true
    },
    deleteItem() {

    },
    getGroups() {
      this.handleClose()
      let params = { term: this.term, status: this.status, page: this.pagination.currentPage, per_page: this.pagination.perPage }
      this.$loading = true
      PayrollGroup.get({ params: params })
        .then(result => {
          if (result.data.payroll_groups)
            this.payroll_groups = result.data.payroll_groups
          this.pagination = result.data.meta
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch payroll groups',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style lang="scss"></style>
