<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ form.id ? 'Update Payroll' : 'Create New Payroll' }}
          </h5>
        </div>
        <div class="card-body">
         <div class="form-group">
              <label>Schedule Type</label>
              <ValidationProvider name="schedule_type" rules="required" v-slot="{ passed, failed }">
                <el-select v-model="form.schedule_type" placeholder="Select" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
                  <el-option :value="i" :label="f" v-for="f, i in $store.state.schedule_types" :key="i">{{ f }}</el-option>
                </el-select>
                <span class="error-text" v-if="failed">Schedule type is required</span>
              </ValidationProvider>  
            </div>

          <div class="form-group">
            <label>Start Date</label>
            <ValidationProvider name="pay_start_date" rules="required" v-slot="{ passed, failed }">
              <fg-input type="date" :error="failed ? 'Start date is required' : null" :hasSuccess="passed" name="pay_start_date"
                v-model="form.pay_start_date">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>End Date</label>
            <ValidationProvider name="pay_end_date" rules="required" v-slot="{ passed, failed }">
              <fg-input type="date" :error="failed ? 'End date is required' : null" :hasSuccess="passed" name="pay_end_date"
                v-model="form.pay_end_date">
              </fg-input>
            </ValidationProvider>
          </div>
           <div class="form-group">
              <label>Check Date</label>
              <ValidationProvider name="check_date" rules="required" v-slot="{ passed, failed }">
                <fg-input type="date" :error="failed ? 'Check date is required' : null" :hasSuccess="passed" name="check_date"
                  v-model="form.check_date">
                </fg-input>
              </ValidationProvider>
            </div>
          
            <div class="form-group">
              <el-checkbox v-model="form.generate_next_payroll" :checked="form.generate_next_payroll == 1">Auto Generate next Payroll ?</el-checkbox>

            </div>
          <div class="form-group">
              <label>Payroll Type</label>
              <ValidationProvider name="payroll_type" rules="required" v-slot="{ passed, failed }">
                <el-select v-model="form.payroll_type" placeholder="Select" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
                  <el-option :value="i" :label="f" v-for="f, i in $store.state.payroll_types" :key="i">{{ f }}</el-option>
                </el-select>
                <span class="error-text" v-if="failed">Payroll type is required</span>
              </ValidationProvider>  
            </div>
          <el-button type="info" native-type="submit" size="small">Save</el-button>
          <el-button type="button" size="small"
            @click="close()">Close</el-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Payroll } from '@/resources/payroll'

extend("required", required);

export default {
  props: ['payroll', 'payroll_group'],
  data() {
    return {
      form: {
        check_date: null,
        pay_start_date: null,
        pay_end_date: null,
        payroll_group_id: null
      }
    };
  },
  watch: {
    payroll: function () {
      if (this.payroll) this.form = Object.assign({}, this.payroll)
    },
    'form.pay_start_date': function () {
      if (this.form.pay_start_date) this.autoSuggestEndDate()
    },
    'form.schedule_type': function () {
      if (this.form.pay_start_date) this.autoSuggestEndDate()
    }
  },
  created: function () {
    if (this.payroll) this.form = Object.assign({}, this.payroll)
  },
  beforeDestroy() {
    this.form = {
      check_date: null,
      pay_start_date: null,
      pay_end_date: null
    }
  },
  methods: {
    autoSuggestEndDate() {
      let date = this.$moment(this.form.pay_start_date)
      let days = 0
      if (this.form.schedule_type == 0) {
        date = date.add(6, 'days')
      }
      if (this.form.schedule_type == 1) date = date.add(13, 'days')
      if (this.form.schedule_type == 2) date = date.add(14, 'days')
      if (this.form.schedule_type == 3) {
         date = date.endOf('month')
      }
      this.form.pay_end_date = date.format('yyyy-MM-DD');

    },
    close() {
      this.$emit('close', null)
    },
    submit() {
      this.$loading = true
      
      let request = this.form.id ? Payroll.update(this.form) : Payroll.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Payroll has been saved',
          });
          this.$emit('close', result.data.payroll)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
