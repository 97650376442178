<template>
<ValidationObserver v-slot="{ handleSubmit }" style="width: 250px;display: block;">
  <form @submit.prevent="handleSubmit(submit)" v-if="!form.delete_">
    <div class="card bg-light-gray">
      <div class="card-body">
        <div class="form-group">
          <label>Name</label>
          <ValidationProvider name="company_earning" rules="required" v-slot="{ passed, failed }">
            <el-select :default-first-option="true" :readonly="!editable" clearable filterable v-model="form.company_earning" placeholder="Name" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]" :remote-method="getCompanyEarnings" :loading="loading" remote>
              <el-option v-for="item in company_earnings" :key="item.id" :label="item.name+' - ' + $store.state.earning_types[item.type]" :value="item">
              </el-option>
            </el-select>

            <span class="error-text" v-if="failed">Name is required</span>
          </ValidationProvider>

        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Hours</label>
              <ValidationProvider name="hours" rules="" v-slot="{ passed, failed }">
                <fg-input :readonly="!editable" type="number" :error="failed ? 'Start date is required' : null" :hasSuccess="passed" name="hours" v-model="form.hours">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Rate</label>
              <ValidationProvider name="rate" rules="" v-slot="{ passed, failed }">
                <fg-input :readonly="!editable" type="number" :error="failed ? 'Rate is required' : null" :hasSuccess="passed" name="rate" v-model="form.rate">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <el-switch disabled v-model="form.is_accruable" :active-value="1" :inactive-value="0" active-text="Accrue" inactive-text="" active-color="#13ce66" inactive-color="#909399">
              </el-switch>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <el-switch disabled v-model="form.type" :active-value="1" :inactive-value="0" active-text="Taxable" inactive-text="" active-color="#13ce66" inactive-color="#909399">
              </el-switch>
            </div>
          </div>
          <div class="col-md-12 mb-2">
            <div>Amount:</div>
            {{ money(form.amount)}} <span class="ytd" v-if="form.id"> (YTD: {{ money(form.ytd_amount) }})</span>
          </div>
          <div class="col-md-12">
              <div class="form-group">
                <label>Workers Comp</label>
                <ValidationProvider name="hours" rules="" v-slot="{ passed, failed }">
                  <fg-input :readonly="!editable" type="text" :error="failed ? 'Start date is required' : null" :hasSuccess="passed" name="hours" v-model="form.wc_code">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
        </div>

        <confirm-delete @onClick="handleDelete(form)" klass="btn-block" label="Delete" v-if="editable"></confirm-delete>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Payroll
} from '@/resources/payroll'
import ConfirmDelete from '../../../../../Common/ConfirmDelete.vue';
import {
  CompanyEarning
} from '@/resources/company_earning'

extend("required", required);

export default {
  components: {
    ConfirmDelete
  },
  props: ['earning', 'editable'],
  data() {
    return {
      loading: false,
      total: 0,
      form: {
        name: null,
        amount: 0,
        rate: 0,
        hours: 0,
        is_accruable: false,
        type: 1, //taxable
        wc_code: null,
        is_modified: false
      },
      company_earnings: []
    };
  },
  watch: {
    earning: function () {
      if (this.earning) {
        this.form.ytd_amount = this.earning.ytd_amount
        if (this.form.company_earning) this.company_earnings = [this.form.company_earning]
        this.form.wc_code = this.earning.wc_code
      }
    },
    form: {
      handler: function (newVal) {
        this.$emit('update', newVal)
      },
      deep: true
    },
    'form.company_earning': {
      handler: function (newVal) {
        if (newVal) {
          this.form.is_accruable = newVal.is_accruable
          this.form.type = newVal.type
          this.form.name = newVal.name
          this.form.multiplier = newVal.multiplier
          this.computeTotal()
        } 
      },
      deep: true
    },
    'form.hours': {
      handler: function (newVal) {
        this.computeTotal()
      },
      deep: true
    },
    'form.rate': {
      handler: function (newVal) {
        this.computeTotal()
      },
      deep: true
    }
  },
  created: function () {
    if (this.earning) {
      this.form = Object.assign({}, this.earning)
    }
  },
  beforeDestroy() {
    this.form = {
      name: null,
      amount: null,
      rate: 0,
      hours: 0,
      is_accruable: false,
      type: 1, //taxable
      wc_code: null,
      is_modified: false
    }
  },
  methods: {
    computeTotal() {
      this.form.amount = this.form.multiplier > 0 ? (this.form.hours * this.form.rate) : this.form.rate
    },
    close() {
      this.$emit('close', null)
    },
    handleDelete(form) {
      this.form.delete_ = true
      this.$emit('update', form)
    },
    getCompanyEarnings(term) {
      let params = {
        term: term,
        page: 1,
        per_page: 5
      }
      this.loading = true
      CompanyEarning.get({
          params: params
        })
        .then(result => {
          if (result.data.company_earnings)
            this.company_earnings = result.data.company_earnings
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>

<style></style>
