<template>
  <div class="row">
    <div class="col-md-3">
      <employee-profile-card :employee="form"></employee-profile-card>
    </div>
    <div class="col-md-8">
      <el-tabs type="border-card" v-model="active_tab">
        <el-tab-pane label="Basic Info" name="basic_info">
          <employee-basic-view :employee="form" @save="setEmployee($event)"></employee-basic-view>
          </el-tab-pane>
        <el-tab-pane label="Personal Info" name="personal_info">
          <employee-personal-info-view :employee="form"></employee-personal-info-view>
        </el-tab-pane>
        <el-tab-pane label="Tax Info" name="tax_info">
          <tax-info-view :employee="form"></tax-info-view>
        </el-tab-pane>
        <el-tab-pane label="Default Earnings" name="default_earnings">
            <employee-default-earnings-list :employee="form" v-if="form && active_tab=='default_earnings'"></employee-default-earnings-list>
          </el-tab-pane>
          <el-tab-pane label="Default Deductions" name="default_deductions">
              <employee-default-deductions-list :employee="form" v-if="form && active_tab == 'default_deductions'"></employee-default-deductions-list>
            </el-tab-pane>
        <el-tab-pane label="Credential" name="credential">
          <employee-user-form :employee="form" ></employee-user-form>
        </el-tab-pane>
        
      </el-tabs>
    </div>
</div>
</template>
<script>
import { Employee } from '@/resources/employee'
import EmployeeProfileCard from '../../../UIComponents/Cards/EmployeeProfileCard.vue';
import EmployeePersonalInfoView from './EmployeePersonalInfoView.vue';
import TaxInfoView from './TaxInfoView.vue';
import EmployeeBasicView from './EmployeeBasicView.vue';
import EmployeeUserForm from './EmployeeUserForm.vue';
import EmployeeDefaultEarningsList from './EmployeeDefaultEarnings/EmployeeDefaultEarningsList.vue';
import EmployeeDefaultDeductionsList from './EmployeeDefaultDeductions/EmployeeDefaultDeductionsList.vue';


export default {
  components: { EmployeeProfileCard, EmployeePersonalInfoView, TaxInfoView, EmployeeBasicView, EmployeeUserForm, EmployeeDefaultEarningsList, EmployeeDefaultDeductionsList },
  props: ['employee'],
  data() {
    return {
      showPersonalInfoForm: false,
      active_tab: 'basic_info',
      form: {
        department: null,
        position: null
      }
    };
  },
  watch: {
    department: function () {
      if (this.employee) this.getEmployee()
    }
  },
  created: function () {
    if (this.employee) this.getEmployee()
  },
  beforeDestroy() {
    this.form = {
      name: null
    }
  },
  methods: {
    
    close() {
      this.$emit('close', null)
    },
    setEmployee(employee) {
      if (employee) {
        this.form = Object.assign({}, employee)
      }
    },
    getEmployee() {
      this.$loading = true

      Employee.show(this.employee.id)
        .then(result => {
          this.form = result.data.employee
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to view this employee'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
