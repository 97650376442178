import api from './api'

const API = '/api/company/payroll-imports'

export const PayrollImport = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(form) {
    return api.createFormData(API, form)
  },
  reset: function(id, form) {
    return api.update(API+'/'+id+'/reset')
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
