<template>
  <div>
    <div v-if="!multiple" class="form-group" :class="[
      { 'has-danger': error },
      { 'has-success': passed }]">
      <slot>
        <el-autocomplete v-model="state" :fetch-suggestions="querySearchAsync" placeholder="Search department"
          class="is-valid" :class="passed ? 'is-valid' : ''" :clearable="true" @select="handleSelect"
          @change="handleChange" style="width: 100%">
          <template slot-scope="{ item }">
            <div class="value">{{ item.value }}</div>
          </template>
          <slot>
            <input>
          </slot>
        </el-autocomplete>
      </slot>

      <slot name="helpBlock" v-if="error">
        <div class="invalid-feedback error-text">
          {{ error }}
        </div>
      </slot>
    </div>
    <div v-else>
      <el-select v-model="selected_department_ids" multiple clearable placeholder="Select Departments" filterable
        popper-class="custom-header" @change="handleChange">
        <el-option v-for="dept in departments" :key="dept.id" :value="dept.id" :label="dept.name"></el-option>
      </el-select>
    </div>
  </div>
</template>
<script>


import { Department } from '@/resources/department';

export default {
  props: {
    passed: {
      type: Boolean,
      description: 'Whether form has passed',
      default: false
    },
    error: {
      type: String,
      description: 'Error message',
      default: null
    },
    department: {
      type: Object,
      description: 'Department',
      default: null
    },
    multiple: {
      type: Boolean,
      description: 'Whether multiple',
      default: false
    }
  },
  data() {
    return {
      links: [],
      state: '',
      timeout: null,
      departments: [],
      selected_department_ids: [],
      checkAll: false,
      indeterminate: false
    };
  },
  watch: {
    'department': function () {
      if (this.department) this.state = this.department.name
      if (this.multiple) this.getDepartments()
    }
  },
  created: function () {
    if (this.department) this.state = this.department.name
    if (this.multiple) this.getDepartments()
  },
  methods: {
    getDepartments() {
      Department.get({ params: { per_page: 999, simple: true } })
        .then(result => {
          this.departments = result.data.departments
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to fetch departments at the moment'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        })
    },
    handleCheckAll(val) {
      console.log(val)
    },
    loadAll() {
      return [];
    },
    querySearchAsync(queryString, cb) {
      Department.get({ params: { term: queryString, per_page: 5, simple: true } })
        .then(result => {
          let items = []
          result.data.departments.forEach(element => {
            items.push({ value: element.name, id: element.id })
          });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            cb(items);
          }, 1000 * Math.random());

        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to fetch departments at the moment'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        })
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      if (item) item.name = item.value
      this.$emit('update', item)
    },
    handleChange(item) {
      this.$emit('update', item)
    }
  },
  mounted() {
    this.links = this.loadAll();
  }
};
</script>

<style scoped lang="scss">
.error-text {
  display: block;
}

.custom-header {
  .el-checkbox {
    display: flex;
    height: unset;
  }
}
</style>