<template>
  <ValidationObserver v-slot="{ handleSubmit }" style="width: 250px;display: block;">
    <form @submit.prevent="handleSubmit(submit)" v-if="!form.delete_">
      <div class="card bg-light-gray">
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="company_deduction" rules="required" v-slot="{ passed, failed }">
              <el-select :default-first-option="true" :readonly="!editable" clearable filterable v-model="form.company_deduction" placeholder="Name" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]" :remote-method="getCompanyDeductions" :loading="loading" remote>
                <el-option v-for="item in company_deductions" :key="item.id" :label="item.name + ' - ' + $store.state.deduction_types[item.type]" :value="item">
                </el-option>
              </el-select>

              <span class="error-text" v-if="failed">Name is required</span>
            </ValidationProvider>

          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Amount</label>
                <ValidationProvider name="amount" rules="" v-slot="{ passed, failed }">
                  <fg-input :readonly="!editable" type="number" :error="failed ? 'Amount is required' : null"
                    :hasSuccess="passed" name="amount" v-model="form.amount">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Type</label>
                <ValidationProvider name="type" rules="required" v-slot="{ passed, failed }">
                  <el-select :disabled="true" v-model="form.type" placeholder="Select" class="form-group bg-dark-gray text-white" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]" type="primary">
                    <el-option :value="i" :label="f" v-for="f, i in $store.state.deduction_types" :key="i">{{ f }}</el-option>
                  </el-select>
                  <span class="error-text" v-if="failed">Deduction type is required</span>
                </ValidationProvider>  
              </div>
              
            </div>
            <div class="col-md-12">
              <div>Amount:</div>
              {{ money(form.amount) }}  <span class="ytd" v-if="form.id"> (YTD: {{ money(form.ytd_amount) }})</span>
              </div>
          </div>

          <confirm-delete @onClick="handleDelete(form)" klass="btn-block" label="Delete" v-if="editable"></confirm-delete>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ConfirmDelete from '../../../../../Common/ConfirmDelete.vue';
import {
  CompanyDeduction
} from '@/resources/company_deduction'


extend("required", required);

export default {
  components: { ConfirmDelete },
  props: ['deduction', 'editable'],
  data() {
    return {
      total: 0,
      form: {
        name: null,
        amount: 0,
        type: 1,
      },
      company_deductions: [],
      loading: false
    };
  },
  watch: {
    deduction: function () {
      if (this.deduction) {
        this.form.ytd_amount = this.deduction.ytd_amount
        if (this.form.company_deduction) this.company_deductions = [this.form.company_deduction]
      }
    },
    form: {
      handler: function (newVal) {
        this.$emit('update', newVal)
      },
      deep: true
    },
    'form.company_deduction': {
      handler: function (newVal) {
        if (newVal) {
          this.form.type = newVal.type
          this.form.name = newVal.name
        }
      },
      deep: true
    },
  },
  created: function () {
    if (this.deduction) this.form = Object.assign({}, this.deduction)
  },
  beforeDestroy() {
    this.form = {
      name: null,
      amount: 0,
      type: 1,
    }
  },
  methods: {
    close() {
      this.$emit('close', null)
    },
    handleDelete(form) {
      this.form.delete_ = true
      this.$emit('update', form)
    },
    getCompanyDeductions(term) {
      let params = {
        term: term,
        page: 1,
        per_page: 5
      }
      this.loading = true
      CompanyDeduction.get({
        params: params
      })
        .then(result => {
          if (result.data.company_deductions)
            this.company_deductions = result.data.company_deductions
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
  }
}
</script>
<style></style>
