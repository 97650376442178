var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Earning Item' : 'Create Earning Item')+" ")]),_c('small',[_vm._v("Generate or modify an item for use in adding earnings to a paycheck")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Earning name is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Multiplier")]),_c('ValidationProvider',{attrs:{"name":"state","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
              { 'has-danger': failed },
              { 'has-success': passed }],attrs:{"filterable":"","placeholder":"Select state"},model:{value:(_vm.form.multiplier),callback:function ($$v) {_vm.$set(_vm.form, "multiplier", $$v)},expression:"form.multiplier"}},_vm._l((_vm.$store.state.multipliers),function(m,i){return _c('el-option',{key:i,attrs:{"value":m.value,"label":m.label}},[_vm._v(_vm._s(m.label))])}),1),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("State is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('small',[_vm._v("Generally, income that is subject to taxation tends to align with the concept of being accrual-based.")]),_c('br'),_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"active-text":"Accruable","inactive-text":"","active-color":"#13ce66","inactive-color":"#909399"},model:{value:(_vm.form.is_accruable),callback:function ($$v) {_vm.$set(_vm.form, "is_accruable", $$v)},expression:"form.is_accruable"}})],1),_c('div',{staticClass:"form-group"},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"active-text":"Subject to tax","inactive-text":"","active-color":"#13ce66","inactive-color":"#909399"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('el-button',{attrs:{"type":"info","native-type":"submit","size":"small"}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"button","size":"small"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancel")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }