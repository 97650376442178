<template>
 
  <ValidationObserver v-slot="{ handleSubmit }">
    <form :model="form" @submit.prevent="handleSubmit(submit)">
      <div class="row">   
        <div class="col-md-7 mt-3">
          <table class="table table-border" style="width: 100%">
            <thead>
              <tr>
                <th style="width: 250px;">State</th>
                <th style="width: 80px;">Bill Rate</th>
                <th style="width: 100px;">Cost Rate</th>
                <th style="width: 200px;">Exp Mod</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item, i in form.states" :key="i">
                <td style="vertical-align: baseline;">
                  <div class="form-group">
                    <ValidationProvider :name="'state_' + i" rules="required" v-slot="{ passed, failed }">
                      <el-select v-model="item.state" placeholder="Select" filterable class="form-group" :class="[
                        { 'has-danger': failed },
                        { 'has-success': passed }]">
                        <el-option :value="s.abbreviation" :label="s.abbreviation + ' - ' + s.name" v-for="s in $store.state.states" :key="s.abbreviation">{{ s.abbreviation }} - {{ s.name }}</el-option>
                      </el-select><br>
                      <span class="error-text" v-if="failed">State is required</span>
                  
                    </ValidationProvider>
                  </div>
                </td>
                <td style="vertical-align: baseline;">
                    <el-input v-model="item.exp_mod"></el-input>
                  </td>
                <td style="vertical-align: baseline;">
                  <el-input v-model="item.bill_rate"></el-input>
                </td>
                <td style="vertical-align: baseline;">
                  <el-input v-model="item.cost_rate"></el-input>
                </td>
          
                <td style="vertical-align: baseline;">
                  <el-button type="danger" size="mini" @click="remove(i)"><i class="fa fa-trash"></i></el-button>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: baseline;text-align: center;" colspan="6">
                  <el-button type="default" size="mini" @click="add()"><i class="fa fa-plus"></i> ADD</el-button>
                </td>
              </tr>
            </tbody>
          </table>

          <el-button type="primary" size="mini" native-type="submit"><i class="fa fa-save"></i> SAVE ALL</el-button>

        </div>

      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  WorkSchedule
} from '@/resources/work_schedule'

export default {
  props: ['work_schedule'],
  components: {  },
  data() {
    return {
      form: {
        states: []
      } 
    }
  },
  created: function () {
    this.getStates()
  },
  methods: {
    add() {
      this.form.states.push({})
    },
    getStates() {
      WorkSchedule.getStates(this.work_schedule.id, {})
        .then(result => {
          if (result.data.states)
            this.form.states = result.data.states
          this.$emit('update', result.data.meta.total)
        })
        .catch(() => {

        }).finally(() => { })
    },
     getActiveItems() {
      let list = JSON.parse(JSON.stringify(this.form.states));
      this.form.states = []
      let self = this
      list.forEach(function (item) {
        if (!item.to_remove) self.form.states.push(item)
      })
    },
    updateTotal(item) {
      item.amount = (item.rate) * item.quantity
    },
    remove(i) {
      this.form.states[i].to_remove = true
      this.getActiveItems()
    },
    submit() {
      WorkSchedule.saveStates(this.work_schedule.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'States have been saved',
          });
          this.getStates()
        })
        .catch(() => {

        }).finally(() => { })
    }
  }
}
</script>

<style lang="scss"></style>
